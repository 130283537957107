import { Suspense, lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { Toaster } from 'react-hot-toast';
import Header from './components/common/header/Header';
import { AboutPage, CoursesPage } from 'pages';
import AboutLayout from 'components/About/AboutLayout';
import { useAppSelector } from 'hooks/useAppSelector';
import { refreshTokens } from 'api/api';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { logoutUser } from 'redux/userSlice';
import SupportEmailModal from 'modals/SupportEmailModal';
import 'react-datepicker/dist/react-datepicker.css';
import { useMediaQuery } from 'react-responsive';
import bgImg from 'images/backgrounds/bg2.webp';
import AppRoutes from 'AppRoutes';

const ChangesSavedModal = lazy(() => import('modals/ChangesSavedModal'));
const TermsModal = lazy(() => import('modals/TermsModal'));
const SpecialistCanceledOrdedModal = lazy(
  () => import('modals/SpecialistCanceledOrdedModal'),
);
const CancelOrderModal = lazy(() => import('modals/CancelOrderModal'));

const App = () => {
  const { role } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

  useEffect(() => {
    const refreshToken = localStorage.getItem('gotouRefreshToken');

    if (!refreshToken || role === 'guest') return;
    const fetchTokens = async () => {
      try {
        await refreshTokens();
      } catch (error: any) {
        dispatch(logoutUser());
      }
    };

    fetchTokens();
  }, [dispatch, role]);

  return (
    <>
      <Routes>
        <Route path='/' element={<AboutLayout />}>
          <Route index element={<AboutPage />} />
          <Route path='courses' element={<CoursesPage />} />
        </Route>
        <Route
          path='*'
          element={
            <>
              <Header />
              {isSmallScreen ? (
                <AppRoutes />
              ) : (
                <div
                  className='no-repeat flex min-h-screen w-screen items-start justify-center bg-cover'
                  style={{ backgroundImage: `url(${bgImg})` }}
                >
                  <div className='-mt-5 rounded-[30px] bg-white/30 px-14 py-5 backdrop-blur-md sm:scale-[0.78]'>
                    <AppRoutes />
                  </div>
                </div>
              )}
            </>
          }
        />
      </Routes>
      <Suspense fallback={<div />}>
        <ChangesSavedModal />
        <SpecialistCanceledOrdedModal />
        <CancelOrderModal />
        <SupportEmailModal />
        <TermsModal />
      </Suspense>
      <Toaster
        toastOptions={{
          error: {
            duration: 2000,
          },
        }}
      />
    </>
  );
};

export default App;
