import React, { useEffect } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import { Input } from 'ui';

type AutocompleteProps = {
  isLoaded: boolean;
  ready: boolean;
  clearSuggestions: () => void;
  setValue: (value: string, shouldFetchData?: boolean) => void;
  data: any[];
  status: string;
  value: string;
  init: () => void;
  setIsOptionSelected: (isOptionSelected: boolean) => void;
  handleSelect: (suggestion: any) => void;
};

const Autocomplete = ({
  isLoaded,
  ready,
  clearSuggestions,
  value,
  setValue,
  data,
  status,
  init,
  setIsOptionSelected,
  handleSelect,
}: AutocompleteProps) => {
  const { t } = useTranslation();
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e: any) => {
    setValue(e.target.value);
    setIsOptionSelected(false);
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion)!}
          className='my-1 animate-fade-appear cursor-pointer p-1 hover:bg-gray-100'
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  useEffect(() => {
    if (isLoaded) {
      init();
    }
  }, [isLoaded, init]);

  return (
    <div ref={ref}>
      <Input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={t('location.enter_address')}
      />
      {status === 'OK' && value && (
        <ul className='flex flex-col'>{renderSuggestions()}</ul>
      )}
    </div>
  );
};

export default Autocomplete;
