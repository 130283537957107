import React, { useState } from 'react';
import UAicon from 'images/flags/ua.png';
import DEicon from 'images/flags/de.png';
import USicon from 'images/flags/us.png';
import PLicon from 'images/flags/pl.jpg';

const languageIcons = {
  ua: UAicon,
  de: DEicon,
  en: USicon,
  pl: PLicon,
};

type LanguageItemProps = {
  handleSelectLanguage: (language: 'en' | 'pl' | 'de' | 'ua') => void;
  language: 'en' | 'pl' | 'de' | 'ua';
};

const LanguageItem = ({
  handleSelectLanguage,
  language,
}: LanguageItemProps) => {
  const [currLanguage] = useState(
    localStorage.getItem('selectedLanguage') || 'de',
  );
  return language !== currLanguage ? (
    <p
      className='flex cursor-pointer items-center gap-3 rounded-xl px-2 py-2 hover:bg-accent-50'
      onClick={() => handleSelectLanguage(language)}
    >
      <img
        src={languageIcons[language]}
        alt='language'
        width={17}
        height={17}
        className='aspect-[1.5/1] rounded-sm'
      />
      {language === 'en' && 'English'}
      {language === 'pl' && 'Polski'}
      {language === 'de' && 'Deutsch'}
      {language === 'ua' && 'Українська'}
    </p>
  ) : (
    <></>
  );
};

export default LanguageItem;
