import React from 'react';
import { motion } from 'framer-motion';
import ConfirmIcon from 'images/icons/cofirm.svg';
import { Button } from 'ui';
import { useTranslation } from 'react-i18next';
import ClientScreen from 'images/screens/Mocap_for clients.png';
import SpecialistScreen from 'images/screens/Mocap_specialists.png';
import { useAppSelector } from 'hooks/useAppSelector';

const typeConfig = {
  client: {
    advantagesKey: 'advantages.clients.list',
    titleKey: 'advantages.clients.title',
    buttonKey: 'advantages.clients.sign_in',
    screen: ClientScreen,
    borderClass: 'border-secondary bg-white',
  },
  specialist: {
    advantagesKey: 'advantages.specialists.list',
    titleKey: 'advantages.specialists.title',
    buttonKey: 'advantages.specialists.sign_in',
    screen: SpecialistScreen,
    borderClass: 'border-accent bg-[#FFD23421]',
  },
};

const AdvantagesCard = ({
  type,
  handleLogin,
}: {
  type: 'client' | 'specialist';
  handleLogin: () => void;
}) => {
  const { t } = useTranslation();
  const config = typeConfig[type];

  const { isLoggedIn } = useAppSelector((state) => state.user);

  const advantages = t(config.advantagesKey, { returnObjects: true }) as any[];
  const titleParts = t(config.titleKey).split(' ');
  const buttonText = t(config.buttonKey);

  return (
    <section
      className={`flex flex-col gap-4 rounded-[20px] border px-9 py-12 sm:max-h-[469px]  smMax:items-start  ${
        type === 'specialist'
          ? 'sm:flex-row-reverse xl:flex-row'
          : 'sm:flex-row'
      } lg:max-w-[600px] ${config.borderClass}`}
    >
      <div className='flex min-h-full min-w-max flex-col items-stretch justify-between smMax:gap-4'>
        <div className='space-y-5'>
          <motion.h3
            className='text-3xl '
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
          >
            {titleParts[0]} <span className='font-medium'>{titleParts[1]}</span>
          </motion.h3>
          <ul className='flex flex-col gap-5'>
            {advantages.map((item, index) => (
              <li key={index} className='flex items-center gap-2'>
                <img src={ConfirmIcon} alt='confirm icon' />
                <p className='text-gray-7'>{item}</p>
              </li>
            ))}
          </ul>
        </div>
        {!isLoggedIn && (
          <Button
            variant='black'
            className={`w-[210px] py-4 ${
              type === 'client'
                ? 'border border-primary bg-white !text-primary hover:!bg-primary hover:!text-white'
                : ''
            }`}
            onClick={handleLogin}
          >
            {buttonText}
          </Button>
        )}
      </div>
      <img
        src={config.screen}
        alt='app screen'
        className='-top-28 max-w-[300px] object-contain sm:relative sm:h-full xl:h-[140%] smMax:w-4/5'
      />
    </section>
  );
};

export default AdvantagesCard;
