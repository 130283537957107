import React from 'react';

type HeaderLinkProps = {
  text: string;
  link: string;
};

const HeaderLink = ({ text, link }: HeaderLinkProps) => {
  const isExternal = link.startsWith('http');

  return (
    <li>
      <a
        href={link}
        {...(isExternal
          ? { target: '_blank', rel: 'noopener noreferrer' }
          : {})}
        className='hidden px-3 py-2 font-normal text-primary transition-all hover:text-accent sm:block lg:p-0 '
      >
        {text}
      </a>
    </li>
  );
};

export default HeaderLink;
