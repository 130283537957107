import React from 'react';

const CategorySkeleton = () => {
  return (
    <li className='h-[20vh] w-[40vw] animate-pulse rounded-[18px] border border-secondary categoriesSkeleton:h-[188px] categoriesSkeleton:w-[188px]'>
      <div className='h-[70%] w-full rounded-t-[18px] bg-secondary' />
      <div className='mx-auto mt-4 h-[10%] w-[80%] rounded-[18px] bg-secondary' />
    </li>
  );
};

export default CategorySkeleton;
