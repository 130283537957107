import React from 'react';
import AcceptCookiesModal from 'modals/AcceptCookiesModal';
import { motion } from 'framer-motion';
import { Button } from 'ui';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setUserRole } from 'redux/userSlice';
import AdvantagesCard from 'components/About/AdvantagesCard';
import { useAppSelector } from 'hooks/useAppSelector';
import { useNavigate } from 'react-router';
import AppScreenshot1 from 'images/screens/app-screen1.png';
import { logOut } from 'api/login';
import { useTranslation } from 'react-i18next';

import DifferentPeople from 'images/different people.webp';
import CardWithIcon from 'components/About/CardWithIcon';
import StarIcon from 'images/emoji/(star).svg';
import PinIcon from 'images/emoji/pin.png';
import LikeIcon from 'images/emoji/like.png';
import TimeIcon from 'images/emoji/hourglass.png';
import ServicesCarousel from 'components/About/ServicesCarousel';
import TeamAvatars from 'components/About/TeamAvatars';
import CategoriesList from 'components/About/CategoriesList';
import ContactsSection from 'components/About/ContactsSection';

const AboutPage = () => {
  const { t } = useTranslation();
  const { isLoggedIn, role, uid } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    await logOut(uid, role as 'client' | 'specialist', dispatch, navigate);
  };

  const handleLogin = (role: 'specialist' | 'client') => {
    dispatch(setUserRole(role));
    navigate('/login');
  };

  const handleGoBackToApp = () => {
    if (role === 'client') {
      navigate('/category');
      return;
    }
    if (role === 'specialist') {
      navigate('/specialist/profile');
    }
  };
  const isEnglish = 'en' === localStorage.getItem('selectedLanguage');

  return (
    <>
      <main className='flex flex-col md:mt-[10vh]'>
        <article className='flex flex-col items-center justify-center gap-9 px-5 pb-14 md:px-[10vw] lg:flex-row'>
          <div className='flex flex-col gap-5 '>
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, ease: 'easeInOut' }}
              className='space-y-5 lg:max-w-[440px] xl:max-w-none'
            >
              <h1 className='text-4xl font-bold leading-tight  text-[#2D2C2C] xl:text-5xl'>
                {t('title.text_1')}
                <br />
                <span className=' text-accent  xl:whitespace-nowrap'>
                  {t('title.text_2')}
                </span>
              </h1>
              <p
                className=' text-lg text-gray-4'
                style={{ fontFamily: 'Inter' }}
              >
                {t('description')}
              </p>
            </motion.div>
            <div className='flex flex-row justify-center gap-4 smMax:flex-col'>
              {!isLoggedIn ? (
                <>
                  <Button
                    variant='whiteStroke'
                    className='flex-1 !border-black py-3 uppercase hover:!border-accent md:py-4 lg:px-8'
                    onClick={() => handleLogin('client')}
                  >
                    {t('register_as_customer')}
                  </Button>
                  <Button
                    variant='black'
                    className='flex-1 py-3 uppercase md:py-4 lg:px-8'
                    onClick={() => handleLogin('specialist')}
                  >
                    {t('register_as_specialist')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant='yellow'
                    className='w-full py-3 uppercase md:py-4 lg:px-8 smMax:whitespace-normal'
                    onClick={handleGoBackToApp}
                  >
                    {t('return_to_app')} {t(role)}
                  </Button>
                  <Button
                    variant='black'
                    className='w-full py-3 uppercase hover:bg-black md:py-4 lg:px-8'
                    onClick={handleLogOut}
                  >
                    {t('menu.logout')}
                  </Button>
                </>
              )}
            </div>
          </div>
          <motion.img
            src={DifferentPeople}
            alt='Different people'
            className={`aspect-[4/3] max-h-[540px] w-[80vw] object-contain ${
              isEnglish ? 'lg:w-[50vw]' : 'lg:w-[40vw]'
            } `}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, ease: 'easeInOut' }}
          />
        </article>
        <CategoriesList />
        <article
          className='flex flex-col gap-5 px-5 py-24 sm:px-[9vw] '
          id='product'
        >
          <section className='mx-auto flex max-w-[860px] flex-col gap-4'>
            <h3
              className={`text-5xl leading-[140%] text-[#2D2C2C] sm:text-center`}
            >
              {t('product.title_1')}
              <span className='font-medium text-accent'>
                {t('product.title_2')}
              </span>
            </h3>
            <p
              className='text-gray-4 sm:text-center'
              style={{ fontFamily: 'Inter, sans-serif' }}
            >
              {t('product.description')}
            </p>
          </section>

          <div className='flex flex-col justify-between gap-7 md:flex-row'>
            <section className='order-2 flex flex-col justify-between gap-11 md:order-1'>
              <CardWithIcon
                description={t('product.benefit_1.description')}
                title={t('product.benefit_1.title')}
                icon={StarIcon}
              />
              <CardWithIcon
                description={t('product.benefit_2.description')}
                title={t('product.benefit_2.title')}
                icon={PinIcon}
              />
            </section>
            <div className='order-1 mx-auto my-auto flex h-3/4 max-w-xs items-center justify-center rounded-[50px] border border-gray-3 bg-gray-gradient md:order-2'>
              <img
                src={AppScreenshot1}
                alt='App screenshot'
                className='aspect-[4/6] max-h-[500px] object-contain   md:max-h-[600px] smMax:w-[80vw]'
              />
            </div>
            <section className='order-3 flex flex-col items-center justify-between gap-11 md:order-3'>
              <CardWithIcon
                description={t('product.benefit_3.description')}
                title={t('product.benefit_3.title')}
                icon={LikeIcon}
              />
              <CardWithIcon
                description={t('product.benefit_4.description')}
                title={t('product.benefit_4.title')}
                icon={TimeIcon}
              />
            </section>
          </div>
        </article>
        <article
          className='flex flex-col-reverse justify-center gap-10  rounded-[50px] bg-gray-50 px-10 py-24 sm:px-[9vw] md:flex-row md:gap-28 '
          id='team'
        >
          <div className=' w-full space-y-1'>
            <TeamAvatars />
            <h3 className='text-left text-gray-5 sm:text-center lg:text-left'>
              {t('our_team')}
            </h3>
          </div>
          <p className='max-w-[600px] text-3xl leading-[48px] sm:text-center md:text-left lg:text-[45px] lg:leading-[55px]'>
            {t('team.description_1')}
            <span className='text-accent'>{t('team.description_2')}</span>
            {t('team.description_3')}
            <span className='text-accent'>{t('team.description_4')}</span>
            {t('team.description_5')}
            <span className='text-accent'>{t('team.description_6')}</span>
          </p>
        </article>
        <article className='flex flex-col items-center justify-center gap-10 overflow-hidden pb-48 pl-2 pt-24 sm:pl-[10vw] xl:flex-row  xl:items-start xl:gap-28 '>
          <div className=' sm:text-center xl:max-w-[270px] xl:text-left '>
            <h2 className='text-5xl leading-[54px] lg:text-5xl lg:leading-[60px]'>
              {t('service.title_1')}
              <span className='font-medium text-accent'>
                {t('service.title_2')}
              </span>
              ?
            </h2>
            <p className='text-xl text-gray-5'>{t('service.description')}</p>
          </div>
          <ServicesCarousel />
        </article>
        <article
          className='flex w-full flex-col items-center justify-center gap-12 rounded-[50px] bg-[#f8f8f8] py-24 pt-12 sm:gap-20 md:pt-16 smMax:px-2'
          id='advantages'
        >
          <h2 className='text-5xl leading-[54px] lg:leading-[60px] smMax:text-left'>
            <span className='font-medium text-accent'>
              {t('advantages.title_1')}
            </span>
            {t('advantages.title_2')}
          </h2>
          <div className='flex flex-col gap-12 sm:gap-24 xl:flex-row  xl:gap-14'>
            <AdvantagesCard
              type='client'
              handleLogin={() => handleLogin('client')}
            />
            <AdvantagesCard
              type='specialist'
              handleLogin={() => handleLogin('specialist')}
            />
          </div>
        </article>
      </main>
      <ContactsSection />
      <AcceptCookiesModal />
    </>
  );
};

export default AboutPage;
