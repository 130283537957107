import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import CategorySkeleton from 'components/common/Category/CategorySkeleton';
import { useApiRequest } from 'hooks/useApiRequest';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { setCategories } from 'redux/categorySlice';
import { SectionHeader, Wrapper } from 'ui';
import CategoryItem from 'components/common/Category/CategoryItem';
import { Link } from 'react-router-dom';
import { BASE_URL } from 'api/api';
import { BiSolidPencil } from 'react-icons/bi';
import { Category } from 'react-app-env';
import { toggleModal } from 'redux/modalSlice';
import NoSpecialistsModal from 'modals/NoSpecialistsModal';
import { useTranslation } from 'react-i18next';

const CategoriesPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { categories } = useAppSelector((state) => state.category);
  const { address, role, city, country } = useAppSelector(
    (state) => state.user,
  );

  const redirectTo =
    role === 'client' ? '/category' : '/specialist/services/select';
  const apiURL =
    role === 'client' ? `categories/${city}/${country}` : 'categories';

  if (role === 'client' && !city) {
    dispatch(toggleModal({ modalType: 'noSpecInLocation' }));
  }

  const { loading, data: fetchedCategories } = useApiRequest<Category[]>({
    endpoint: !city && role === 'client' ? null : `${BASE_URL}${apiURL}`,
    destructuringDataType: 'categories',
    deps: city,
  });

  useEffect(() => {
    if (!loading && fetchedCategories && fetchedCategories.length > 0) {
      const allCategoriesEnabled = fetchedCategories.every(
        (category) => !category.enable,
      );
      if (role === 'client' && allCategoriesEnabled) {
        dispatch(toggleModal({ modalType: 'noSpecInLocation' }));
      }
      dispatch(setCategories(fetchedCategories));
    }
  }, [loading, fetchedCategories, dispatch, role]);

  return (
    <Wrapper className='!px-4'>
      <SectionHeader
        title={role === 'client' ? t('category') : t('services.add')}
        subtitle={
          role === 'client' ? (
            <Link to='/location' className=' underline hover:no-underline'>
              {address} <BiSolidPencil className='inline' />
            </Link>
          ) : (
            t('add_service.description')
          )
        }
        wrapperClassName='smMax:!-mx-2'
      />
      <ul className='mt-6 grid grid-cols-2 justify-items-stretch gap-5 sm:max-h-[90vh] sm:overflow-y-auto xs:gap-2 '>
        {loading ? (
          <>
            <CategorySkeleton />
            <CategorySkeleton />
          </>
        ) : (
          categories.map((category) => (
            <CategoryItem
              key={category.id}
              category={category}
              link={redirectTo}
            />
          ))
        )}
      </ul>
      {role === 'client' &&
        ReactDOM.createPortal(
          <NoSpecialistsModal />,
          document.getElementById('root') as Element,
        )}
    </Wrapper>
  );
};

export default CategoriesPage;
