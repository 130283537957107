import React from 'react';
import SectionLabel from './SectionLabel';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { Service } from 'react-app-env';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setShowSheetOfServices } from 'redux/createOrderPageSlice';
import StarManIcon from 'images/emoji/StarMan.png';
import { useAppSelector } from 'hooks/useAppSelector';
import { ServiceSelectionButton } from 'ui';

type ServiceSectionProps = {
  services: Service[];
  handleChooseService: (service: Service) => void;
  chosenServices: Service[];
};

const variants: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
    type: 'tween',
  },
  closed: { opacity: 0, height: 0, type: 'tween' },
};

const ServiceSection = ({
  services,
  handleChooseService,
  chosenServices,
}: ServiceSectionProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showSheetOfServices, isOpenSelectCustomer } = useAppSelector(
    (state) => state.createOrderPage,
  );

  return (
    <>
      <SectionLabel
        title={t('create_order.specialist_services')}
        description={chosenServices.map((service) => service.name).join(', ')}
        icon={StarManIcon}
        enable={showSheetOfServices}
        onClick={() => dispatch(setShowSheetOfServices(!showSheetOfServices))}
      />
      <AnimatePresence>
        {showSheetOfServices && (
          <motion.li
            variants={variants}
            initial='closed'
            animate={!isOpenSelectCustomer ? 'open' : 'closed'}
            exit='closed'
          >
            <ul className='my-2 flex max-h-[35vh] flex-col gap-1 overflow-y-auto px-3'>
              {services?.map((service, i) => {
                const isChosen = chosenServices.some(
                  (chosenService) =>
                    chosenService.stringId === service.stringId &&
                    chosenService.gssDto.createdAt === service.gssDto.createdAt,
                );
                return (
                  <li
                    className='mb-3 mr-2 flex-col'
                    key={service.id + i}
                    onClick={() => handleChooseService(service)}
                  >
                    <ServiceSelectionButton
                      service={service}
                      isChosen={isChosen}
                    />
                  </li>
                );
              })}
              {services?.length === 0 && (
                <p className='text-center text-secondary'>
                  {t('order.no_services_available')}
                </p>
              )}
            </ul>
          </motion.li>
        )}
      </AnimatePresence>
    </>
  );
};

export default ServiceSection;
