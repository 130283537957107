import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LatLng } from 'use-places-autocomplete';

type MapState = {
  coordinates: LatLng;
};

const initialState: MapState = {
  coordinates: {
    lat: 0,
    lng: 0,
  },
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setCoordinates: (state, { payload }: PayloadAction<LatLng>) => {
      return { ...state, coordinates: payload };
    },
  },
});

export const { setCoordinates } = mapSlice.actions;

export default mapSlice.reducer;
