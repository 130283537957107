import React, { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import { Variants, motion } from 'framer-motion';
import { useNavigate } from 'react-router';
import { ContactItem, CustomerLabel, Input } from 'ui';
import { useAppSelector } from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { BASE_URL } from 'api/api';
import { useApiRequest } from 'hooks/useApiRequest';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  addCustomer,
  setIsOpenSelectCustomer,
} from 'redux/createOrderPageSlice';

const variants: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
    type: 'tween',
  },
  closed: { opacity: 0, height: 0, type: 'tween' },
};

type ClientSectionProps = {
  toggleCustomerSelect?: () => void;
};

type Contact = { name: string; phone: string; clientIdStr: string };

const ClientSection = ({ toggleCustomerSelect }: ClientSectionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { phoneNumber, customerName, isOpenSelectCustomer } = useAppSelector(
    (state) => state.createOrderPage,
  );
  const { role } = useAppSelector((state) => state.user);
  const [searchCustomer, setSearchCustomer] = useState('');

  const { data: contacts } = useApiRequest<Contact[]>({
    endpoint:
      role === 'specialist'
        ? `${BASE_URL}specialists/clientsContactInfo`
        : null,
    destructuringDataType: 'clients',
  });

  // Used only on specialist side
  const handleSelectClient = (
    name: string,
    phone: string,
    clientId: string,
  ) => {
    dispatch(addCustomer({ name, phoneNumber: phone, clientId, surname: '' }));
    dispatch(setIsOpenSelectCustomer(false));
  };

  // Search client by name or phone
  const filteredContacts = contacts?.filter(
    (contact) =>
      contact.name.toLowerCase().includes(searchCustomer.toLowerCase()) ||
      contact.phone.includes(searchCustomer),
  );
  return (
    <>
      <li
        className={`${
          isOpenSelectCustomer ? '' : 'border-b'
        } flex cursor-pointer items-center justify-between border-gray-3 px-5 py-6 hover:bg-gray-50`}
        onClick={toggleCustomerSelect}
      >
        <CustomerLabel phoneNumber={phoneNumber} customerName={customerName} />
        <motion.div animate={{ rotate: isOpenSelectCustomer ? 90 : 0 }}>
          <FaChevronRight className='text-secondary' />
        </motion.div>
      </li>
      {isOpenSelectCustomer && (
        <>
          <motion.li
            className='flex flex-col items-center px-5'
            variants={variants}
            initial='closed'
            animate={isOpenSelectCustomer ? 'open' : 'closed'}
            exit='closed'
          >
            <Input
              value={searchCustomer}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchCustomer(e.target.value)
              }
              className='mt-2 !rounded-md  border px-2 py-4'
              placeholder={t('order.specialist_found.telephone_number')}
              Icon={
                <FaPlus
                  size={30}
                  className='cursor-pointer rounded-md bg-[#FFDD6473] p-1 text-[#9E9E9E] transition-all hover:text-primary active:p-2'
                  title='Create a new customer'
                  onClick={() =>
                    navigate('/specialist/add-customer', {
                      state: searchCustomer,
                    })
                  }
                />
              }
              wrapperClassName='w-[90%]'
            />
          </motion.li>
          <ul className='mt-2 flex max-h-[40vh] flex-col overflow-y-auto'>
            {filteredContacts?.map(({ name, phone, clientIdStr }) => (
              <ContactItem
                key={phone}
                phone={phone}
                name={name}
                clientIdStr={clientIdStr}
                handleSelectClient={handleSelectClient}
              />
            ))}
          </ul>
        </>
      )}
    </>
  );
};

export default ClientSection;
