import React from 'react';
import { useNavigate } from 'react-router';

type ProfileAvatarLinkProps = {
  text: string;
  link: string;
};

const ProfileAvatarLink = ({ text, link }: ProfileAvatarLinkProps) => {
  const navigate = useNavigate();

  const isExternal = link.startsWith('http');

  if (isExternal) {
    return (
      <li className='block cursor-pointer px-6 py-3 leading-tight transition-all hover:bg-gray-200'>
        <a
          href={link}
          target='_blank'
          rel='noopener noreferrer'
          className='block w-full'
        >
          {text}
        </a>
      </li>
    );
  }

  const handleNavigate = () => {
    navigate(link);
  };
  return (
    <li
      className='block cursor-pointer px-6 py-3 leading-tight transition-all hover:bg-gray-200'
      onClick={handleNavigate}
    >
      {text}
    </li>
  );
};

export default ProfileAvatarLink;
