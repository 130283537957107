import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Category, setSelectedCategoryName } from 'redux/categorySlice';
import { setCategoryId } from 'redux/serviceIdSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

type CategoryItemProps = {
  category: Category;
  link: string;
};

const CategoryItem = ({ category, link }: CategoryItemProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { name, stringId, imgURL, enable = true } = category;

  const handleClick = () => {
    if (enable) {
      dispatch(setCategoryId(stringId));
      dispatch(setSelectedCategoryName(name));
      navigate(`${link}/${stringId}`);
    }
  };

  return (
    <motion.li
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className={`mx-auto aspect-square max-h-[188px] max-w-[188px] rounded-[18px] border ${
        !enable
          ? 'relative border-[#DADADA]'
          : 'cursor-pointer border-accent hover:shadow-md'
      } `}
      onClick={handleClick}
    >
      <motion.img
        initial={{ opacity: 0.5 }}
        animate={{
          opacity: !enable ? 0.5 : 1,
        }}
        transition={{ duration: 0.3 }}
        src={imgURL}
        alt='category'
        className={`aspect-[93/64] rounded-t-[18px] ${
          !enable && 'grayscale-[1]'
        }`}
        width='186'
        height='128'
        loading='lazy'
      />
      <div className='flex items-center justify-center py-2'>
        <p className='text-center xs:text-sm'>{name}</p>
        {!enable && (
          <p className='absolute left-1/2 top-1/2 z-10 w-full -translate-x-1/2  -translate-y-1/2 transform select-none text-center text-sm font-medium xs:top-1/3 xs:text-xs '>
            {t('sabcategories.comming_soon')}
          </p>
        )}
      </div>
    </motion.li>
  );
};

export default CategoryItem;
