import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import { RxAvatar } from 'react-icons/rx';
import { useNavigate } from 'react-router';
import { setUserRole } from 'redux/userSlice';
import { Button } from 'ui';
import { logOut } from 'api/login';

const LoginButton = () => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useOnclickOutside(() => {
    if (showDropdown) setShowDropdown(false);
  });
  const navigate = useNavigate();
  const { isLoggedIn, role, uid } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const handleLogOut = async () => {
    await logOut(uid, role as 'client' | 'specialist', dispatch, navigate);
  };

  const handleLogin = (role: 'specialist' | 'client') => {
    dispatch(setUserRole(role));
    navigate('/login');
  };
  return (
    <div ref={dropdownRef} className='relative'>
      <Button
        variant='whiteStroke'
        className='inline-flex items-center justify-between gap-2 !border-black uppercase hover:text-gray-600 active:scale-95 active:text-gray-600  smMax:text-sm'
        onClick={() => {
          if (isLoggedIn) {
            handleLogOut();
            return;
          }
          setShowDropdown(!showDropdown);
        }}
      >
        <RxAvatar size={20} />
        {isLoggedIn ? t('menu.logout') : t('log_in.name')}
      </Button>
      <AnimatePresence>
        {showDropdown && !isLoggedIn && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className='absolute -left-14 top-full z-50 flex flex-col gap-4 rounded-lg bg-white p-4 '
          >
            <Button
              variant='black'
              className='uppercase'
              onClick={() => handleLogin('client')}
            >
              {t('register_as_customer')}
            </Button>
            <Button
              variant='black'
              className='uppercase'
              onClick={() => handleLogin('specialist')}
            >
              {t('register_as_specialist')}
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LoginButton;
