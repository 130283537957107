import React, { useEffect, useState } from 'react';
import { fetchRequestBoolean, fetchRequestData } from 'api/api';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import {
  FaCheck,
  FaCircleNotch,
  FaInstagram,
  FaRegCopy,
} from 'react-icons/fa6';
import { setReferralCode } from 'redux/userSlice';
import { Input, toastWithDismiss } from 'ui';
import toast from 'react-hot-toast';
import { setInstagramLink as setInstagramLinkStore } from 'redux/specialistSlice';

const isValidInstagramUrl = (url: string): boolean => {
  return url.includes('instagram');
};

const clearUrlParameters = (url: string) => {
  try {
    const urlObj = new URL(url);
    return urlObj.origin + urlObj.pathname; // This will return the URL without any query parameters
  } catch (error) {
    console.error('Error clearing URL parameters:', error);
    return url;
  }
};

const SocialSection = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { uid, referralCode } = useAppSelector((state) => state.user);
  const { instagramLink: instagramLinkStore } = useAppSelector(
    (state) => state.specialist,
  );
  const [referralLink, setReferralLink] = useState(
    referralCode
      ? `${window.location.hostname}/login?ref=${referralCode}`
      : 'Something went wrong',
  );
  const [instagramLink, setInstagramLink] = useState(instagramLinkStore);
  const [isFocusedInst, setIsFocusedInst] = useState(false);
  const [isUploadingLink, setIsUploadingLink] = useState(false);

  const copyToClipboard = async () => {
    if (referralCode) {
      if (!navigator.clipboard) {
        copyToClipboardFallback(referralLink);
        return;
      }
      try {
        await navigator.clipboard.writeText(referralLink);
        toast.dismiss();
        toastWithDismiss(t('msg.copied_clickboard'), 'success');
      } catch (err) {
        toast.dismiss();
        toastWithDismiss('Failed to copy text', 'error');
      }
    }
  };

  const copyToClipboardFallback = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      toastWithDismiss(t('msg.copied_clickboard'), 'success');
    } catch (err) {
      toastWithDismiss('Failed to copy text', 'error');
    }
    document.body.removeChild(textArea);
  };

  const navigateToInstagram = () => {
    if (!isValidInstagramUrl(instagramLink)) {
      toast.dismiss();
      toastWithDismiss(t('edit_profile.invalid_instagram_link'), 'error');
      return;
    }
    if (instagramLink) {
      window.open(instagramLink, '_blank');
    }
  };

  const handleSaveInstagramLink = async () => {
    if (!instagramLink) {
      toastWithDismiss(t('edit_profile.empty_instagram_link'), 'error');
      return;
    }
    if (!isValidInstagramUrl(instagramLink)) {
      toastWithDismiss(t('edit_profile.invalid_instagram_link'), 'error');
      return;
    }

    if (instagramLinkStore === instagramLink) {
      toastWithDismiss(t('edit_profile.changes_saved'), 'success');
      return;
    }
    const cleanedInstagramLink = clearUrlParameters(instagramLink);

    setIsUploadingLink(true);
    const response = await fetchRequestBoolean(
      'specialists/updateInst',
      'POST',
      cleanedInstagramLink,
    );
    setIsUploadingLink(false);
    if (response) {
      dispatch(setInstagramLinkStore(cleanedInstagramLink));
      setInstagramLink(cleanedInstagramLink);
      toastWithDismiss(t('edit_profile.changes_saved'), 'success');
    } else {
      toastWithDismiss(t('something_wrong'), 'error');
    }
  };

  const handleFocus = () => {
    setIsFocusedInst(true);
  };

  const handleBlur = () => {
    setIsFocusedInst(false);
  };

  useEffect(() => {
    const fetchReferralLink = async () => {
      try {
        const response = await fetchRequestData(
          `specialist/generateReferral`,
          'POST',
          {
            specialistId: uid,
          },
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const refLinkFetched = await response.json();
        const domain = window.location.hostname;
        const referralLinkWithDomain = `${domain}/login?ref=${refLinkFetched.referralCode}`;
        setReferralLink(referralLinkWithDomain);
        dispatch(setReferralCode(refLinkFetched.referralCode));
      } catch (error) {}
    };

    if (uid && !referralCode) {
      fetchReferralLink();
    }
  }, [dispatch, referralCode, uid]);

  return (
    <div className='space-y-3'>
      <div className='flex flex-col gap-1'>
        <p className='text-sm text-secondary'>{t('profile.instagram_link')}</p>
        <Input
          onBlur={handleBlur}
          onFocus={handleFocus}
          Icon={
            <div className='absolute -right-[7px] z-[5] flex gap-2 bg-white px-2'>
              <FaInstagram
                onClick={navigateToInstagram}
                title='Open Instagram link'
                size={37}
                className='cursor-pointer rounded-md bg-[#FFDD6473] p-2 text-[#9E9E9E] transition-all hover:text-primary active:p-[10px]'
              />
              {isUploadingLink ? (
                <div className='rounded-md bg-[#FFDD6473]'>
                  <FaCircleNotch
                    className='animate-spin p-2 text-[#9E9E9E]'
                    size={37}
                  />
                </div>
              ) : (
                <FaCheck
                  onClick={handleSaveInstagramLink}
                  title='Save Instagram link'
                  size={37}
                  className='cursor-pointer rounded-md bg-[#FFDD6473] p-2 text-[#9E9E9E] transition-all hover:text-primary active:p-[10px]'
                />
              )}
            </div>
          }
          variant='outline'
          multiline={isFocusedInst}
          maxRows={2}
          value={instagramLink}
          onChange={(e) => setInstagramLink(e.target.value)}
          className='overflow-hidden text-ellipsis whitespace-nowrap pr-[95px] focus:whitespace-normal'
          iconTitle='Instagram link'
        />
      </div>
      <div className='flex flex-col gap-1'>
        <p className='text-sm text-secondary'>{t('profile.referral_link')}</p>
        <Input
          Icon={
            <FaRegCopy
              onClick={copyToClipboard}
              title='Copy link to clipboard'
              size={37}
              className='cursor-pointer rounded-md bg-[#FFDD6473] p-2 text-[#9E9E9E] transition-all hover:text-primary active:p-[10px]'
            />
          }
          variant='outline'
          className='cursor-default !border-accent pr-[45px]'
          iconTitle='Copy link to clipboard'
          readOnly
          value={referralLink}
        />
      </div>
    </div>
  );
};

export default SocialSection;
