import { LocationDto, Schedule, SpecialistProfileDto } from 'react-app-env';
import { NavigateFunction } from 'react-router';
import { resetState } from 'redux/createOrderPageSlice';
import { toggleModal } from 'redux/modalSlice';
import {
  SupportedLanguages,
  logoutUser,
  setSpecialist,
  setLoginUser,
  setUserLocation,
  setIsNewUser,
  setReferralCode,
  setSpecSubRole,
} from 'redux/userSlice';
import { AppDispatch } from 'store';
import {
  BASE_URL,
  fetchRequestBoolean,
  fetchRequestData,
  getClientAddress,
  getHeaders,
} from 'api/api';
import { resetSchedule, setSchedule } from 'redux/scheduleSlice';
import {
  clearSpecialistState,
  setInstagramLink,
  setSpecDescription,
} from 'redux/specialistSlice';
import { resetServices } from 'redux/specialistServices';
import { clearAllServicesIDs } from 'redux/serviceIdSlice';

export const sentCode = async (
  phoneNumber: string,
  userRole: 'client' | 'specialist',
  setUid: (uid: string) => void,
  dispatch: AppDispatch,
  ref: string | null,
) => {
  try {
    const response = await fetch(`${BASE_URL}login/${userRole}`, {
      method: 'POST',
      body: JSON.stringify({ phoneNumber, ref }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data: {
      id: number;
      stringId: string;
    } = await response.json();
    setUid(data.stringId);
  } catch (error) {
    dispatch(toggleModal({ modalType: 'error' }));
  }
};

export const resendCode = async (
  uid: string,
  setIsValid: (boolean: boolean) => void,
  role: 'client' | 'specialist',
) => {
  try {
    const url =
      role! === 'specialist'
        ? 'login/specialist/resendCode'
        : 'login/resendCode';

    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'POST',
      body: JSON.stringify(uid),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Request failed');
    }
  } catch (error) {
    setIsValid(false);
  }
};

export const verifyCode = async (
  uid: string,
  phoneNumber: string,
  role: 'client' | 'specialist',
  codeSMS: string,
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  setIsValid: (boolean: boolean) => void,
  refferalCode: string | null,
) => {
  try {
    const response = await fetchRequestData<{
      accessToken: string;
      refreshToken: string;
      tokenType: string;
    }>(`login/${role}/verifyCode/${uid}/${codeSMS}`, 'GET');

    if (!response) {
      throw new Error('Request failed');
    }
    localStorage.setItem('gotouAccessToken', response.accessToken);
    localStorage.setItem('gotouRefreshToken', response.refreshToken);
    localStorage.setItem('gotouTokenType', response.tokenType);

    try {
      const userLocale = localStorage.getItem('selectedLanguage') || 'en';

      const { locale } = await fetchRequestData<{
        locale: (typeof SupportedLanguages)[number];
      }>(`app-config/getLocale`);

      if (locale !== userLocale) {
        await fetchRequestBoolean(
          `app-config/changeLocale?selectedLocale=${userLocale}`,
          'POST',
        );
      }
    } catch (error) {}

    const responseRef = await fetch(`${BASE_URL}specialist/generateReferral`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        specialistId: uid,
      }),
    });

    const refLinkFetched = await responseRef.json();
    dispatch(setReferralCode(refLinkFetched.referralCode));

    if (role === 'specialist') {
      await handleSpecialist(uid, phoneNumber, role, dispatch, navigate);
    } else {
      await handleClient(
        uid,
        phoneNumber,
        role,
        dispatch,
        navigate,
        refferalCode,
      );
    }
    logSmile('happy');
  } catch (error) {
    setIsValid(false);
  }
};

export const verifyCodeForOrder = async (
  uid: string,
  phoneNumber: string,
  role: 'client' | 'specialist',
  codeSMS: string,
  dispatch: AppDispatch,
  setIsValid: (boolean: boolean) => void,
  firstName: string,
  lastName: string,
): Promise<boolean> => {
  try {
    const response = await fetchRequestData<{
      accessToken: string;
      refreshToken: string;
      tokenType: string;
    }>(`login/${role}/verifyCode/${uid}/${codeSMS}`, 'GET');

    if (!response) {
      throw new Error('Request failed');
    }
    localStorage.setItem('gotouAccessToken', response.accessToken);
    localStorage.setItem('gotouRefreshToken', response.refreshToken);
    localStorage.setItem('gotouTokenType', response.tokenType);
    const userLocale = localStorage.getItem('selectedLanguage') || 'en';

    const { locale } = await fetchRequestData<{
      locale: (typeof SupportedLanguages)[number];
    }>(`app-config/getLocale`);

    if (locale !== userLocale) {
      await fetchRequestBoolean(
        `app-config/changeLocale?selectedLocale=${userLocale}`,
        'POST',
      );
    }
    const { fullName, latitude, longitude, country, city } =
      await getClientAddress(uid);

    const { name, photoUrl, surname } = await fetchRequestData<{
      address: string;
      name: string;
      photoUrl: string;
      surname: string;
    }>(`clients/info`, 'GET');

    const user = {
      uid,
      phoneNumber,
      role,
      firstName: name || firstName,
      lastName: surname || lastName,
      profilePhoto: photoUrl,
    };
    dispatch(setIsNewUser(false));

    if (fullName && latitude && longitude && country && city && name) {
      dispatch(
        setLoginUser({
          ...user,
          address: fullName,
          country,
          city,
          coordinates: { lat: latitude, lng: longitude },
        }),
      );
    } else {
      dispatch(setLoginUser(user));
    }
    return true;
  } catch (error) {
    setIsValid(false);
    return false;
  }
};

const handleSpecialist = async (
  uid: string,
  phoneNumber: string,
  role: 'specialist',
  dispatch: AppDispatch,
  navigate: NavigateFunction,
) => {
  const specialist = await getSpecialist(uid);
  const specLocation = await fetchRequestData<LocationDto>(
    `specialists/${uid}/location`,
    'GET',
  );
  const { dtos } = await fetchRequestData<{ dtos: Schedule[] }>(
    `specialists/${uid}/schedule`,
    'GET',
  );

  dispatch(
    setSpecialist({
      uid,
      phoneNumber,
      role,
      ...specialist.profileDto,
    }),
  );
  if (specialist.profileDto.insta) {
    dispatch(setInstagramLink(specialist.profileDto.insta.replace(/"/g, '')));
  }
  if (specialist.profileDto.descr) {
    dispatch(setSpecDescription(specialist.profileDto.descr.replace(/"/g, '')));
  }

  if (!specLocation.city) {
    dispatch(setSpecSubRole('acceptingTerms'));
    dispatch(toggleModal({ modalType: 'terms' }));
    dispatch(setIsNewUser(true));

    return;
  }

  let linkToNavigate = '';

  if (specialist.profileDto) {
    dispatch(setSpecSubRole('editingProfile'));
    linkToNavigate = 'specialist/profile/edit';
  }

  if (specLocation) {
    dispatch(
      setUserLocation({
        address: specialist.profileDto.address,
        coordinates: {
          lat: specLocation.latitude!,
          lng: specLocation.longitude!,
        },
        city: specLocation.city,
        country: specLocation.country,
      }),
    );
    dispatch(setSpecSubRole('editingSchedule'));

    linkToNavigate = 'specialist/schedule';
  }
  if (dtos && Array.isArray(dtos) && dtos.length > 0) {
    dispatch(setSchedule(dtos));
    dispatch(setSpecSubRole('full'));
    linkToNavigate = 'specialist/profile';
  }

  dispatch(setIsNewUser(linkToNavigate !== 'specialist/profile'));

  navigate(linkToNavigate);
};

const handleClient = async (
  uid: string,
  phoneNumber: string,
  role: 'client',
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  refferalCode: string | null,
) => {
  const { fullName, latitude, longitude, country, city } =
    await getClientAddress(uid);

  const { name, photoUrl, surname } = await fetchRequestData<{
    address: string;
    name: string;
    photoUrl: string;
    surname: string;
  }>(`clients/info`, 'GET');

  const user = {
    uid,
    phoneNumber,
    role,
    firstName: name,
    lastName: surname,
    profilePhoto: photoUrl,
  };

  if (fullName && latitude && longitude && country && city && name) {
    dispatch(
      setLoginUser({
        ...user,
        address: fullName,
        country,
        city,
        coordinates: { lat: latitude, lng: longitude },
      }),
    );
    if (refferalCode) {
      navigate(`/create-order?ref=${refferalCode}`);
      return;
    }

    navigate('/category');
  } else {
    dispatch(setLoginUser(user));
    navigate('/profile/edit');
  }
};

export const logOut = async (
  uid: string,
  role: 'client' | 'specialist',
  dispatch: AppDispatch,
  navigate: NavigateFunction,
) => {
  const user = role === 'client' ? 'Client' : 'Specialist';

  navigate('/');
  await fetchRequestBoolean(`login/logout${user}/${uid}`, 'POST');
  logSmile('sad');

  localStorage.removeItem('gotouAccessToken');
  localStorage.removeItem('gotouRefreshToken');
  dispatch(logoutUser());
  dispatch(resetState());
  dispatch(clearAllServicesIDs());
  dispatch(resetServices());
  dispatch(resetSchedule());
  dispatch(clearSpecialistState());
};

const getSpecialist = async (uid: string) => {
  return await fetchRequestData<{
    profileDto: SpecialistProfileDto;
  }>(`specialists/${uid}`, 'GET');
};

const logSmile = (type: 'sad' | 'happy') => {
  if (type === 'happy') {
    console.log(
      '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬛⬛⬛⬜⬜⬜⬛⬛⬛⬜⬜⬜\n' +
        '⬜⬜⬛⬛⬛⬛⬛⬜⬛⬛⬛⬛⬛⬜⬜\n' +
        '⬜⬜⬛⬛⬜⬛⬛⬛⬛⬛⬜⬛⬛⬜⬜\n' +
        '⬜⬜⬛⬛⬛⬛⬛⬜⬛⬛⬛⬛⬛⬜⬜\n' +
        '⬜⬜⬜⬛⬛⬛⬜⬜⬜⬛⬛⬛⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜🟨🟨⬜⬜⬜⬜⬜⬜🟨🟨⬜⬜⬜\n' +
        '⬜⬜🟨🟨🟨⬜⬜⬜⬜🟨🟨🟨⬜⬜⬜\n' +
        '⬜⬜⬜🟨🟨🟨⬜⬜🟨🟨🟨⬜⬜⬜⬜\n' +
        '⬜⬜⬜🟨🟨🟨🟨🟨🟨🟨🟨⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜🟨🟨🟨🟨⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜',
    );
  } else {
    console.log(
      '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬛⬛⬛⬜⬜⬜⬛⬛⬛⬜⬜⬜\n' +
        '⬜⬜⬛⬛⬛⬛⬛⬜⬛⬛⬛⬛⬛⬜⬜\n' +
        '⬜⬜⬛⬛⬜⬛⬛⬛⬛⬛⬜⬛⬛⬜⬜\n' +
        '⬜⬜⬛⬛⬛⬛⬛⬜⬛⬛⬛⬛⬛⬜⬜\n' +
        '⬜⬜⬜⬛⬛⬛⬜⬜⬜⬛⬛⬛⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜🟨🟨🟨🟨⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜🟨🟨🟨🟨🟨🟨🟨🟨⬜⬜⬜⬜\n' +
        '⬜⬜🟨🟨🟨⬜⬜⬜⬜🟨🟨🟨⬜⬜⬜\n' +
        '⬜⬜🟨🟨⬜⬜⬜⬜⬜⬜🟨🟨⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n' +
        '⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜⬜\n',
    );
  }
};
