import React, { useState } from 'react';
import { withModalHandling } from './withModalHandling';
import { Button, Input, ModalWrapper } from 'ui';
import { IoClose } from 'react-icons/io5';
import PhoneInput from 'react-phone-input-2';
import toast from 'react-hot-toast';
import { fetchRequestBoolean } from 'api/api';
import { validateEmail, validatePhoneNumber } from 'utils/validations';
import { useAppSelector } from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';

const InvestorModalForm = ({
  handleCloseModal,
}: {
  handleCloseModal: () => void;
}) => {
  // TODO - add localization
  const { t } = useTranslation();
  const { value: emailType } = useAppSelector((state) => state.emailType);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const [hasEmailError, setHasEmailError] = useState(false);
  const [hasPhoneError, setHasPhoneError] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailHelperText('Invalid email');
      setHasEmailError(true);
      return;
    }
    setHasEmailError(false);
    setEmailHelperText('');

    if (!validatePhoneNumber(`+${phone}`)) {
      toast.error('Invalid phone number');
      setHasPhoneError(true);
      return;
    }
    setHasPhoneError(false);

    let queryString = `email=${encodeURIComponent(
      email,
    )}&name=${encodeURIComponent(name)}&type=${emailType}`;

    if (phone) {
      queryString += `&phoneNumber=${encodeURIComponent(`+${phone}`)}`;
    }

    if (company) {
      queryString += `&companyName=${encodeURIComponent(company)}`;
    }

    const response = await fetchRequestBoolean(
      `email-contact/create?${queryString}`,
      'POST',
    );

    if (response) {
      toast.success(t('request_sent'));
      setName('');
      setEmail('');
      setPhone('');
      handleCloseModal();
    } else {
      toast.error(t('something_wrong'));
    }
  };

  return (
    <ModalWrapper modalId='investorForm'>
      <IoClose
        className='absolute right-5 top-5 cursor-pointer'
        size={25}
        onClick={handleCloseModal}
      />
      <h2 className='my-4 text-center text-2xl font-medium'>
        {emailType === 'GET_DEMO'
          ? t('get_demo')
          : emailType === 'GET_PITCH'
            ? t('get_pitch_deck')
            : t('become_investor')}
      </h2>
      <form
        onSubmit={handleSubmit}
        className='flex flex-col gap-4'
        id='investor-form'
      >
        <Input
          type='text'
          placeholder={t('name')}
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          type='email'
          placeholder='Email'
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          helperText={emailHelperText}
          hasError={hasEmailError}
        />
        {(emailType === 'GET_DEMO' || emailType === 'GET_PITCH') && (
          <Input
            type='text'
            placeholder='Company name'
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        )}
        <PhoneInput
          regions={'europe'}
          placeholder={t('edit_profile.phone_number')}
          inputClass={`hover:border-accent focus:border-accent ${
            hasPhoneError && '!border-error'
          }`}
          value={phone}
          onChange={(value) => setPhone(`${value}`)}
        />
      </form>
      <Button
        variant='black'
        className='mx-auto w-1/2'
        type='submit'
        form='investor-form'
      >
        Send
      </Button>
    </ModalWrapper>
  );
};

export default withModalHandling(InvestorModalForm, 'investorForm');
