import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/useAppSelector';
import SettingsList, {
  Settings,
} from 'components/specialist/Settings/SettingsList';
import { SectionHeader, SpecialistAvatar, Wrapper, SpecialistStats } from 'ui';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { BiSolidPencil } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import AcceptToast from 'modals/AcceptToast';
import { logOut } from 'api/login';
import toast from 'react-hot-toast';
import SocialSection from 'components/specialist/Profile/SocialSection';

const getSettingsProfile = (
  uid: string,
  t: TFunction<'translation', undefined>,
): Settings[] => [
  { id: 1, name: t('profile.services'), link: '/specialist/services' },
  { id: 2, name: t('profile.create_order'), link: '/specialist/create-order' },
  { id: 3, name: t('menu.calendar'), link: '/specialist/calendar' },
  {
    id: 4,
    name: t('profile.portfolio'),
    link: `/specialist/portfolio/${uid}`,
  },
  { id: 6, name: t('profile.schedule'), link: '/specialist/schedule' },
  { id: 7, name: t('profile.balance'), link: '/specialist/balance' },
  { id: 8, name: t('edit.profile_location'), link: '/location' },
];

const ProfilePage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const {
    firstName,
    since,
    reviewNumber,
    rank,
    imagesAmount,
    profilePhoto,
    uid,
    role,
  } = useAppSelector((state) => state.user);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get('ref');
  const navigate = useNavigate();

  useEffect(() => {
    if (referralCode) {
      AcceptToast({
        saveButtonText: t('menu.logout'),
        cancelButtonText: t('client.remove_favorite.no'),
        text: t('referral.specialits'),
        handleSaveClick: async () => {
          await logOut(
            uid,
            role as 'client' | 'specialist',
            dispatch,
            navigate,
          );
          navigate('/login?ref=' + referralCode);
          toast.dismiss();
        },
        duration: 100000,
      });
    }
  }, [referralCode, uid, role, dispatch, navigate, t]);

  return (
    <Wrapper className='flex flex-col gap-4'>
      <div className='flex items-start justify-between smMax:mt-2 xs:flex-col xs:gap-3'>
        <div className='flex flex-col items-center justify-center gap-3'>
          <SpecialistAvatar
            src={profilePhoto}
            size={120}
            showLikeButton={false}
          />
          <Link
            to='/specialist/profile/edit'
            aria-label='Edit profile'
            className='inline-flex max-w-[120px] items-end border-b border-secondary text-center text-secondary hover:border-0 xs:text-center'
          >
            {t('edit_profile.header')} <BiSolidPencil />
          </Link>
        </div>
        <SpecialistStats
          reviewsNumber={reviewNumber}
          rank={rank}
          imagesAmount={imagesAmount}
          portfolioUid={uid}
          reviewsUid={uid}
        />
      </div>
      <SectionHeader
        title={firstName}
        subtitle={`${t('edit_profile.since')} ${since}`}
        showGoBackButton={false}
        wrapperClassName='smMax:pl-5'
      />
      <SocialSection />
      <SettingsList
        title={t('profile.settings')}
        settings={getSettingsProfile(uid, t)}
      />
    </Wrapper>
  );
};

export default ProfilePage;
