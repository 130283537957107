import React, { useCallback, useRef, useState } from 'react';
import {
  GoogleMap,
  Libraries,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { SectionHeader, Wrapper } from 'ui';
import MarkerIcon from 'images/icons/marker-map.svg';
import { defaultMapTheme } from 'components/common/Location/theme';
import { useAppSelector } from 'hooks/useAppSelector';

const defaultOptions: google.maps.MapOptions = {
  zoomControl: true,
  panControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  clickableIcons: false,
  keyboardShortcuts: false,
  fullscreenControl: false,
  scrollwheel: false,
  disableDefaultUI: true,
  gestureHandling: 'greedy',
  styles: defaultMapTheme,
  maxZoom: 18,
  minZoom: 14,
  disableDoubleClickZoom: true,
};

const MapPage = () => {
  const [LIBRARIES] = useState<Libraries>(['places']);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: LIBRARIES,
  });

  const { coordinates } = useAppSelector((state) => state.map);
  const { lat, lng } = coordinates;

  const mapRef = useRef<google.maps.Map | undefined>(undefined);

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      mapRef.current = map;

      const bounds = new google.maps.LatLngBounds();
      bounds.extend(coordinates);

      bounds.extend({ lat: lat + 0.08, lng: lng + 0.08 });
      bounds.extend({ lat: lat - 0.08, lng: lng - 0.08 });

      map.addListener('bounds_changed', () => {
        if (!bounds.contains(map.getCenter() as google.maps.LatLng)) {
          map.setCenter(coordinates);
        }
      });
    },
    [coordinates, lat, lng],
  );

  const onUnmount = useCallback(() => {
    mapRef.current = undefined;
  }, []);

  return (
    <Wrapper className='!p-0'>
      <SectionHeader wrapperClassName='absolute top-5 left-5 z-10 !bg-transparent' />
      {isLoaded && (
        <GoogleMap
          key={lat}
          mapContainerClassName={`smMax:h-[90vh] h-[750px]  w-auto overflow-hidden `}
          center={coordinates}
          zoom={16}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={defaultOptions}
        >
          <Marker
            position={coordinates}
            icon={{
              url: MarkerIcon,
              scaledSize: new window.google.maps.Size(26, 26),
            }}
          />
        </GoogleMap>
      )}
    </Wrapper>
  );
};

export default MapPage;
