import React, { useState } from 'react';
import { IconType } from 'react-icons';
import ReactTextareaAutosize from 'react-textarea-autosize';

type InputProps = {
  type?: string;
  placeholder?: string;
  className?: string;
  helperText?: string;
  value?: string;
  accept?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  hasError?: boolean;
  required?: boolean;
  disabled?: boolean;
  border?: boolean;
  restrictSymbols?: boolean;
  min?: string | number;
  max?: string | number;
  Icon?: IconType | JSX.Element;
  onIconClick?: () => void;
  iconClassName?: string;
  label?: string;
  wrapperClassName?: string;
  multiline?: boolean;
  iconTitle?: string;
  readOnly?: boolean;
  variant?: 'default' | 'outline';
  maxRows?: number;
  onBlur?: () => void;
  onFocus?: () => void;
};

const Input = React.forwardRef<any, InputProps>(
  (
    {
      className,
      helperText,
      hasError,
      required,
      disabled,
      value,
      onChange,
      accept,
      restrictSymbols,
      min,
      max,
      Icon,
      onIconClick,
      iconClassName,
      label,
      wrapperClassName,
      iconTitle,
      readOnly,
      maxRows,
      onBlur,
      onFocus,
      variant = 'default',
      multiline = false,
      border = true,
      type = 'text',
      placeholder = '',
      ...props
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false); // Add a new state for focus

    const handleFocus = () => {
      setIsFocused(true);
      onFocus && onFocus();
    };

    const handleBlur = () => {
      setIsFocused(false);
      onBlur && onBlur();
    };

    const handleInputChange = (e: React.ChangeEvent<any>) => {
      const newValue = e.target.value.replace(/<[^>]*>?/gm, '');

      if (onChange) {
        onChange({
          ...e,
          target: {
            ...e.target,
            value: newValue,
          },
        });
      }
    };

    return (
      <div className={`input-with-placeholder relative ${wrapperClassName}`}>
        {label && (
          <label
            className={`pointer-events-none block select-none text-sm text-secondary ${
              isFocused || value ? 'label-top' : ''
            }`}
          >
            {label}
          </label>
        )}
        {multiline ? (
          <ReactTextareaAutosize
            ref={ref}
            placeholder={placeholder}
            value={value}
            onChange={handleInputChange}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
            maxRows={maxRows}
            onFocus={handleFocus}
            onBlur={handleBlur}
            cols={1}
            className={`${className} ${
              hasError
                ? 'border-error hover:border-error focus:border-error'
                : 'border-secondary'
            } my-1 w-full ${
              border ? 'border-b-[1.5px]' : ''
            } resize-none outline-none transition-all hover:border-accent focus:border-accent ${
              variant === 'outline'
                ? 'rounded-lg border px-2 py-4'
                : 'rounded-none border-b-[1.5px]'
            }`}
          >
            {value}
          </ReactTextareaAutosize>
        ) : (
          <input
            ref={ref}
            type={type}
            placeholder={placeholder}
            className={`${className} ${
              hasError
                ? 'border-error hover:border-error focus:border-error'
                : 'border-secondary'
            } my-1 w-full outline-none transition-all hover:border-accent focus:border-accent disabled:bg-white ${
              required ? 'required-input' : ''
            } ${
              variant === 'outline'
                ? 'rounded-lg border px-2 py-4'
                : 'rounded-none border-b-[1.5px]'
            }`}
            required={required}
            value={value}
            onChange={handleInputChange}
            disabled={disabled}
            accept={accept}
            min={min}
            max={max}
            readOnly={readOnly}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...props}
          />
        )}
        {Icon && typeof Icon === 'function' && (
          <Icon
            className={`absolute right-2 top-1/2 -translate-y-1/2 transform ${iconClassName}`}
            onClick={onIconClick}
            title={iconTitle}
            size={20}
          />
        )}
        {Icon && typeof Icon !== 'function' && (
          <span
            className={`absolute right-2 top-1/2 inline-flex -translate-y-1/2 items-center justify-center gap-2 ${iconClassName}`}
          >
            {Icon}
          </span>
        )}
        {helperText && (
          <p
            className={`${
              hasError ? 'text-error' : ''
            } -mt-2 mb-2 break-words text-sm`}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  },
);

export default Input;
