import React from 'react';
import { LanguageDropDown } from 'ui';
import AboutPageHamburgerNavigation from './AboutPageHamburgerNavigation';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'hooks/useAppSelector';
import HeaderLink from './HeaderLink';
import { useTranslation } from 'react-i18next';
import LoginButton from './LoginButton';

export const MainLinks = [
  { key: 'product', value: 'product' },
  { key: 'benefits', value: 'advantages' },
  { key: 'contacts', value: 'contacts' },
];

const AboutHeader = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { role, isLoggedIn } = useAppSelector((state) => state.user);

  const backToAppPath = role === 'client' ? '/category' : '/specialist/profile';

  const handleNavigation = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate('/#' + id, { replace: true });
    }
  };

  return (
    <header className='sticky left-0 top-0 z-50 max-h-[15vh] w-full bg-white px-5 md:px-[10vw]'>
      <nav className='mx-auto flex items-center justify-between border-gray-200 py-5'>
        <a href='/' className='flex items-center'>
          <img
            src='/Logo-goto.svg'
            className='h-10'
            height={40}
            width={86}
            alt='GoTou Logo'
            draggable={false}
          />
        </a>

        <div className='flex items-center gap-8 smMax:gap-4'>
          <ul className='hidden items-center justify-center gap-4 lg:flex lg:gap-8'>
            {MainLinks.map((link) => (
              <HeaderLink
                key={link.key}
                text={t(link.key)}
                onClick={() => handleNavigation(link.value)}
              />
            ))}
            {isLoggedIn && (
              <HeaderLink
                className='hidden font-medium xl:block'
                text={t('back_home')}
                onClick={() => navigate(backToAppPath)}
              />
            )}
          </ul>
          <div className='xs:hidden'>
            <LanguageDropDown />
          </div>
          <div className='hidden md:block '>
            <LoginButton />
          </div>
          <AboutPageHamburgerNavigation />
        </div>
      </nav>
    </header>
  );
};

export default AboutHeader;
