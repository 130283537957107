import { useState } from 'react';
import { motion } from 'framer-motion';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import Procedure from 'images/protseduryi.jpg';
import Procedure1 from 'images/protseduryi1.png';
import Procedure2 from 'images/protseduryi(2).jpg';
import Procedure3 from 'images/protseduryi(3).jpg';
import { Button } from 'ui';
import { useNavigate } from 'react-router';

const images = [Procedure, Procedure1, Procedure2, Procedure3];

const CarouselItem = ({
  index,
  serviceName,
}: {
  index: number;
  serviceName: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <motion.div
      key={index}
      className='flex  aspect-square max-h-[270px]  max-w-[235px] flex-col items-center justify-evenly rounded-[20px] bg-gray-gradient-1 pb-2 sm:max-h-[350px] sm:max-w-[328px]'
    >
      <img
        src={images[index]}
        draggable={false}
        alt='procedure'
        className='mx-auto mt-2 aspect-square w-4/6 select-none rounded-full object-cover'
      />
      <p className='mt-1 text-center text-lg' style={{ fontFamily: 'Inter' }}>
        {serviceName}
      </p>
      <Button
        variant='grayStroke'
        className='rounded-[20px] border-black disabled:!bg-transparent disabled:!opacity-40'
        disabled={index !== 0}
        onClick={() => navigate('/courses')}
      >
        {index === 0 ? t('more_details') : t('create_order.comming_soon')}
      </Button>
    </motion.div>
  );
};
const ServicesCarousel = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const serviceNames = t('service.names', { returnObjects: true }) as string[];

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    );
  };

  const nextIndex = (currentIndex + 1) % images.length;

  return (
    <div className='relative -mr-10 ml-auto max-h-[200px] rounded-[20px] bg-yellow-btn-hover p-5 sm:max-h-[250px]'>
      <motion.div
        className='relative mx-auto mr-10 flex max-w-2xl space-x-5 overflow-hidden rounded-lg'
        style={{ height: '400px' }}
        drag='x'
        dragConstraints={{ left: 5, right: 10 }}
        dragElastic={0.3}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);
          if (swipe < -swipeConfidenceThreshold) {
            handleNext();
          } else if (swipe > swipeConfidenceThreshold) {
            handlePrevious();
          }
        }}
      >
        <CarouselItem
          index={currentIndex}
          serviceName={serviceNames[currentIndex]}
        />
        <CarouselItem index={nextIndex} serviceName={serviceNames[nextIndex]} />
      </motion.div>
      <div className='absolute  z-20 -mt-10 flex gap-8 smMax:-bottom-36'>
        <FaChevronLeft
          size={45}
          className='cursor-pointer select-none rounded-full border border-secondary p-2 transition-all active:p-3'
          onClick={handlePrevious}
        />
        <FaChevronRight
          size={45}
          className='cursor-pointer select-none rounded-full border border-secondary p-2 transition-all active:p-3'
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default ServicesCarousel;
