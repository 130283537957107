import React, { useEffect } from 'react';
import CourseItem from 'components/About/CourseItem';
import BG from 'images/CoursesBG.jpg';
import { useTranslation } from 'react-i18next';

const paddingHorizontal = 'lg:px-[110px] md:px-[50px] px-[8%]' as const;

const CoursesPage = () => {
  const { t } = useTranslation();

  const courses = [
    {
      title: t('courses.java.title'),
      subtitle: t('courses.java.subtitle'),
      link: 'https://www.tetianapolhul.com/#program',
      prices: [
        {
          title: t('courses.java.prices.duration.title'),
          subtitle: t('courses.java.prices.duration.subtitle'),
        },
        {
          title: t('courses.java.prices.startDate.title'),
          subtitle: t('courses.java.prices.startDate.subtitle'),
        },
        {
          title: t('courses.java.prices.oneMonthPrice.title'),
          subtitle: t('courses.java.prices.oneMonthPrice.subtitle'),
        },
        {
          title: t('courses.java.prices.threeMonthsPrice.title'),
          subtitle: t('courses.java.prices.threeMonthsPrice.subtitle'),
        },
      ],
    },
    {
      title: t('courses.aiDataScience.title'),
      subtitle: t('courses.aiDataScience.subtitle'),
      link: 'https://innodevacademy.com/',
      prices: [
        {
          title: t('courses.aiDataScience.prices.hours.title'),
          subtitle: t('courses.aiDataScience.prices.hours.subtitle'),
        },
        {
          title: t('courses.aiDataScience.prices.applicationDeadline.title'),
          subtitle: t(
            'courses.aiDataScience.prices.applicationDeadline.subtitle',
          ),
        },
        {
          title: t('courses.aiDataScience.prices.startDate.title'),
          subtitle: t('courses.aiDataScience.prices.startDate.subtitle'),
        },
        {
          title: t('courses.aiDataScience.prices.endDate.title'),
          subtitle: t('courses.aiDataScience.prices.endDate.subtitle'),
        },
        {
          title: t('courses.aiDataScience.prices.fullPrice.title'),
          subtitle: t('courses.aiDataScience.prices.fullPrice.subtitle'),
        },
        {
          title: t('courses.aiDataScience.prices.byMay17.title'),
          subtitle: t('courses.aiDataScience.prices.byMay17.subtitle'),
        },
        {
          title: t('courses.aiDataScience.prices.fromMay17ToJuly17.title'),
          subtitle: t(
            'courses.aiDataScience.prices.fromMay17ToJuly17.subtitle',
          ),
        },
      ],
    },
    {
      title: t('courses.collegeBoundMentorship.title'),
      subtitle: t('courses.collegeBoundMentorship.subtitle'),
      link: 'https://techdevacademy.com/ementoring-program/',
      prices: [
        {
          title: t('courses.collegeBoundMentorship.prices.duration.title'),
          subtitle: t(
            'courses.collegeBoundMentorship.prices.duration.subtitle',
          ),
        },
        {
          title: t('courses.collegeBoundMentorship.prices.startDate.title'),
          subtitle: t(
            'courses.collegeBoundMentorship.prices.startDate.subtitle',
          ),
        },
        {
          title: t('courses.collegeBoundMentorship.prices.fullPrice.title'),
          subtitle: t(
            'courses.collegeBoundMentorship.prices.fullPrice.subtitle',
          ),
        },
      ],
    },
    {
      title: t('courses.dataScienceInternship.title'),
      subtitle: t('courses.dataScienceInternship.subtitle'),
      link: 'https://magnimindacademy.com/data-science-internship/',
      prices: [
        {
          title: t('courses.dataScienceInternship.prices.duration.title'),
          subtitle: t('courses.dataScienceInternship.prices.duration.subtitle'),
        },
        {
          title: t(
            'courses.dataScienceInternship.prices.applicationDeadline.title',
          ),
          subtitle: t(
            'courses.dataScienceInternship.prices.applicationDeadline.subtitle',
          ),
        },
        {
          title: t('courses.dataScienceInternship.prices.startDate.title'),
          subtitle: t(
            'courses.dataScienceInternship.prices.startDate.subtitle',
          ),
        },
        {
          title: t('courses.dataScienceInternship.prices.fullPrice.title'),
          subtitle: t(
            'courses.dataScienceInternship.prices.fullPrice.subtitle',
          ),
        },
        {
          title: t('courses.dataScienceInternship.prices.payUpfront.title'),
          subtitle: t(
            'courses.dataScienceInternship.prices.payUpfront.subtitle',
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h1
        className={`mb-8 text-4xl leading-10 lg:text-6xl lg:leading-[4.75rem] ${paddingHorizontal}`}
      >
        {t('courses.java.title1')}
        <br />
        <span className='text-accent'>{t('courses.java.title2')}</span>
      </h1>
      <img
        src={BG}
        alt='background'
        className='max-h-[450px] w-full object-cover'
      />
      <p className='mx-auto my-10  max-w-[650px] text-center text-lg leading-8 lg:mb-[130px] lg:mt-[100px] lg:text-3xl lg:leading-10'>
        {t('courses.subtitle')}
      </p>
      <div className={`${paddingHorizontal} flex flex-col gap-[120px]`}>
        {courses.map((course, i) => (
          <CourseItem
            key={i}
            index={i}
            subtitle={course.subtitle}
            link={course.link}
            title={course.title}
            prices={course.prices}
          />
        ))}
      </div>
    </div>
  );
};

export default CoursesPage;
