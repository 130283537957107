import React from 'react';
import SettingsItem from './SettingsItem';
import { useNavigate } from 'react-router-dom';

export type Settings = {
  id: number;
  name: string;
  link?: string;
  disabled?: boolean;
  onClick?: () => void;
};

type SettingsListProps = {
  settings: Settings[];
  title?: string;
  wrapperClassName?: string;
};

const SettingsList = ({
  title,
  settings,
  wrapperClassName,
}: SettingsListProps) => {
  const navigate = useNavigate();

  const handleClick = (
    disabled: boolean,
    link?: string,
    onClick?: () => void,
  ) => {
    if (disabled) return;
    if (link) {
      navigate(link);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <section className={wrapperClassName}>
      <h3 className='text-2xl font-medium'>{title}</h3>
      <ul className='mt-2 flex flex-col gap-1 '>
        {settings.map((setting, i) => (
          <SettingsItem
            key={i}
            settingName={setting.name}
            disabled={setting.disabled ? true : false}
            onClick={() =>
              handleClick(
                setting.disabled || false,
                setting.link,
                setting.onClick,
              )
            }
          />
        ))}
      </ul>
    </section>
  );
};

export default SettingsList;
