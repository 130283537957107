import React, { useState } from 'react';
import Map from 'components/common/Location/Map';
import { OrderWithCoordinates } from 'react-app-env';
import { ActionButtons, OrderTimeRange } from 'ui';
import { formatCurrency } from 'utils/validations';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

type OrderWithMapCardProps = {
  order: OrderWithCoordinates['orders'][number];
  isLoadedMap: boolean;
  url: string;
};

const OrderWithMapCard = ({
  order,
  isLoadedMap,
  url,
}: OrderWithMapCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMapVisible, setIsMapVisible] = useState(false);
  const formattedPrice = formatCurrency(order.currency, order.price);
  const locale = localStorage.getItem('selectedLanguage') || 'en';
  const toggleMapVisibility = () => setIsMapVisible(!isMapVisible);

  const handleNavigate = () => {
    navigate(url);
  };

  return (
    <div className='flex flex-col gap-2 rounded-lg border border-gray-3'>
      <div className='flex items-center justify-between px-3 pt-3'>
        <div className='flex flex-col '>
          <p className='font-medium'>{order.serviceName}</p>
          <OrderTimeRange timeOfOrder={order.timeOfOrder} dur={order.dur} />
        </div>
        <p>{formattedPrice}</p>
      </div>
      <ActionButtons
        handleSaveClick={toggleMapVisibility}
        handleCancelClick={handleNavigate}
        saveButtonText={
          isMapVisible ? t('my_orders.close_map') : t('my_orders.open_map')
        }
        cancelButtonText={t('my_orders.open_order')}
        wrapperClassName={`p-3 ${locale !== 'en' ? 'max-500:flex-col' : ''}`}
        buttonsClassName='!py-2'
      />

      <AnimatePresence>
        {isMapVisible && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Map
              isLoaded={isLoadedMap}
              center={{
                lat: order.latitude,
                lng: order.longitude,
              }}
              className='!h-[190px]'
              isDraggable
              canZoom
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default OrderWithMapCard;
