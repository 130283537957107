import React, { useState, useCallback, useRef } from 'react';
import Cropper from 'react-easy-crop';
import { FaRotateRight } from 'react-icons/fa6';
import { Area, Point } from 'react-easy-crop/types';
import { ActionButtons, SectionHeader } from 'ui';
import { useTranslation } from 'react-i18next';

const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

async function getCroppedImg(
  imageSrc: string,
  pixelCrop: { width: number; height: number; x: number; y: number },
  rotation = 0,
): Promise<string> {
  const image: HTMLImageElement = await createImage(imageSrc);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  image.width = image.width / 2;
  image.height = image.height / 2;

  const safeArea = Math.max(image.width, image.height) * 2;

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  if (!ctx) {
    console.error('Could not create canvas context');
    return '';
  }

  const getRadianAngle = (degreeValue: number) => {
    return (degreeValue * Math.PI) / 180;
  };
  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width, safeArea / 2 - image.height);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width - pixelCrop.x,
    0 - safeArea / 2 + image.height - pixelCrop.y,
  );
  return canvas.toDataURL('image/jpeg');
}

function dataURLToBlob(dataurl: string): Blob {
  let arr = dataurl.split(',');
  let mime = arr[0].match(/:(.*?);/)?.[1] || '';
  let bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

type CropImageProps = {
  image: string;
  handleCancelClick: () => void;
  handleSaveClick: (image: File) => void;
};

const CropImageSection = ({
  image,
  handleCancelClick,
  handleSaveClick,
}: CropImageProps) => {
  const { t } = useTranslation();
  const [isLoading, setisLoading] = useState(false);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [cropArea, setCropArea] = useState<Area | null>(null);
  const [demensionsCropArea, setDemensionsCropArea] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const cropperRef = useRef<Cropper>(null);

  const onCropChange = useCallback((crop: Point) => {
    setCrop(crop);
  }, []);

  const onZoomChange = useCallback((zoom: number) => {
    setZoom(zoom);
  }, []);

  const handleGetCroppedImage = async () => {
    if (cropArea) {
      setisLoading(true);
      const croppedImageDataUrl = await getCroppedImg(
        image,
        cropArea,
        rotation,
      );
      const croppedImageBlob = dataURLToBlob(croppedImageDataUrl);
      await handleSaveClick(
        new File([croppedImageBlob], image, {
          type: 'image/jpeg',
        }),
      );
      setisLoading(false);
    }
  };

  const handleCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCropArea(croppedAreaPixels);
  };

  return (
    <>
      <div className='flex flex-col gap-8'>
        <SectionHeader title={t('profile.edit.avatar')} />
        <div className='relative sm:h-[450px] smMax:h-[400px]'>
          <Cropper
            ref={cropperRef}
            style={{
              containerStyle: {
                width: '100%', // Use 100% of the container width
                height: '100%', // Use 100% of the container height
              },
              mediaStyle: {
                width: demensionsCropArea.width,
                height: demensionsCropArea.height,
              },
              cropAreaStyle: {
                borderRadius: '10px',
                border: '2px solid var(--accent-color)',
                color: 'rgba(0,0,0,0.1)',
              },
            }}
            onMediaLoaded={({ width, height }) => {
              setDemensionsCropArea({
                width,
                height,
              });
            }}
            image={image}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            onCropChange={onCropChange}
            onZoomChange={onZoomChange}
            onCropComplete={handleCropComplete}
            aspect={1}
          />
          <div className='absolute bottom-2 right-2 rounded-full bg-slate-50 p-1 shadow'>
            <FaRotateRight
              className='cursor-pointer text-xl'
              title='Rotate right'
              size={18}
              onClick={() =>
                setRotation((prevRotation) => (prevRotation + 90) % 360)
              }
            />
          </div>
        </div>
      </div>
      <ActionButtons
        handleCancelClick={handleCancelClick}
        handleSaveClick={handleGetCroppedImage}
        isLoading={isLoading}
      />
    </>
  );
};

export default CropImageSection;
