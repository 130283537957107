import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import categorySlice from 'redux/categorySlice';
import createOrderPageSlice from 'redux/createOrderPageSlice';
import modalSlice from 'redux/modalSlice';
import orderTimeSlice from 'redux/orderTimeSlice';
import serviceIdSlice from 'redux/serviceIdSlice';
import userSlice from 'redux/userSlice';
import specialistSlice from 'redux/specialistSlice';
import emailTypeSlice from 'redux/emailTypeSlice';
import storageSession from 'redux-persist/lib/storage/session';
import specialistServices from 'redux/specialistServices';
import scheduleSlice from 'redux/scheduleSlice';
import searchOrderSlice from 'redux/searchOrderSlice';
import mapSlice from 'redux/mapSlice';

const userPersistConfig = {
  key: 'user',
  storage: storage,
};

const specialistPersistConfig = {
  key: 'specialist',
  storage: storage,
};

const servicePersistConfig = {
  key: 'service',
  storage: storage,
};

const modalPersistConfig = {
  key: 'modal',
  storage: storageSession,
  whiteList: ['cookiePolicy'],
};

const specialistServicesPersistConfig = {
  key: 'specialistServices',
  storage: storageSession,
};

const specialistSchedulePersistConfig = {
  key: 'specialistSchedule',
  storage: storageSession,
};

const searchOrderPersistConfig = {
  key: 'specialistSchedule',
  storage: storageSession,
};

const categoryPersistConfig = {
  key: 'category',
  storage: storageSession,
};

const mapPersistConfig = {
  key: 'map',
  storage: storageSession,
};

const persistedUserReducer = persistReducer(specialistPersistConfig, userSlice);
const persistedSpecialistReducer = persistReducer(
  userPersistConfig,
  specialistSlice,
);
const persistedServiceReducer = persistReducer(
  servicePersistConfig,
  serviceIdSlice,
);
const persistedModalReducer = persistReducer(modalPersistConfig, modalSlice);
const persistedSpecialistServicesReducer = persistReducer(
  specialistServicesPersistConfig,
  specialistServices,
);
const persistedSpecialistScheduleReducer = persistReducer(
  specialistSchedulePersistConfig,
  scheduleSlice,
);
const searchOrderReducer = persistReducer(
  searchOrderPersistConfig,
  searchOrderSlice,
);

const categoryReducer = persistReducer(categoryPersistConfig, categorySlice);
const mapReducer = persistReducer(mapPersistConfig, mapSlice);

export const store = configureStore({
  reducer: {
    category: categoryReducer,
    modals: persistedModalReducer,
    orderTime: orderTimeSlice,
    service: persistedServiceReducer,
    user: persistedUserReducer,
    createOrderPage: createOrderPageSlice,
    emailType: emailTypeSlice,
    specialistServices: persistedSpecialistServicesReducer,
    specialistSchedule: persistedSpecialistScheduleReducer,
    searchOrder: searchOrderReducer,
    map: mapReducer,
    specialist: persistedSpecialistReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
