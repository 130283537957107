import React from 'react';
import PriceItem from './PriceItem';
import { Button } from 'ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setReferralCode, setUserRole } from 'redux/userSlice';
import { useAppSelector } from 'hooks/useAppSelector';

type CourseItemProps = {
  index: number;
  title: string;
  subtitle: string;
  link: string;
  prices?: {
    title: string;
    subtitle: string;
  }[];
  shouldShowSignUpButton?: boolean;
};

const CourseItem = ({
  prices,
  title,
  subtitle,
  link,
  index,
}: CourseItemProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAppSelector((state) => state.user);

  const handleLogin = () => {
    if (isLoggedIn) {
      dispatch(setReferralCode('PEE9QE'));
      navigate('/create-order');
    } else {
      dispatch(setUserRole('client'));
      navigate('/login?ref=PEE9QE');
    }
  };

  return (
    <article
      className={`flex justify-between gap-2 ${
        index !== 3 ? 'border-b' : ''
      } border-gray-5 pb-8 md:gap-10 smMax:flex-col smMax:gap-12`}
    >
      <div className='flex flex-[3] flex-col gap-6'>
        <p className='text-2xl leading-9  md:text-5xl md:leading-[4.5rem]'>
          {title}
        </p>
        <p className='max-w-md md:text-lg'>{subtitle}</p>
        <a
          href={link}
          className='max-w-[250px] cursor-pointer text-lg underline hover:no-underline'
        >
          {t('curriculum')}
        </a>
      </div>
      <div className='flex flex-[1] flex-col gap-6'>
        {prices?.map(({ subtitle, title }, index) => (
          <PriceItem key={index} title={title} subtitle={subtitle} />
        ))}
        <Button
          variant='whiteStroke'
          className=' !border-black py-3 uppercase hover:!border-accent md:py-4 lg:px-8'
          onClick={handleLogin}
        >
          {t('apply')}
        </Button>
      </div>
    </article>
  );
};

export default CourseItem;
