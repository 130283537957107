import React from 'react';
import toast from 'react-hot-toast';
import PersonIcon from 'images/emoji/Face.svg';
import { FaPlus } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';

type CustomerItemProps = {
  name: string;
  phone: string;
  clientIdStr: string;
  handleSelectClient: (
    name: string,
    phone: string,
    clientIdStr: string,
  ) => void;
};
const ContactItem = ({
  name,
  phone,
  clientIdStr,
  handleSelectClient,
}: CustomerItemProps) => {
  const { t } = useTranslation();
  return (
    <li
      className='flex items-center justify-between px-5 py-2 transition-all hover:bg-gray-100'
      key={phone}
      onClick={() => {
        handleSelectClient(name, phone, clientIdStr);
        toast.success(t('order.specialist_client_selected'));
      }}
    >
      <div className='flex items-center gap-3'>
        <img src={PersonIcon} alt='Person' width={18} height={18} />
        <div className='flex flex-col'>
          <p className='text-primary'>{name}</p>
          <p className='text-secondary'>{phone}</p>
        </div>
      </div>

      <FaPlus
        className='cursor-pointer text-secondary transition-all hover:text-accent'
        title='Select client'
      />
    </li>
  );
};

export default ContactItem;
