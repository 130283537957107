import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ModalState, toggleModal } from '../../redux/modalSlice';
import { useCallback } from 'react';

type ModalWrapperProps = {
  children: React.ReactNode;
  modalId: keyof ModalState;
  className?: string;
};

const ModalWrapper = ({ children, modalId, className }: ModalWrapperProps) => {
  const { role } = useAppSelector((state) => state.user);
  const isOpen = useAppSelector((state) => state.modals[modalId].active);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const modalStyles = `fixed inset-0 z-50  ${isOpen ? 'block' : 'hidden'}`;

  if (isOpen) {
    document.body.classList.add('overflow-hidden');
  }

  const handleCloseModal = useCallback(() => {
    dispatch(
      toggleModal({
        modalType: modalId,
      }),
    );
    document.body.classList.remove('overflow-hidden');

    if (modalId === 'cancelOrder') {
      if (role === 'client') {
        navigate('/orders');
      } else {
        navigate('/specialist/orders');
      }
    }
  }, [dispatch, modalId, navigate, role]);

  return (
    <div className={modalStyles}>
      <div className='flex min-h-screen items-center justify-center'>
        <div
          className='fixed inset-0 bg-black opacity-50'
          onClick={handleCloseModal}
        />
        <div
          className={`relative mx-auto flex max-h-[95vh] max-w-[90vw] flex-col items-center justify-center gap-3 overflow-y-auto rounded-3xl bg-white p-8 sm:min-w-[40vw] sm:max-w-[60vw] ${className}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalWrapper;
