import React, { useEffect, useState } from 'react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import Avatar from 'images/icons/no-avatar.svg';
import toast from 'react-hot-toast';
import { toastWithDismiss } from 'ui';
import { useTranslation } from 'react-i18next';
import AcceptToast from 'modals/AcceptToast';

type SpecialistAvatarProps = {
  src?: string;
  size?: number;
  handleLikeSpecialist?: () => void;
  isFavourite?: boolean;
  showLikeButton?: boolean;
  circleAvatar?: boolean;
};

const SpecialistAvatar = ({
  src,
  size,
  handleLikeSpecialist,
  isFavourite = false,
  showLikeButton = true,
  circleAvatar = false,
}: SpecialistAvatarProps) => {
  const { t: tr } = useTranslation();
  const [imageSrc, setImageSrc] = useState(src);
  const heartIconSize = size ? size / 4 : 23;

  const handleImageError = () => {
    setImageSrc(Avatar);
  };

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  const handleLikeClick = () => {
    toast.dismiss();
    if (isFavourite) {
      AcceptToast({
        handleSaveClick: () => {
          handleLikeSpecialist && handleLikeSpecialist();
          toast.dismiss();
        },
        saveButtonText: tr('client.remove_favorite.yes'),
        cancelButtonText: tr('client.remove_favorite.no'),
        text: tr('client.remove_favorite'),
        duration: 10000,
      });
    } else {
      handleLikeSpecialist && handleLikeSpecialist();
      toastWithDismiss(tr('client.added_favorite'), 'success');
    }
  };

  return (
    <div>
      <img
        src={imageSrc || Avatar}
        alt='specialist'
        loading='lazy'
        width={size || 80}
        height={size || 80}
        className={`aspect-square object-cover ${
          circleAvatar ? 'rounded-full' : 'rounded-[20px] bg-[#F5F5F5]'
        }`}
        onError={handleImageError}
      />
      {showLikeButton && (
        <div
          className={`relative bottom-5 mx-auto flex w-1/2 cursor-pointer items-center justify-center rounded-full bg-white px-1 py-2 shadow-lg transition-all hover:bottom-6`}
          onClick={handleLikeClick}
        >
          {!isFavourite ? (
            <FaRegHeart
              className='text-accent'
              title='Save specialist to favorites'
              size={heartIconSize}
            />
          ) : (
            <FaHeart
              className='text-accent'
              title='Remove specialist from favorites'
              size={heartIconSize}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SpecialistAvatar;
