import React from 'react';
import { Order, Service } from 'react-app-env';

import { AnimatePresence } from 'framer-motion';
import PriceSection from './PriceSection';
import DateSection from './DateSection';
import ServiceSection from './ServiceSection';
import ClientSection from './ClientSection';

type OrderServiceFormProps = {
  selectedDateTimestamp: number | null;
  chosenServices: Service[];
  isCustomerSelectionOpen: boolean;
  isDatePickerOpen: boolean;
  onOrderSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  toggleDatePicker: () => void;
  isCustomerSelectOpen?: boolean;
  toggleCustomerSelect?: () => void;
  allowedDays?: number[];
  timeSlots?: Date[];
  specialistOrders?: Order['orders'];
  services: Service[];
  handleChooseService: (service: Service) => void;
};

const OrderServiceForm = ({
  selectedDateTimestamp,

  chosenServices,
  isCustomerSelectionOpen,
  isDatePickerOpen,
  onOrderSubmit,
  toggleDatePicker,
  toggleCustomerSelect,
  isCustomerSelectOpen = true,
  allowedDays = [],
  specialistOrders = [],
  timeSlots,
  services,
  handleChooseService,
}: OrderServiceFormProps) => {
  return (
    <form onSubmit={onOrderSubmit} id='create-order'>
      <ul className='flex flex-col'>
        <AnimatePresence>
          {isCustomerSelectOpen && (
            <ClientSection toggleCustomerSelect={toggleCustomerSelect} />
          )}
        </AnimatePresence>
        {!isCustomerSelectionOpen && (
          <>
            <ServiceSection
              handleChooseService={handleChooseService}
              services={services}
              chosenServices={chosenServices}
            />
            <DateSection
              allowedDays={allowedDays}
              selectedDateTimestamp={selectedDateTimestamp}
              specialistOrders={specialistOrders}
              timeSlots={timeSlots || []}
              toggleDatePicker={toggleDatePicker}
              isDatePickerOpen={isDatePickerOpen}
            />
            <PriceSection />
          </>
        )}
      </ul>
    </form>
  );
};

export default OrderServiceForm;
