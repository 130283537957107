import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type Services = {
  categoryId: string;
  subcategoryId: string;
  serviceId: string;
};

const initialState: Services = {
  categoryId: '',
  subcategoryId: '',
  serviceId: '',
};

const serviceIdSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    clearAllServicesIDs: () => initialState,
    setCategoryId: (state, { payload }: PayloadAction<string>) => {
      state.categoryId = payload;
    },
    setSubcategoryId: (state, { payload }: PayloadAction<string>) => {
      state.subcategoryId = payload;
    },
    setServiceId: (state, { payload }: PayloadAction<string>) => {
      state.serviceId = payload;
    },
  },
});

export const {
  clearAllServicesIDs,
  setCategoryId,
  setSubcategoryId,
  setServiceId,
} = serviceIdSlice.actions;
export default serviceIdSlice.reducer;
