import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import { useLocation, useNavigate } from 'react-router';
import { ActionButtons, Input, SectionHeader, Wrapper } from 'ui';
import Logo from 'images/icons/logo.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { addCustomer } from 'redux/createOrderPageSlice';
import { addClient } from 'api/api';
import { useAppSelector } from 'hooks/useAppSelector';
import { validatePhoneNumber } from 'utils/validations';
import { useTranslation } from 'react-i18next';
import { PHONE_INPUT_COUNTRIES } from 'utils/constants';

const AddCustomerPage = () => {
  const { t } = useTranslation();
  const { state: userNameOrPhone } = useLocation();

  const { uid } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Check if userNameOrPhone is a phone number
  const isPhoneNumber = /^\+?[0-9]+$/.test(userNameOrPhone);

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(
    isPhoneNumber ? '' : userNameOrPhone ? userNameOrPhone : '',
  );
  const [surname, setSurname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(
    isPhoneNumber ? (userNameOrPhone ? userNameOrPhone : '') : '',
  );
  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.setSelectionRange(cursor, cursor);
  }, [ref, cursor, phoneNumber]);

  const handleChangePhoneNumber = (
    value: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCursor(event.target.selectionStart);
    setPhoneNumber(value);
  };

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Add a plus sign to phoneNumber if it doesn't already start with one
    const fullPhoneNumber = phoneNumber.startsWith('+')
      ? phoneNumber
      : `+${phoneNumber}`;

    if (!validatePhoneNumber(fullPhoneNumber)) {
      toast.error(t('valid_phone_number'));
      return;
    }
    setIsLoading(true);
    const response = await addClient(uid, {
      name,
      surname,
      phone: fullPhoneNumber,
    });
    setIsLoading(false);
    if (response.clientIdStr) {
      dispatch(
        addCustomer({
          name,
          surname,
          phoneNumber: fullPhoneNumber,
          clientId: response.clientIdStr,
        }),
      );
      navigate(-1);
    }
  };

  return (
    <Wrapper flexColumnSpaceBetween>
      <div>
        <SectionHeader title={t('add_your_customer')} />
        <img
          src={Logo}
          alt='logo'
          width={85}
          height={85}
          className='mx-auto my-4'
        />
        <form
          onSubmit={handleSubmitForm}
          className='mt-5  flex flex-col gap-5'
          id='create-customer'
        >
          <Input
            placeholder='Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Input
            placeholder='Surname'
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            required
          />
          <PhoneInput
            country={'ch'}
            onlyCountries={PHONE_INPUT_COUNTRIES}
            inputClass={`hover:border-accent focus:border-accent w-full text-sm`}
            value={phoneNumber}
            inputProps={{
              required: true,
              ref: ref,
            }}
            placeholder={t('edit_profile.phone_number')}
            onChange={(val, _, e) => handleChangePhoneNumber(val, e)}
          />
        </form>
      </div>
      <ActionButtons
        handleCancelClick={() => navigate(-1)}
        form='create-customer'
        isLoading={isLoading}
        wrapperClassName='mt-4'
      />
    </Wrapper>
  );
};

export default AddCustomerPage;
