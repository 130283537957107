import React, { useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useNavigate } from 'react-router';
import HeaderLink from './HeaderLink';
import useOnclickOutside from 'react-cool-onclickoutside';
import { LanguageDropDown } from 'ui';
import { CgMenuRightAlt } from 'react-icons/cg';
import { MainLinks } from './AboutHeader';
import { useTranslation } from 'react-i18next';

const AboutPageHamburgerNavigation = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { role, isLoggedIn } = useAppSelector((state) => state.user);
  const backToAppPath = role === 'client' ? '/category' : '/specialist/profile';

  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  const handleNavigation = (id: string) => {
    setIsOpen(false);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate('/#' + id, { replace: true });
    }
  };

  return (
    <div ref={ref}>
      <CgMenuRightAlt
        size={30}
        className='cursor-pointer rounded-md p-1 transition-all  hover:bg-accent-80 hover:text-white lg:hidden'
        onClick={() => setIsOpen(!isOpen)}
      />
      <div
        className={`transfrom absolute left-0 right-0 top-full bg-white transition-all duration-200 ease-in-out ${
          isOpen
            ? 'translate-y-0 opacity-100'
            : 'pointer-events-none -translate-y-10 opacity-0'
        } -z-20`}
      >
        <ul className='flex flex-col items-center justify-center'>
          {MainLinks.map((link) => (
            <HeaderLink
              key={link.key}
              text={t(link.key)}
              onClick={() => handleNavigation(link.value)}
            />
          ))}
          {isLoggedIn && (
            <HeaderLink
              text='Back to app'
              onClick={() => navigate(backToAppPath)}
            />
          )}
          <div className='block py-4 md:hidden'>
            <LanguageDropDown />
          </div>
        </ul>
      </div>
    </div>
  );
};

export default AboutPageHamburgerNavigation;
