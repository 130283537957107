import React from 'react';
import toast from 'react-hot-toast';
import { ActionButtons } from 'ui';

type AcceptToastProps = {
  handleSaveClick: () => void;
  text: string;
  saveButtonText: string;
  cancelButtonText: string;
  duration?: number;
};

const AcceptToast = ({
  handleSaveClick,
  text,
  saveButtonText,
  cancelButtonText,
  duration,
}: AcceptToastProps) => {
  return toast(
    (t) => (
      <div>
        <p className='mb-3 text-center'>{text}</p>
        <ActionButtons
          saveButtonText={saveButtonText}
          cancelButtonText={cancelButtonText}
          handleCancelClick={() => toast.dismiss(t.id)}
          handleSaveClick={handleSaveClick}
          buttonsClassName='!py-2'
        />
      </div>
    ),
    {
      duration: duration || 4000,
    },
  );
};

export default AcceptToast;
