import React from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { setOrderTime } from 'redux/orderTimeSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  Button,
  HeaderDatePicker,
  SectionHeader,
  TimeSlotList,
  Wrapper,
} from 'ui';
import { THIRTY_DAYS_IN_MS } from 'utils/time';
import { resetSearchOrder } from 'redux/searchOrderSlice';
import { useTranslation } from 'react-i18next';
import CalendarIcon from 'images/emoji/(Calendar).svg';
import MoneyIcon from 'images/emoji/(Money).svg';

const SchedulePage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dateStamp, formattedDate } = useAppSelector(
    (state) => state.orderTime,
  );

  const handleChangeDate = (date: Date) => {
    dispatch(setOrderTime(date.getTime()));
  };

  return (
    <Wrapper flexColumnSpaceBetween>
      <SectionHeader />
      <div className='flex flex-col items-center gap-2'>
        <h2 className='text-2xl font-medium'>
          {t('order.change_date.schedule_a_visit')}
        </h2>
        {!formattedDate && (
          <p className='text-xl text-secondary'>
            {t('order.change_date.enter_time')}
          </p>
        )}
      </div>
      <DatePicker
        inline
        selected={new Date(dateStamp)}
        onChange={handleChangeDate}
        dateFormat='MMMM d, yyyy h:mm aa'
        minDate={new Date()}
        maxDate={new Date(Date.now() + THIRTY_DAYS_IN_MS)}
        renderCustomHeader={HeaderDatePicker}
      />
      <div className='space-y-3'>
        <TimeSlotList />
      </div>
      <div className='my-10 flex flex-col gap-2'>
        {t('choose_time')
          .split('.')
          .map((sentence, index) => (
            <div key={index} className='flex gap-2'>
              <img src={index === 0 ? CalendarIcon : MoneyIcon} alt='emoji' />
              <p className='text-left text-lg'>{sentence}</p>
            </div>
          ))}
      </div>
      <Button
        variant='yellow'
        className='w-full py-4'
        onClick={() => {
          dispatch(resetSearchOrder());
          navigate('/search');
        }}
      >
        {t('order.change_date.find_service')}
      </Button>
    </Wrapper>
  );
};

export default SchedulePage;
