import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const halfHour = 30 * 60 * 1000;
const currentDate = new Date();

export type OrderTimeState = {
  dateStamp: number;
  formattedTime: string;
  formattedDate: string;
};

const initialState: OrderTimeState = {
  dateStamp: currentDate.getTime() + halfHour,
  formattedTime: '',
  formattedDate: '',
};

const orderTimeSlice = createSlice({
  name: 'orderTime',
  initialState,
  reducers: {
    clearOrderTime: () => initialState,
    setOrderTime: (state, { payload }: PayloadAction<number>) => {
      state.dateStamp = payload;
      state.formattedDate = new Date(payload).toLocaleDateString('de', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
    setOrderTimeDateStamp: (state, { payload }: PayloadAction<number>) => {
      state.dateStamp = payload;
      state.formattedTime = new Date(payload).toLocaleTimeString('de', {
        hour: '2-digit',
        minute: '2-digit',
      });
    },
  },
});

export const { setOrderTime, clearOrderTime, setOrderTimeDateStamp } =
  orderTimeSlice.actions;
export default orderTimeSlice.reducer;
