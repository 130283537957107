import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Button } from 'ui';
import 'react-phone-input-2/lib/bootstrap.css';
import { useTranslation } from 'react-i18next';
import { PHONE_INPUT_COUNTRIES } from 'utils/constants';

type PhoneInputFormProps = {
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  isValid: boolean;
  isLoading: boolean;
  handleSentCode: (e: React.FormEvent<HTMLFormElement>) => void;
};

const PhoneInputForm = ({
  phoneNumber,
  setPhoneNumber,
  isLoading,
  isValid,
  handleSentCode,
}: PhoneInputFormProps) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(phoneNumber);
  const handlePhoneChange = (value: string) => {
    setInputValue(value);
    setPhoneNumber(`+${value}`);
  };

  const [userCountry] = useState('ch');

  return (
    <>
      <form onSubmit={handleSentCode} className='mt-20' id='phoneForm'>
        <PhoneInput
          country={userCountry}
          onlyCountries={PHONE_INPUT_COUNTRIES}
          placeholder={t('edit_profile.phone_number')}
          inputClass={`hover:border-accent focus:border-accent ${
            !isValid && 'error'
          }`}
          value={inputValue}
          onEnterKeyPress={(e: any) => handleSentCode(e)}
          inputProps={{
            required: true,
            style: {
              fontSize: '24px',
            },
          }}
          onChange={handlePhoneChange}
        />
        {!isValid && <p className='text-error'>{t('valid_phone_number')}</p>}
      </form>
      <div>
        <p className='mt-[50%] text-center text-secondary'>
          {t('log_in.text_2')} <br />
          <a className='cursor-pointer underline' href='/privacy'>
            {t('log_in.terms')}
          </a>
        </p>
        <Button
          className='mt-6 w-full py-4'
          type='submit'
          form='phoneForm'
          variant='yellow'
          loading={isLoading}
        >
          {t('log_in.continue')}
        </Button>
      </div>
    </>
  );
};

export default PhoneInputForm;
