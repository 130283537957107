import React from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { Category } from 'react-app-env';
import { GoChevronLeft } from 'react-icons/go';
import { useLocation, useNavigate } from 'react-router';

type GoBackButtonProps = {
  className?: string;
};

const GoBackButton = ({ className }: GoBackButtonProps) => {
  const { serviceId, subcategoryId, categoryId } = useAppSelector(
    (state) => state.service,
  );
  const { categories, services, subcategories } = useAppSelector(
    (state) => state.category,
  );
  const { role } = useAppSelector((state) => state.user);
  const findNameById = (id: string, items: Category[]) => {
    const item = items.find((item) => item.stringId === id);
    return item ? item.name : '';
  };

  const categoryName = findNameById(categoryId, categories);
  const subcategoryName = findNameById(subcategoryId, subcategories);
  const serviceName = findNameById(serviceId, services);

  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    switch (true) {
      case pathname === '/specialist/services':
        return navigate('/specialist/profile');
      case pathname.startsWith('/category'):
        return navigate('/category');
      case pathname.startsWith('/services'):
        return navigate(`/category/${categoryId}`, { state: categoryName });
      case pathname.startsWith('/service'):
        return navigate(`/services/${subcategoryId}`, {
          state: subcategoryName,
        });
      case pathname === '/search':
        return navigate(`/service/${serviceId}`, { state: serviceName });
      case state?.from === '/search':
        return navigate('/category');
      case state?.from:
        return navigate(-2);
      case pathname.startsWith('/create-order'):
        return navigate('/favorites');
      case pathname.startsWith('/favorites'):
        return navigate('/category');
      case pathname.includes('/orders/') && role === 'client':
        return navigate('/orders');
      case pathname.startsWith('/orders'):
        return navigate('/category');
      default:
        navigate(-1);
    }
  };

  if (pathname === '/category' || pathname === '/specialist/profile') {
    return null;
  }

  return (
    <GoChevronLeft
      size={35}
      className={`cursor-pointer rounded-lg border border-[#DADADA] bg-transparent p-[2px] transition-all hover:p-0 ${className}`}
      onClick={handleGoBack}
      title='Go back'
    />
  );
};

export default GoBackButton;
