import React from 'react';
import { ModalWrapper } from 'ui';
import { withModalHandling } from './withModalHandling';
import { IoClose } from 'react-icons/io5';

type LeaveReviewModalProps = {
  children: React.ReactNode;
  handleCloseModal?: () => void;
};

const LeaveReviewModal = ({
  children,
  handleCloseModal,
}: LeaveReviewModalProps) => {
  return (
    <ModalWrapper modalId='clientLeaveReview'>
      <IoClose
        onClick={handleCloseModal}
        className='absolute right-4 top-4 cursor-pointer text-3xl'
      />
      {children}
    </ModalWrapper>
  );
};

export default withModalHandling(LeaveReviewModal, 'clientLeaveReview');
