import React from 'react';

type PriceItemProps = {
  title: string;
  subtitle: string;
};

const PriceItem = ({ title, subtitle }: PriceItemProps) => {
  return (
    <div className='flex flex-col items-start'>
      <p className='text-accent'>{title}</p>
      <p className='text-lg font-medium'>{subtitle}</p>
    </div>
  );
};

export default PriceItem;
