import { useState } from 'react';

const useCounter = (
  initialValue: number,
  incrementValue: number,
  maxValue: number,
  minValue: number = 0,
) => {
  const [value, setValue] = useState(initialValue);

  const increment = () =>
    setValue((prevValue) =>
      prevValue + incrementValue > maxValue
        ? maxValue
        : prevValue + incrementValue,
    );
  const decrement = () =>
    setValue((prevValue) =>
      prevValue - incrementValue < minValue
        ? minValue
        : prevValue - incrementValue,
    );

  return { value, setValue, increment, decrement };
};

export default useCounter;
