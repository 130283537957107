import React from 'react';
import { Variants, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const listOfCategories = (t: any) => {
  return t('categories', { returnObjects: true }) as string[];
};

const slideAnimation: Variants = {
  animate: {
    x: '-50%',
    transition: {
      duration: 28,
      ease: 'linear',
      repeat: Infinity,
    },
  },
};

const CategoriesList = () => {
  const { t } = useTranslation();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 665px)' });

  return isSmallScreen ? (
    <motion.div className='overflow-hidden whitespace-nowrap'>
      <motion.div
        className='flex w-[200%] space-x-16'
        variants={slideAnimation}
        animate='animate'
      >
        {Array(2)
          .fill(listOfCategories(t))
          .flat()
          .map((category, index) => (
            <div
              key={index}
              className='inline-block select-none whitespace-nowrap text-2xl text-[#3C3E38]'
            >
              {t(category)}
            </div>
          ))}
      </motion.div>
    </motion.div>
  ) : (
    <ul className='mt-10 flex w-full items-center justify-evenly gap-4 overflow-hidden px-5'>
      {listOfCategories(t).map((category, index) => (
        <li key={index} className='select-none whitespace-nowrap'>
          <p className='text-2xl text-[#3C3E38]'>{t(category)}</p>
        </li>
      ))}
    </ul>
  );
};

export default CategoriesList;
