import React, { useState } from 'react';
import Logo from 'images/icons/logo.svg';
import { withModalHandling } from './withModalHandling';
import { ActionButtons, Input, ModalWrapper } from 'ui';
import toast from 'react-hot-toast';
import { fetchRequestBoolean } from 'api/api';
import { useAppSelector } from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';

const SupportEmailModal = ({
  handleCloseModal,
}: {
  handleCloseModal: () => void;
}) => {
  const { t } = useTranslation();
  const { role, uid } = useAppSelector((state) => state.user);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let type = '';
    if (role === 'client') {
      type = 'CLIENT';
    } else {
      type = 'SPECIALIST';
    }

    let queryString = `comment=${encodeURIComponent(message)}&type=${type}${
      role === 'client' ? `&clientId=${uid}` : `&specialistId=${uid}`
    }`;
    setIsLoading(true);

    const response = await fetchRequestBoolean(
      `email-contact/support?${queryString}`,
      'POST',
    );
    setIsLoading(false);

    if (response) {
      toast.success(t('support.email_sent'));
    } else toast.error(t('support.email_error'));
    setMessage('');
    handleCloseModal();
  };

  return (
    <ModalWrapper modalId='supportEmail'>
      <img src={Logo} alt='Logo' width={42} height={42} />
      <h2 className=' text-center text-xl font-medium'>{t('email_us')}</h2>
      <form
        onSubmit={handleSubmit}
        id='emailUs'
        className='my-3 sm:w-[40vw] smMax:w-[80vw]'
      >
        <Input
          multiline
          required
          placeholder={t('support.your_message')}
          className='max-h-64 w-full'
          onChange={(e) => setMessage(e.target.value)}
        />
      </form>
      <ActionButtons
        form='emailUs'
        saveButtonText={t('support.send')}
        handleCancelClick={handleCloseModal}
        isLoading={isLoading}
        buttonsClassName='!py-2'
      />
    </ModalWrapper>
  );
};

export default withModalHandling(SupportEmailModal, 'supportEmail');
