import React from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { GoBackButton } from 'ui';

type SectionHeaderProps = {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  wrapperClassName?: string;
  titleClassName?: string;
  subtitleClassName?: string;
  showGoBackButton?: boolean;
  goBackButtonClassName?: string;
};

const SectionHeader = ({
  title,
  subtitle,
  wrapperClassName,
  titleClassName,
  subtitleClassName,
  goBackButtonClassName,
  showGoBackButton = true,
}: SectionHeaderProps) => {
  const { isNewUser } = useAppSelector((state) => state.user);
  return (
    <section
      className={`flex flex-row items-center gap-2 smMax:-ml-2 ${wrapperClassName}`}
    >
      {showGoBackButton && !isNewUser && (
        <GoBackButton className={goBackButtonClassName} />
      )}
      <div>
        {title &&
          (typeof title === 'string' ? (
            <h2 className={`${titleClassName || ''} text-2xl font-medium`}>
              {title}
            </h2>
          ) : (
            <div className={`${titleClassName || ''} text-2xl font-medium`}>
              {title}
            </div>
          ))}
        {subtitle &&
          (typeof subtitle === 'string' ? (
            <p className={`mt-1 text-secondary ${subtitleClassName || ''}`}>
              {subtitle}
            </p>
          ) : (
            <div className={`mt-1 text-secondary ${subtitleClassName || ''}`}>
              {subtitle}
            </div>
          ))}
      </div>
    </section>
  );
};

export default SectionHeader;
