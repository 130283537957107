import React from 'react';
import { useNavigate } from 'react-router';
import { useApiRequest } from 'hooks/useApiRequest';
import { useAppSelector } from 'hooks/useAppSelector';
import { Button, SectionHeader, Wrapper } from 'ui';
import { BASE_URL } from 'api/api';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setOrderTimeDateStamp } from 'redux/orderTimeSlice';
import { resetSearchOrder } from 'redux/searchOrderSlice';
import { useTranslation } from 'react-i18next';

type ServiceResponse = {
  catName: string;
  id: 0;
  imgURL: string;
  language: string;
  name: string;
  stringId: string;
  subcatName: string;
};

const ServicePage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedCategoryName, selectedServiceName, selectedSubcategoryName } =
    useAppSelector((state) => state.category);
  const { serviceId } = useAppSelector((state) => state.service);
  const { data: service } = useApiRequest<ServiceResponse>({
    endpoint: `${BASE_URL}services/{id}?serviceId=${serviceId}`,
  });

  const handleAsapClick = () => {
    dispatch(resetSearchOrder());
    const date = new Date();
    date.setMinutes(date.getMinutes() + 30);
    dispatch(setOrderTimeDateStamp(date.getTime()));
    navigate('/search', { state: service?.stringId });
  };

  return (
    <Wrapper flexColumnSpaceBetween noHorizontalPadding>
      <div>
        <SectionHeader
          title={selectedCategoryName}
          subtitle={`${selectedSubcategoryName}, ${selectedServiceName}`}
          wrapperClassName='px-5'
        />

        {service?.imgURL ? (
          <img
            src={service?.imgURL}
            alt='service'
            className='my-2 h-[250px] w-full bg-contain object-cover'
          />
        ) : (
          <div className='my-2 h-[300px] w-full animate-pulse rounded bg-secondary' />
        )}
      </div>
      {service?.name ? (
        <div className='flex flex-col gap-3 px-5'>
          <Button variant='yellow' className='py-4' onClick={handleAsapClick}>
            {t('order.asap')}
          </Button>
          <Button
            variant='yellowStroke'
            className='py-4'
            onClick={() => navigate('/schedule')}
          >
            {t('order.schedule')}
          </Button>
        </div>
      ) : (
        <div className='flex flex-col gap-3 px-5'>
          <Button children={'s'} className='py-4' skeleton={true} />
          <Button children={'s'} className='py-4' skeleton={true} />
        </div>
      )}
    </Wrapper>
  );
};

export default ServicePage;
