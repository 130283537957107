import React from 'react';
import { convertMsToTime } from 'utils/time';

type OrderTimeRangeProps = {
  timeOfOrder: number;
  dur: number;
  wrapperClassName?: string;
};

const OrderTimeRange = ({
  timeOfOrder,
  dur,
  wrapperClassName,
}: OrderTimeRangeProps) => {
  return (
    <span className={`!whitespace-nowrap text-secondary ${wrapperClassName}`}>
      {convertMsToTime(timeOfOrder)} - {convertMsToTime(timeOfOrder + dur)}
    </span>
  );
};

export default OrderTimeRange;
