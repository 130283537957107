import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ClientSubRole,
  Currency,
  Roles,
  SpecialistProfileDto,
  SpecSubRole,
} from 'react-app-env';
import { LatLng } from 'use-places-autocomplete';

export const SupportedLanguages = ['en', 'pl', 'de', 'ua'] as const;

type User = {
  phoneNumber: string;
  address: string;
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  ordersId: string[];
  since: string;
  isLoggedIn: boolean;
  uid: string;
  rank: number;
  reviewNumber: number;
  imagesAmount: number;
  coordinates: LatLng;
  timeZone: string | null;
  profilePhoto: string;
  role: Roles;
  clientSubRole: ClientSubRole;
  specSubRole: SpecSubRole;
  currency: Currency;
  isNewUser: boolean;
  referralCode: string | null;
  language: (typeof SupportedLanguages)[number];
};

type LoginPayload = {
  phoneNumber?: string;
  address?: string;
  firstName?: string;
  lastName?: string;
  since?: string;
  uid: string;
  rank?: number;
  role: 'client' | 'specialist';
  coordinates?: LatLng;
  profilePhoto?: string;
  city?: string;
  country?: string;
  isLogged?: boolean;
};

type UserLocationPayload = {
  address: string;
  coordinates: LatLng;
  city: string;
  country: string;
};

type UpdateSpecPayload = {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  country: string;
  currency: Currency;
  coordinates: LatLng;
};

type SpecialistPayload = {
  uid: string;
  phoneNumber: string;
  role: 'client' | 'specialist';
  reviewsCnt: number;
} & SpecialistProfileDto;

const initialState: User = {
  phoneNumber: '',
  address: '',
  firstName: '',
  lastName: '',
  country: '',
  city: '',
  ordersId: [],
  since: '',
  isLoggedIn: false,
  uid: '',
  rank: 0,
  reviewNumber: 0,
  imagesAmount: 0,
  profilePhoto: '',
  role: 'guest',
  specSubRole: 'acceptingTerms',
  clientSubRole: 'full',
  coordinates: { lat: -3.745, lng: -38.523 },
  currency: 'USD',
  isNewUser: false,
  referralCode: null,
  language: 'en',
  timeZone: null,
};

// TODO - make same state for client and specialist. Refactor actions
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoginUser: (state, { payload }: PayloadAction<LoginPayload>) => {
      if (payload.isLogged !== undefined) {
        state.isLoggedIn = payload.isLogged;
      } else {
        state.isLoggedIn = true;
      }
      state.address = payload.address ?? '';
      state.city = payload.city ?? '';
      state.coordinates = payload.coordinates ?? { lat: -3.745, lng: -38.523 };
      state.country = payload.country ?? '';
      state.firstName = payload.firstName ?? '';
      state.lastName = payload.lastName ?? '';
      state.phoneNumber = payload.phoneNumber ?? '';
      state.profilePhoto = payload.profilePhoto ?? '';
      state.rank = payload.rank ?? 0;
      state.role = payload.role;
      state.since = payload.since ?? '';
      state.uid = payload.uid;
    },
    setUserLocation: (
      state,
      { payload }: PayloadAction<UserLocationPayload>,
    ) => {
      state.address = payload.address;
      state.coordinates = payload.coordinates;
      state.city = payload.city;
      state.country = payload.country;
    },
    setSpecialist: (state, { payload }: PayloadAction<SpecialistPayload>) => {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.address = payload.address;
      state.phoneNumber = payload.phoneNumber;
      state.uid = payload.uid;
      state.role = payload.role;
      state.isLoggedIn = true;
      state.since = payload.since.toString();
      state.coordinates = { lat: -3.745, lng: -38.523 };
      state.profilePhoto = payload.profilePhoto;
      state.imagesAmount = payload.picsCnt;
      state.rank = payload.rating;
      state.reviewNumber = payload.reviewsCnt;
    },
    setUserAvatar: (state, { payload }: PayloadAction<string>) => {
      state.profilePhoto = payload;
    },
    updateSpecialistState: (
      state,
      { payload }: PayloadAction<UpdateSpecPayload>,
    ) => {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.address = payload.address;
      state.city = payload.city;
      state.country = payload.country;
      state.currency = payload.currency;
      state.coordinates = payload.coordinates;
    },
    setImageAmount: (state, { payload }: PayloadAction<number>) => {
      state.imagesAmount = payload;
    },
    setUserRole: (
      state,
      { payload }: PayloadAction<'client' | 'specialist' | 'guest'>,
    ) => {
      state.role = payload;
    },
    setUserCurrency: (state, { payload }: PayloadAction<Currency>) => {
      state.currency = payload;
    },
    setIsNewUser: (state, { payload }: PayloadAction<boolean>) => {
      state.isNewUser = payload;
    },
    setReferralCode: (state, { payload }: PayloadAction<string | null>) => {
      state.referralCode = payload;
    },
    setClientSubRole: (state, { payload }: PayloadAction<ClientSubRole>) => {
      state.clientSubRole = payload;
    },
    setSpecSubRole: (state, { payload }: PayloadAction<SpecSubRole>) => {
      state.specSubRole = payload;
    },
    setUserLanguage: (
      state,
      { payload }: PayloadAction<(typeof SupportedLanguages)[number]>,
    ) => {
      state.language = payload;
    },

    setUserTimeZone: (state, { payload }: PayloadAction<string>) => {
      state.timeZone = payload;
    },
    logoutUser: () => initialState,
  },
});

export const {
  setLoginUser,
  setSpecialist,
  updateSpecialistState,
  logoutUser,
  setUserLocation,
  setUserAvatar,
  setImageAmount,
  setUserRole,
  setIsNewUser,
  setReferralCode,
  setUserLanguage,
  setUserTimeZone,
  setSpecSubRole,
  setClientSubRole,
} = userSlice.actions;

export default userSlice.reducer;
