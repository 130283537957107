import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, SectionHeader, TransactionItem, Wrapper } from 'ui';
import MoneyIcon from 'images/emoji/(Money).svg';
import CharityIcon from 'images/emoji/Charity.svg';
import Logo from 'images/icons/logo-sad.svg';

const BalancePage = () => {
  const { t } = useTranslation();
  return (
    <Wrapper className='!p-0'>
      <SectionHeader wrapperClassName='absolute top-7 left-7 text-white' />
      <section className='flex flex-col items-center justify-center bg-[#FFD84E] pb-16 pt-10 text-white '>
        <h2 className='text-5xl font-medium leading-[58px]'>
          0<span className=' text-3xl'>.00 $</span>
        </h2>
        <h4>0.00 $</h4>
      </section>
      <div className='-mt-7 flex h-full flex-col justify-between rounded-t-[32px] bg-white px-4 pt-9'>
        {false ? (
          <div>
            <p className='mb-2 text-center text-secondary'>Today</p>
            <TransactionItem />
          </div>
        ) : (
          <img
            src={Logo}
            alt=''
            width={50}
            height={50}
            className='mx-auto mt-10'
          />
        )}
        <div className='absolute bottom-5 left-0 mx-auto flex w-full flex-col gap-3'>
          <div className='mx-auto flex w-[95%] gap-4'>
            <Button
              variant='yellowStroke'
              className='inline-flex w-full items-center justify-center gap-1 py-3'
            >
              <img src={MoneyIcon} alt='' width={16} height={16} />
              Archive
            </Button>
            <Button
              variant='yellowStroke'
              className='inline-flex w-full items-center justify-center gap-1 py-3'
            >
              <img src={CharityIcon} alt='' width={16} height={16} />
              Charity
            </Button>
          </div>
          <div className='mx-auto w-[95%]'>
            <Button variant='yellow' className='w-full py-4'>
              {t('Withdraw funds')}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default BalancePage;
