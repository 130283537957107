import errorToast from 'modals/ErrorToast';
import { useEffect, useState, useCallback } from 'react';
import { getHeaders, handleTokenRefresh } from 'api/api';
import { useAppDispatch } from './useAppDispatch';
import { logOut } from 'api/login';
import { useNavigate } from 'react-router';
import { useAppSelector } from './useAppSelector';
import { useTranslation } from 'react-i18next';

type Error = {
  message: string;
  name: string;
  status: number;
};

type UseApiRequestOptions = {
  endpoint: string | null;
  destructuringDataType?: string;
  requestMethod?: 'GET' | 'POST';
  requestBody?: any;
  deps?: any;
  requireAccessToken?: boolean;
};

export const useApiRequest = <T = any>({
  endpoint,
  destructuringDataType,
  requestBody,
  deps,
  requestMethod = 'GET',
  requireAccessToken = true,
}: UseApiRequestOptions): {
  loading: boolean;
  data: T | null;
  error: any;
} => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const { uid, role } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const fetchData = useCallback(
    async (retryCount = 0): Promise<void> => {
      try {
        if (!endpoint) {
          setLoading(false);
          return setData(null);
        }

        const requestOptions: RequestInit = {
          headers: getHeaders(),
          method: requestMethod,
        };

        if (requestBody) {
          requestOptions.body = JSON.stringify(requestBody);
        }

        setLoading(true);
        const response = await fetch(endpoint, requestOptions);

        if (
          response.status === 401 &&
          requireAccessToken &&
          localStorage.getItem('gotouAccessToken')
        ) {
          if (retryCount >= 2) {
            if (role !== 'guest') await logOut(uid, role, dispatch, navigate);
            return;
          }

          await handleTokenRefresh();
          return fetchData(retryCount + 1);
        }

        if (response.status === 910 && endpoint.includes('getTrialEndDate')) {
          setError({
            message: 'Trial has ended',
            name: 'Trial has ended',
            status: 910,
          });
          return;
        }

        if (response.status === 909 && endpoint.includes('getTrialEndDate')) {
          setError({
            message: 'Trial has not started yet',
            name: 'Trial has not started yet',
            status: 909,
          });
          return;
        }

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        if (destructuringDataType) {
          setData(result[destructuringDataType]);
        } else {
          setData(result);
        }
      } catch (error: any) {
        let errorMessage = t('something_wrong');
        switch (error.status) {
          case 401:
            errorMessage = t('unauthorized_access');
            break;
          case 404:
            errorMessage = t('resource_not_found');
            break;
          case 500:
            errorMessage = t('internal_server_error');
            break;
          case 910:
            errorMessage = t('trial_ended');
            break;
          case 909:
            errorMessage = t('trial_not_started');
            break;
          default:
            // Keep the default error message
            break;
        }

        setError({
          message: error.message,
          name: error.name,
          status: error.status,
        });

        errorToast({ message: errorMessage });
      } finally {
        setLoading(false);
      }
    },
    [
      endpoint,
      destructuringDataType,
      requestMethod,
      requestBody,
      requireAccessToken,
      role,
      uid,
      dispatch,
      navigate,
      t,
    ],
  );

  useEffect(() => {
    if (endpoint) {
      fetchData();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps, endpoint]);

  return { loading, data, error };
};
