import React, { useEffect, useState } from 'react';
import { useApiRequest } from 'hooks/useApiRequest';
import { useAppSelector } from 'hooks/useAppSelector';
import useCounter from 'hooks/useCounter';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  BASE_URL,
  fetchRequestBoolean,
  updateSpecialistService,
} from 'api/api';
import { convertHoursToMs, convertMinToMs, convertMsToMin } from 'utils/time';
import {
  ActionButtons,
  Autocomplete,
  CounterInput,
  Radio,
  SectionHeader,
  toastWithDismiss,
  Wrapper,
} from 'ui';
import MoneyIcon from 'images/emoji/Cash.svg';
import ComputerIcon from 'images/emoji/desktop_computer.png';
import CoupleIcon from 'images/emoji/couple.png';

import CalendarIcon from 'images/emoji/Hours.svg';
import toast from 'react-hot-toast';
import { Currency, Service } from 'react-app-env';
import { useTranslation } from 'react-i18next';

const MAX_PRICE = 5000;
const MIN_PRICE = 0;

const AddServicePage = () => {
  const { t } = useTranslation();

  const { services: specServices } = useAppSelector(
    (state) => state.specialistServices,
  );
  const { uid, currency } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { state: routeState } = useLocation();
  const isStateString = typeof routeState === 'string';
  const { id } = useParams();
  const priceCounter = useCounter(
    isStateString ? 20 : routeState?.price,
    10,
    MAX_PRICE,
    MIN_PRICE,
  );
  const { data: fetchedServices } = useApiRequest<Service[]>({
    endpoint: `${BASE_URL}services?subcategoryId=${id}`,
    destructuringDataType: 'services',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [serviceName, setServiceName] = useState(
    isStateString ? '' : routeState?.serviceName,
  );
  const [hours, setHours] = useState(
    isStateString ? 0 : Math.floor(convertMsToMin(routeState?.duration) / 60),
  );
  const [minutes, setMinutes] = useState(
    isStateString ? 0 : convertMsToMin(routeState?.duration) % 60,
  );
  const [currencyInput, setCurrencyInput] = useState<Currency>(
    isStateString || !routeState?.currency ? currency : routeState?.currency,
  );
  const [isGroup, setIsGroup] = useState(
    isStateString ? false : !!routeState?.isGroup,
  );
  const [isOnline, setIsOnline] = useState(
    isStateString ? false : !!routeState?.isOnline,
  );

  const serviceOptions = fetchedServices
    ?.filter((service) =>
      specServices.some((specService) => specService.name === service.name),
    )
    .map((service) => service.name);

  const handleCreateService = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    if (!serviceName) {
      toastWithDismiss(t('enter_service_name'), 'error');
      return;
    }

    if (
      specServices.some(
        (service) =>
          service.name === serviceName &&
          service.stringId !== routeState?.gserviceIdStr,
      )
    ) {
      toastWithDismiss(t('service_exists'), 'error');
      return;
    }

    if (serviceName.length > 60) {
      toastWithDismiss(t('name_limit'), 'error');
      return;
    }
    if (minutes === 0 && hours === 0) {
      toastWithDismiss(t('enter_duration'), 'error');
      return;
    }

    if (priceCounter.value < MIN_PRICE) {
      toastWithDismiss(t('enter_valid_price'), 'error');
      return;
    }

    let detectedLanguage = '';
    setIsLoading(true);

    try {
      const response = await fetch('https://api-free.deepl.com/v2/translate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          auth_key: process.env.REACT_APP_DEEPL_API_KEY || '',
          text: serviceName,
          target_lang: 'EN',
        }),
      });

      const data = await response.json();

      detectedLanguage = data.translations[0].detected_source_language || '';
    } finally {
    }

    const serviceData = {
      name: serviceName,
      price: priceCounter.value,
      duration: convertHoursToMs(hours) + convertMinToMs(minutes),
      currency: currencyInput,
      language: detectedLanguage,
      subcategoryId: id!,
      isOnline,
      isGroup,
    };

    if (!isStateString) {
      const response = await updateSpecialistService(
        uid,
        routeState?.gserviceIdStr,
        {
          ...serviceData,
          serviceId: routeState?.gserviceIdStr,
        },
      );
      setIsLoading(false);

      if (response) {
        toast.dismiss();
        toastWithDismiss(t('service_updated_successfully'), 'success');
      } else {
        toastWithDismiss(t('something_wrong'), 'error');
      }
      return;
    }

    if (id) {
      const response = await fetchRequestBoolean('services', 'POST', {
        ...serviceData,
        specialistId: uid,
      });
      setIsLoading(false);
      if (response) {
        toast.success(t('service_created'));
        navigate(`/specialist/services`);
      }
    }
  };

  const handleChangeInput =
    (
      counter: { setValue: (value: number) => void },
      min: number,
      max: number,
    ) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = +e.target.value;
      if (value >= min && value <= max) {
        counter.setValue(value);
      }
    };

  useEffect(() => {
    if (!routeState) {
      navigate('/specialist/services');
    }
  }, [routeState, navigate]);

  return (
    <Wrapper flexColumnSpaceBetween>
      <form
        id='createService'
        className='mb-5 flex flex-col gap-8'
        onSubmit={handleCreateService}
      >
        <SectionHeader
          title={isStateString ? routeState : routeState?.serviceName}
          subtitle={isStateString ? t('create_service') : t('edit_service')}
        />
        <Autocomplete
          options={serviceOptions || []}
          inputValue={serviceName}
          setInputValue={setServiceName}
          placeholder={t('enter_service_name')}
        />
        <div className='flex justify-between border-b border-gray-10'>
          <div className='flex items-start justify-start gap-2'>
            <img
              src={ComputerIcon}
              alt='icon'
              width={24}
              height={24}
              className='xs:hidden'
            />
            <p>{t('add_service_page.service_provided')}</p>
          </div>
          <div className='flex flex-col gap-3'>
            <Radio
              required
              name='Online'
              children={t('specialist.service_online')}
              checked={isOnline}
              onChange={() => setIsOnline(!isOnline)}
            />
            <Radio
              required
              name='Online'
              children={t('specialist.service_offline')}
              checked={!isOnline}
              onChange={() => setIsOnline(!isOnline)}
            />
          </div>
        </div>
        <div className='flex justify-between border-b border-gray-10'>
          <div className='flex items-start justify-start gap-2 '>
            <img
              src={CoupleIcon}
              alt='icon'
              width={24}
              height={24}
              className='xs:hidden'
            />
            <p>{t('add_service_page.service_type')}</p>
          </div>
          <div className='flex flex-col gap-3'>
            <Radio
              required
              name='Group'
              children={t('specialist.service_group')}
              checked={isGroup}
              onChange={() => setIsGroup(!isGroup)}
            />
            <Radio
              required
              name='Group'
              children={t('specialist.service_individual')}
              checked={!isGroup}
              onChange={() => setIsGroup(!isGroup)}
            />
          </div>
        </div>
        <CounterInput
          counter={priceCounter}
          label={`${t('add_service_page.price')}, ${currencyInput}`}
          onChange={handleChangeInput(priceCounter, MIN_PRICE, MAX_PRICE)}
          icon={MoneyIcon}
        />
        <div className='flex items-start justify-between'>
          <div className='flex gap-2'>
            <img
              src={CalendarIcon}
              alt='icon'
              width={24}
              height={24}
              className='xs:hidden'
            />
            <p>{t('add_service_page.duration')}</p>
          </div>
          <div className='flex flex-col gap-4'>
            <div className='flex gap-5'>
              <div
                className='relative
  my-2 rounded-lg border border-gray-3 px-4 py-2 transition-all hover:border-accent'
              >
                <label
                  htmlFor='hours'
                  className='absolute -top-2 left-1 bg-white text-xs text-gray-2'
                >
                  {t('add_service_page.hours')}
                </label>
                <select
                  name='hours'
                  id='hours'
                  className='bg-white outline-none'
                  value={hours}
                  onChange={(e) => setHours(Number(e.target.value))}
                  required
                >
                  {Array.from({ length: 12 }, (_, index) => (
                    <option key={index} value={index}>
                      {index}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className='relative
  my-2 rounded-lg border border-gray-3 px-4 py-2 transition-all hover:border-accent'
              >
                <label
                  htmlFor='minutes'
                  className='absolute -top-2 left-1 bg-white text-xs text-gray-2'
                >
                  {t('add_service_page.minutes')}
                </label>
                <select
                  name='minutes'
                  id='minutes'
                  className=' bg-white outline-none'
                  value={minutes}
                  onChange={(e) => setMinutes(Number(e.target.value))}
                  required
                >
                  {Array.from({ length: 13 }, (_, index) => {
                    const value = index * 5;
                    return (
                      <option key={index} value={value}>
                        {value.toString().padStart(2, '0')}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className='flex items-center justify-end'>
              <div className='relative mt-4 w-44 rounded-lg border border-gray-3 p-2 transition-all hover:border-accent'>
                <label className='absolute -top-2 bg-white text-xs text-gray-2'>
                  {t('add_service_page.currency')}
                </label>
                <select
                  name='currency'
                  id='currency'
                  className='w-40 cursor-pointer bg-white outline-none'
                  value={currencyInput}
                  onChange={(e) => setCurrencyInput(e.target.value as Currency)}
                >
                  <option value='USD'>USD $</option>
                  <option value='EUR'>EUR €</option>
                  <option value='UAH'>UAH ₴</option>
                  <option value='CHF'>CHF ₣</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ActionButtons
        handleCancelClick={() => navigate(-1)}
        form='createService'
        isLoading={isLoading}
      />
    </Wrapper>
  );
};

export default AddServicePage;
