import React from 'react';
import { Order } from 'react-app-env';
import { TimeSpecificOrderButton } from 'ui';

type CalendarItemProps = {
  date: Date;
  orders?: Order['orders'];
  hideDate?: boolean;
};

const CalendarItem = ({ date, orders, hideDate }: CalendarItemProps) => {
  const isToday = date.toDateString() === new Date().toDateString();

  // Retrieve the locale from localStorage
  const locale = localStorage.getItem('selectedLanguage') || 'en';

  // Format the date according to the locale
  const formattedDay = new Intl.DateTimeFormat(locale, {
    month: 'long',
  }).format(date);

  return (
    <div className='flex gap-3'>
      {!hideDate && (
        <div className='flex flex-col items-center gap-2 text-sm font-medium'>
          <p className={`${isToday ? 'text-accent' : 'text-secondary'}`}>
            {formattedDay}
          </p>
          <p
            className={`-mt-2 inline-flex h-[32px] w-[32px] items-center justify-center rounded-full  ${
              isToday ? 'bg-accent text-white' : 'text-primary'
            } `}
          >
            {date.getDate()}
          </p>
        </div>
      )}

      <div className='flex flex-1 flex-col gap-2 border-b border-gray-200'>
        {orders &&
          orders.length > 0 &&
          orders.map((order) => (
            <TimeSpecificOrderButton
              key={order.idStr}
              url={`/specialist/orders/${order.idStr}`}
              order={order}
            />
          ))}
      </div>
    </div>
  );
};

export default CalendarItem;
