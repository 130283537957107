import React, { useState } from 'react';
import DayTimeSelector from '../atoms/DayTimeSelector';
import { useTranslation } from 'react-i18next';

type CustomTimeSlotsListProps = {
  timeSlots: Date[];
  timeStamp: number | null;
  handleSelectTime: (time: Date) => void;
};

const timeRanges = (t: any) => [
  { start: 6, end: 10, label: t('morning') },
  { start: 10.5, end: 16, label: t('afternoon') },
  { start: 16.5, end: 21, label: t('evening') },
  { start: 22, end: 5.5, label: t('night') },
];

const CustomTimeSlotsList = ({
  handleSelectTime,
  timeSlots,
  timeStamp,
}: CustomTimeSlotsListProps) => {
  const { t } = useTranslation();

  const [selectedRange, setSelectedRange] = useState(0);

  const filteredTimeRanges = timeRanges(t).filter((range) => {
    return timeSlots.some((time) => {
      const hours = time.getHours() + time.getMinutes() / 60;
      if (range.start < range.end) {
        return hours >= range.start && hours < range.end;
      } else {
        return hours >= range.start || hours < range.end;
      }
    });
  });

  const filteredTimeSlots = timeSlots.filter((time) => {
    const hours = time.getHours() + time.getMinutes() / 60;
    const range = filteredTimeRanges[selectedRange];
    if (range) {
      if (range.start < range.end) {
        return hours >= range.start && hours < range.end;
      } else {
        return hours >= range.start || hours < range.end;
      }
    }
    return false;
  });

  // console.log(timeSlots.map);

  if (timeSlots.length === 0) {
    return (
      <div>
        <p className=' text-center text-lg text-secondary'>
          {t('no_timeslots')}
        </p>
      </div>
    );
  }

  return (
    <>
      <DayTimeSelector
        timeRanges={timeRanges(t)}
        selectedIndex={selectedRange}
        setDayTime={setSelectedRange}
      />
      <div className='horizontal-scrollbar mt-2 flex w-full gap-4 overflow-x-auto whitespace-nowrap'>
        {filteredTimeSlots.map((timeSlot, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                handleSelectTime(timeSlot);
              }}
              className={`inline-block cursor-pointer rounded-[28px] border border-accent px-4 py-2 ${
                timeStamp === timeSlot.getTime() ? 'bg-accent text-white' : ''
              }`}
            >
              {timeSlot.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CustomTimeSlotsList;
