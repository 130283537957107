import React from 'react';

type HeaderLinkProps = {
  text: string;
  onClick: () => void;
  className?: string;
};

const HeaderLink = ({ text, onClick, className }: HeaderLinkProps) => {
  return (
    <li
      className={`cursor-pointer py-4 text-sm uppercase leading-5 tracking-wide  transition-all hover:text-accent active:text-accent lg:text-lg ${className}`}
      onClick={onClick}
      aria-label={text}
    >
      {text}
    </li>
  );
};

export default HeaderLink;
