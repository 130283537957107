import { Outlet } from 'react-router-dom';
import AboutFooter from './AboutFooter';
import InvestorModalForm from 'modals/InvestorModalForm';
import AboutHeader from './AboutHeader';
import './index.css';

const AboutLayout = () => {
  return (
    <div className='about-font'>
      <AboutHeader />
      <Outlet />
      <AboutFooter />
      <InvestorModalForm />
    </div>
  );
};

export default AboutLayout;
