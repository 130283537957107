import React from 'react';

type OrderServiceLabelProps = {
  icon: string;
  text: string;
};

const OrderServiceLabel = ({ icon, text }: OrderServiceLabelProps) => {
  return (
    <div className='flex items-center'>
      <img
        src={icon}
        alt='calendar'
        className='mr-1 xs:hidden'
        width={20}
        height={20}
      />

      <p className='text-sm text-secondary'>{text}</p>
    </div>
  );
};

export default OrderServiceLabel;
