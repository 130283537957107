import React from 'react';
import toast from 'react-hot-toast';
import LogoSad from 'images/icons/logo-sad.svg';

const SpecialistOccupiedToast = (t: any) => {
  return toast.custom(
    (tt) => (
      <div
        className={`${
          tt.visible ? 'animate-enter' : 'animate-leave'
        } pointer-events-auto flex w-full max-w-md flex-col rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
      >
        <div className='space-y-3 p-4'>
          <img
            src={LogoSad}
            alt='logo-sad'
            width={30}
            height={30}
            className='mx-auto'
          />
          <div className='mt-2 space-y-1'>
            <p className=' text-center text-sm text-gray-4'>
              {t('specialist_701')}
            </p>
            <p className='text-center text-sm text-secondary'>
              {t('specialist_701_description')}
            </p>
          </div>
        </div>
        <div className='flex border-t border-gray-200'>
          <button
            onClick={() => toast.dismiss(tt.id)}
            className='flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-accent-80 hover:text-accent focus:outline-none focus:ring-2 focus:ring-accent'
          >
            {t('ok')}
          </button>
        </div>
      </div>
    ),
    {
      duration: 10000,
    },
  );
};

export default SpecialistOccupiedToast;
