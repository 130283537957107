import React from 'react';
import toast, { Toast } from 'react-hot-toast';
import LogoSad from 'images/icons/logo-sad.svg';
import { Button } from 'ui';

type ErrorToastProps = {
  message: string;
};

const errorToast = ({ message }: ErrorToastProps) => {
  toast.dismiss();
  const msg = (t: Toast) => (
    <div onClick={() => toast.dismiss(t.id)}>
      <img
        src={LogoSad}
        alt='Logo'
        width={30}
        height={30}
        className='mx-auto'
      />
      <h2 className='mt-3 text-center text-lg'>{message}</h2>
      <Button
        variant='yellow'
        onClick={() => toast.dismiss(t.id)}
        className='w-full max-w-xs uppercase'
      >
        OK
      </Button>
    </div>
  );

  return toast((t) => msg(t), {
    duration: Infinity,
  });
};

export default errorToast;
