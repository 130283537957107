import React, { useState } from 'react';
import SectionLabel from './SectionLabel';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useAppSelector';
import { convertMsToTime } from 'utils/time';
import ReactDatePicker from 'react-datepicker';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { useAppDispatch } from 'hooks/useAppDispatch';
import CalendarIcon from 'images/emoji/Calendar days.svg';
import {
  setDateStamp,
  setIsTimeSelected,
  setMonth,
} from 'redux/createOrderPageSlice';
import {
  CustomTimeSlotsList,
  HeaderDatePicker,
  TimeSpecificOrderButton,
} from 'ui';
import { Order } from 'react-app-env';

type DateSectionProps = {
  timeSlots: Date[];
  selectedDateTimestamp: number | null;
  allowedDays: number[];
  specialistOrders: Order['orders'];
  toggleDatePicker: () => void;
  isDatePickerOpen: boolean;
};

const variants: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
    type: 'tween',
  },
  closed: { opacity: 0, height: 0, type: 'tween' },
};

const now = new Date();
const nextMonth = new Date(now.getFullYear(), now.getMonth() + 2, 0); // Months are 0-indexed

const DateSection = ({
  allowedDays,
  toggleDatePicker,
  timeSlots,
  specialistOrders,
  selectedDateTimestamp,
  isDatePickerOpen,
}: DateSectionProps) => {
  const { t } = useTranslation();

  const { chosenServices, isTimeSelected, dateStamp } = useAppSelector(
    (state) => state.createOrderPage,
  );
  const { role } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [isAnimating, setIsAnimating] = useState(false);

  const locale = localStorage.getItem('selectedLanguage') || 'en';

  const handleSelectDate = (date: Date) => {
    dispatch(setDateStamp(date!?.getTime()));
    dispatch(setIsTimeSelected(false));
  };

  return (
    <>
      <SectionLabel
        title={t('create_order.date_and_time')}
        description={
          selectedDateTimestamp && chosenServices[0]
            ? `${new Date(selectedDateTimestamp).toLocaleDateString([], {
                month: 'short',
                weekday: 'short',
                day: 'numeric',
                ...(isTimeSelected && {
                  hour: 'numeric',
                  minute: 'numeric',
                }),
              })}
        -
        ${
          isTimeSelected
            ? convertMsToTime(
                selectedDateTimestamp + chosenServices[0]?.gssDto?.duration,
              )
            : ''
        }`
            : ''
        }
        icon={CalendarIcon}
        enable={isDatePickerOpen}
        onClick={toggleDatePicker}
      />
      <AnimatePresence>
        {isDatePickerOpen && (
          <>
            <li className=' flex flex-col items-center px-5'>
              <ReactDatePicker
                dateFormat='MMMM d, yyyy h:mm aa'
                filterDate={(date) => allowedDays.includes(date.getDate())}
                inline
                maxDate={nextMonth}
                minDate={new Date()}
                onChange={(date) => {
                  handleSelectDate(date!);
                }}
                onMonthChange={(date) =>
                  dispatch(setMonth(date.getMonth() + 1))
                }
                renderCustomHeader={HeaderDatePicker}
                selected={dateStamp ? new Date(dateStamp) : null}
                wrapperClassName='transition-all focus:outline-none '
                shouldCloseOnSelect={false}
              />
              {selectedDateTimestamp && (
                <CustomTimeSlotsList
                  timeSlots={timeSlots || []}
                  timeStamp={selectedDateTimestamp}
                  handleSelectTime={(time: Date) => {
                    dispatch(setDateStamp(time.getTime()));
                    dispatch(setIsTimeSelected(true));
                  }}
                />
              )}
            </li>
            {role === 'specialist' &&
              dateStamp &&
              specialistOrders.length > 0 && (
                <motion.ul
                  className={`my-4 flex max-h-[30vh] flex-col gap-2 ${
                    isAnimating ? 'overflow-hidden' : 'overflow-y-auto'
                  } px-5 pr-2`}
                  variants={variants}
                  initial='closed'
                  animate={specialistOrders.length > 0 ? 'open' : 'closed'}
                  exit='closed'
                  onAnimationStart={() => setIsAnimating(true)}
                  onAnimationComplete={() => setIsAnimating(false)}
                >
                  <li>
                    <p className='text-sm text-secondary'>
                      {t('orders_on')}{' '}
                      {new Date(dateStamp).toLocaleString(locale, {
                        weekday: 'long',
                        day: 'numeric',
                      })}
                    </p>
                  </li>
                  {specialistOrders?.map((order) => (
                    <TimeSpecificOrderButton
                      url={`/specialist/orders/${order.idStr}`}
                      order={order}
                      key={order.idStr}
                    />
                  ))}
                </motion.ul>
              )}
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default DateSection;
