import { useApiRequest } from 'hooks/useApiRequest';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useCallback, useEffect, useState } from 'react';
import { Service, SpecialistProfileDto } from 'react-app-env';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import {
  addChosenService,
  clearChosenServices,
  resetState,
  setIsCalendarOpen,
  setOrderDto,
  setSpecialistDto,
} from 'redux/createOrderPageSlice';
import {
  Button,
  OrderServiceForm,
  SectionHeader,
  SpecialistAvatar,
  SpecialistStats,
  Wrapper,
} from 'ui';
import { BASE_URL, createOrder, fetchRequestBoolean } from 'api/api';
import ReactDOM from 'react-dom';
import RateSpecialistModal from 'modals/RateSpecialistModal';
import { logoutUser } from 'redux/userSlice';
import { toggleModal } from 'redux/modalSlice';
import { FaInstagram } from 'react-icons/fa6';

const allDays = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

const CreateOrderPage = () => {
  const { t } = useTranslation();

  const { state: specialistByLocationState } = useLocation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { uid, role, referralCode, isLoggedIn } = useAppSelector(
    (state) => state.user,
  );
  const { isCalendarOpen, dateStamp, chosenServices, isTimeSelected, month } =
    useAppSelector((state) => state.createOrderPage);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referralCodeByParam = searchParams.get('ref');

  const userReferralCode = referralCode ? referralCode : referralCodeByParam;

  const { data: fetchedSpecialist } = useApiRequest<SpecialistProfileDto>({
    endpoint:
      userReferralCode && !specialistByLocationState
        ? `${BASE_URL}specialist/info/${userReferralCode}`
        : null,
    destructuringDataType: 'profileDto',
  });
  const [specialist, setSpecialist] = useState<SpecialistProfileDto | null>(
    null,
  );

  useEffect(() => {
    if (fetchedSpecialist && !specialist) {
      setSpecialist(fetchedSpecialist);
      dispatch(setSpecialistDto(fetchedSpecialist));
      return;
    }
    if (specialistByLocationState?.stringId && !specialist) {
      setSpecialist(specialistByLocationState);
      dispatch(setSpecialistDto(specialistByLocationState));
    }
  }, [specialistByLocationState, fetchedSpecialist, specialist, dispatch]);

  const day = dateStamp ? new Date(dateStamp).getDate() : new Date().getDate();
  const year = new Date().getFullYear();

  const { data: allowedDays } = useApiRequest<number[]>({
    endpoint: specialist?.stringId
      ? `${BASE_URL}orders/${specialist?.stringId}/availableDays/${month}/${year}`
      : null,
    destructuringDataType: 'days',
    deps: month,
  });
  const { data: services } = useApiRequest<Service[]>({
    endpoint: specialist?.stringId
      ? `${BASE_URL}specialists/${specialist?.stringId}/services`
      : null,
    destructuringDataType: 'services',
    deps: specialist?.stringId,
  });
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [region, city] = timeZone.split('/');
  const { loading: loadingTimestamps, data: timestamps } = useApiRequest<
    number[]
  >({
    endpoint:
      chosenServices.length > 0 && specialist?.stringId
        ? `${BASE_URL}specialists/${specialist?.stringId}/orders/${day}/${month}/${year}/${chosenServices[0]?.gssDto.duration}/${region}/${city}`
        : null,
    destructuringDataType: 'ts',
    deps: day,
  });
  // Convert the timestamps to Date objects
  const timeSlots = useCallback(() => {
    return timestamps ? timestamps.map((timestamp) => new Date(timestamp)) : [];
  }, [timestamps]);

  const handleCreateOrder = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!dateStamp) {
      toast.error(t('choose_date'));
      return;
    }

    if (!isLoggedIn) {
      dispatch(
        setOrderDto({
          clientId: uid, // maybe empty for not logged user
          specialistId: specialist?.stringId!,
          price: chosenServices[0]?.gssDto.price!,
          serviceId: chosenServices[0]?.gssDto.gserviceIdStr!,
          duration: chosenServices[0]?.gssDto.duration!,
          ts: dateStamp,
          role: 'client',
        }),
      );
      navigate('/login-client');
      return;
    }

    const response = await createOrder(
      {
        clientId: uid,
        specialistId: specialist?.stringId!,
        price: chosenServices[0]?.gssDto.price!,
        serviceId: chosenServices[0]?.gssDto.gserviceIdStr!,
        duration: chosenServices[0]?.gssDto.duration!,
        ts: dateStamp,
        role,
      },
      t,
    );
    if (response?.orderIdStr) {
      toast.success(t('order_success'));
      dispatch(resetState());
      navigate(`/orders/${response.orderIdStr}`);
    }
  };

  const toggleCalendarVisibility = () => {
    if (chosenServices.length === 0) {
      toast.error(t('choose_service'));
      return;
    }
    dispatch(setIsCalendarOpen(!isCalendarOpen));
  };

  const handleChooseService = (service: Service) => {
    dispatch(clearChosenServices());
    dispatch(addChosenService(service));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (role === 'specialist' && specialist && uid === specialist?.stringId) {
      navigate('/specialist/create-order');
      return;
    }

    const logoutSpecialist = async () => {
      if (role === 'specialist' && specialist && uid !== specialist?.stringId) {
        await fetchRequestBoolean(`login/logoutSpecialist/${uid}`, 'POST');
        localStorage.removeItem('gotouAccessToken');
        localStorage.removeItem('gotouRefreshToken');
        dispatch(logoutUser());
        dispatch(resetState());
      }
    };

    logoutSpecialist();
  }, [dispatch, navigate, role, specialist, uid]);

  return (
    <Wrapper noHorizontalPadding flexColumnSpaceBetween>
      <SectionHeader
        title={t('create_order.name')}
        wrapperClassName='pl-5'
        showGoBackButton={!!uid}
      />
      <div className='mb-5 mt-7 flex items-center justify-between gap-4 px-6 xs:px-3'>
        <div className='flex flex-col items-center gap-2'>
          <SpecialistAvatar
            src={specialist?.profilePhoto}
            size={124}
            showLikeButton={false}
          />
          {specialist?.insta && (
            <div
              className='flex cursor-pointer items-center gap-2'
              onClick={() =>
                window.open(specialist.insta.replace(/"/g, ''), '_blank')
              }
            >
              <FaInstagram /> <p>Instagram</p>
            </div>
          )}
        </div>
        <SpecialistStats
          rank={specialist?.rating || 0}
          imagesAmount={specialist?.picsCnt}
          portfolioUid={specialist?.stringId}
          reviewsUid={specialist?.stringId}
          reviewsNumber={specialist?.reviewsCnt}
          showLeaveReview={false}
        />
      </div>
      <div className='space-y-5'>
        <div className='flex flex-row items-center justify-between px-6 xs:px-3'>
          <div>
            <h2 className='text-2xl font-medium leading-7'>
              {specialist?.firstName} {specialist?.lastName}
            </h2>
            <p className='text-sm text-secondary'>
              {t('edit_profile.since')} {specialist?.since}
            </p>
          </div>
          <Button
            variant='yellowStroke'
            onClick={() =>
              dispatch(toggleModal({ modalType: 'rateSpecialist' }))
            }
          >
            {t('order.client.leave_review')}
          </Button>
        </div>
        {specialist?.descr && (
          <div className='mx-6 rounded-lg bg-[#F2F2F2] p-3 xs:mx-3'>
            <p className='font-medium'>{specialist?.descr}</p>
          </div>
        )}
      </div>
      <OrderServiceForm
        allowedDays={
          allowedDays && allowedDays?.length >= 0 ? allowedDays : allDays
        }
        isCustomerSelectOpen={false}
        isCustomerSelectionOpen={false}
        isDatePickerOpen={isCalendarOpen}
        selectedDateTimestamp={dateStamp}
        chosenServices={chosenServices}
        toggleDatePicker={toggleCalendarVisibility}
        onOrderSubmit={handleCreateOrder}
        timeSlots={timeSlots()}
        services={services || []}
        handleChooseService={handleChooseService}
      />

      <div className='px-4'>
        <Button
          className='mt-10 w-full py-4'
          variant='yellow'
          type='submit'
          form='create-order'
          loading={loadingTimestamps}
          disabled={
            chosenServices.length === 0 ||
            timeSlots()?.length === 0 ||
            !isTimeSelected
          }
        >
          {t('menu.create_order')}
        </Button>
      </div>
      {ReactDOM.createPortal(
        <RateSpecialistModal
          specialistName={`${specialist?.firstName} ${specialist?.lastName}`}
        />,
        document.getElementById('root') as Element,
      )}
    </Wrapper>
  );
};

export default CreateOrderPage;
