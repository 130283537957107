import React, { useCallback, useEffect } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ModalState, closeModal } from 'redux/modalSlice';
import { useLocation } from 'react-router-dom';

type ModalHandlingProps = {
  handleCloseModal: () => void;
};

export function withModalHandling<P extends object>(
  WrappedComponent: React.ComponentType<P & ModalHandlingProps>,
  modalType: keyof ModalState,
) {
  return (props: P) => {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();

    const handleCloseModal = useCallback(() => {
      dispatch(closeModal(modalType));
      document.body.classList.remove('overflow-hidden');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
      handleCloseModal();
    }, [handleCloseModal, pathname]);

    return <WrappedComponent {...props} handleCloseModal={handleCloseModal} />;
  };
}
