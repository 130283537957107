import React, { useState, useEffect } from 'react';
import { useApiRequest } from 'hooks/useApiRequest';
import { useAppSelector } from 'hooks/useAppSelector';
import { FavoriteSpecialistCard, SectionHeader, Wrapper } from 'ui';
import { BASE_URL } from 'api/api';
import Logo from 'images/icons/logo.svg';
import { useTranslation } from 'react-i18next';

type Specialist = {
  createdAt: number;
  firstName: string;
  id: number;
  lastName: string;
  picsCnt: number;
  reviewsCnt: number;
  profilePhoto: string;
  rating: number;
  since: number;
  stringId: string;
  subcategory: string;
};

const FavoritePage = () => {
  const { uid } = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  const { loading, data: speciaslists } = useApiRequest<Specialist[]>({
    endpoint: `${BASE_URL}favourites/${uid}`,
    destructuringDataType: 'specs',
  });

  const [localSpecialists, setLocalSpecialists] = useState(speciaslists);

  useEffect(() => {
    setLocalSpecialists(speciaslists);
  }, [speciaslists]);

  const handleRemoveSpecialist = (id: string) => {
    setLocalSpecialists(
      localSpecialists &&
        localSpecialists.filter(
          (specialist: any) => specialist.stringId !== id,
        ),
    );
  };

  return (
    <Wrapper className='flex flex-col gap-3'>
      <SectionHeader title={t('client.favorite.specialists')} />
      <div className='grid grid-cols-2 justify-items-center gap-3 sm:max-h-[90vh] sm:overflow-y-auto xs:grid-cols-1'>
        {!loading &&
          localSpecialists &&
          localSpecialists.length > 0 &&
          localSpecialists.map((specialist: any) => (
            <FavoriteSpecialistCard
              specialist={specialist}
              setChangeState={() => handleRemoveSpecialist(specialist.stringId)}
              key={specialist.stringId}
            />
          ))}
        {loading && (
          <div className='h-[246px] w-[160px] animate-pulse rounded-lg bg-secondary' />
        )}
      </div>
      {!loading && localSpecialists && localSpecialists.length === 0 && (
        <div className='mt-20 flex flex-col items-center justify-center gap-2'>
          <img src={Logo} alt='Logo' width={50} height={50} />
          <h2 className='text-center text-2xl text-accent'>
            {t('favorite.empty')}
          </h2>
          <p className='text-center text-secondary'>
            {t('favorite.empty.description')}
          </p>
        </div>
      )}
    </Wrapper>
  );
};

export default FavoritePage;
