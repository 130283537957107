import React, { useState } from 'react';
import { IoStar, IoStarHalfOutline, IoStarOutline } from 'react-icons/io5';

interface RatingStarsProps {
  initialRating?: number;
  onRatingChange?: (rating: number) => void;
}

const RatingStars = ({
  initialRating = 0,
  onRatingChange,
}: RatingStarsProps) => {
  const [rating, setRating] = useState(initialRating);
  const [hoverRating, setHoverRating] = useState(0);

  const handleClick = (value: number) => {
    setRating(value);
    if (onRatingChange) {
      onRatingChange(value);
    }
  };

  const handleMouseOver = (
    value: number,
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    const star = e.currentTarget.getBoundingClientRect();
    const leftSide = e.clientX - star.left;
    const starWidth = star.right - star.left;
    const halfStar = leftSide < starWidth / 2;
    setHoverRating(halfStar ? value - 0.5 : value);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  return (
    <div className='flex gap-2' onMouseLeave={handleMouseLeave}>
      {[1, 2, 3, 4, 5].map((i) => {
        const fullValue = i;
        const halfValue = i - 0.5;

        return (
          <span
            key={i}
            onClick={() => handleClick(hoverRating)}
            onMouseOver={(e) => handleMouseOver(fullValue, e)}
          >
            {hoverRating >= fullValue ||
            (!hoverRating && rating >= fullValue) ? (
              <IoStar className='text-accent' size={40} />
            ) : hoverRating >= halfValue ||
              (!hoverRating && rating >= halfValue) ? (
              <IoStarHalfOutline className='text-accent' size={40} />
            ) : (
              <IoStarOutline className=' text-secondary' size={40} />
            )}
          </span>
        );
      })}
    </div>
  );
};

export default RatingStars;
