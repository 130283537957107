import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setOrderTimeDateStamp } from 'redux/orderTimeSlice';
import { DayTimeSelector } from 'ui';

type TimeSlotListProps = {
  timeInterval?: number;
};

const timeRanges = [
  { start: 6, end: 10, label: 'Morning' },
  { start: 10.5, end: 16, label: 'Afternoon' },
  { start: 16.5, end: 21, label: 'Evening' },
  { start: 22, end: 5.5, label: 'Night' },
];

const generateTimeSlots = (date: Date, interval: number) => {
  const start = date;
  const isToday = start.toDateString() === new Date().toDateString();

  if (isToday) {
    start.setHours(start.getHours(), start.getMinutes() + 30);
  } else {
    start.setHours(0, 0, 0, 0);
  }

  const end = new Date(date);
  end.setHours(23, 30, 0, 0);

  const timeSlots = [];
  let currentTime = start;

  while (currentTime <= end) {
    timeSlots.push(new Date(currentTime));
    currentTime.setMinutes(currentTime.getMinutes() + interval);
  }

  return timeSlots;
};

const findRangeIndex = (timeSlot: Date) => {
  const hours = timeSlot.getHours() + timeSlot.getMinutes() / 60;
  return timeRanges.findIndex((range) => {
    if (range.start < range.end) {
      return hours >= range.start && hours < range.end;
    } else {
      return hours >= range.start || hours < range.end;
    }
  });
};

const TimeSlotList = ({ timeInterval = 30 }: TimeSlotListProps) => {
  const { dateStamp } = useAppSelector((state) => state.orderTime);
  const dispatch = useAppDispatch();

  const [selectedRange, setSelectedRange] = useState(
    findRangeIndex(new Date(dateStamp)),
  );
  const [timeSlots, setTimeSlots] = useState<Date[]>(
    generateTimeSlots(new Date(dateStamp), timeInterval),
  );

  useEffect(() => {
    const newTimeSlots = generateTimeSlots(new Date(dateStamp), timeInterval);
    setTimeSlots(newTimeSlots);
  }, [dateStamp, timeInterval]);

  const filteredTimeSlots = timeSlots.filter((time) => {
    const hours = time.getHours() + time.getMinutes() / 60;
    const range = timeRanges[selectedRange];
    if (range.start < range.end) {
      return hours >= range.start && hours < range.end;
    } else {
      return hours >= range.start || hours < range.end;
    }
  });

  return (
    <>
      <DayTimeSelector
        timeRanges={timeRanges}
        selectedIndex={selectedRange}
        setDayTime={setSelectedRange}
      />
      <div className='horizontal-scrollbar flex gap-4 overflow-x-auto whitespace-nowrap'>
        {filteredTimeSlots.map((timeSlot, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                dispatch(setOrderTimeDateStamp(timeSlot.getTime()));
              }}
              className={`inline-block cursor-pointer rounded-[28px] border border-accent px-4 py-2 ${
                dateStamp === timeSlot.getTime() ? 'bg-accent text-white' : ''
              }`}
            >
              {timeSlot.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TimeSlotList;
