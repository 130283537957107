import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MainLinks } from './AboutHeader';
import HeaderLink from './HeaderLink';
import Logo from 'images/icons/Logo-goto-white.svg';

const AboutFooter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigation = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate('/#' + id, { replace: true });
    }
  };
  return (
    <footer className='mt-10 flex w-full items-center justify-between bg-[#1F1F1F]  px-10 py-16 sm:px-[9vw] smMax:flex-col smMax:gap-3'>
      <div className='flex flex-col items-center justify-center'>
        <img
          src={Logo}
          alt='logo'
          width={139}
          height={78}
          className='mr-[2%] '
          draggable={false}
        />
        <p className='text-center text-xs text-white sm:whitespace-nowrap smMax:text-[#FFFFFF66]'>
          © 2023 GotoU-Labs, Inc.
        </p>
      </div>
      <ul className='flex items-center gap-2 text-white sm:gap-12 smMax:flex-col'>
        {MainLinks.map((link) => (
          <HeaderLink
            key={link.key}
            text={t(link.key)}
            className=' !text-base font-normal text-white !opacity-100'
            onClick={() => handleNavigation(link.value)}
          />
        ))}
        <HeaderLink
          onClick={() => navigate('/privacy')}
          className=' !text-base font-normal text-white !opacity-100'
          text={t('terms_and_privacy')}
        />
      </ul>
    </footer>
  );
};

export default AboutFooter;
