import React from 'react';
import PersonIcon from 'images/emoji/Face.svg';
import { useTranslation } from 'react-i18next';

type CustomerLabelProps = {
  customerName: string | undefined;
  phoneNumber: string | undefined;
};

const CustomerLabel = ({ customerName, phoneNumber }: CustomerLabelProps) => {
  const { t } = useTranslation();
  return (
    <div className='flex items-center'>
      <img
        src={PersonIcon}
        alt='person'
        className='mr-1 xs:hidden'
        width={20}
        height={20}
      />
      <div className='flex flex-col items-start gap-1'>
        {customerName ? (
          <>
            <p className='ml-1 text-sm text-primary'>{customerName}</p>
            <p className='text-sm text-gray-2'>{phoneNumber}</p>
          </>
        ) : (
          <p className='text-sm text-secondary'>
            {t('create_order.choose_customer')}
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomerLabel;
