import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Wrapper } from 'ui';

const ErrorPage = () => {
  return (
    <Wrapper className='flex flex-col items-center justify-center gap-8'>
      <h1 className='text-5xl font-semibold text-primary'>404</h1>
      <p className='text-center'>Page not found</p>
      <Link to='/'>
        <Button variant='yellow'>Back to home page</Button>
      </Link>
    </Wrapper>
  );
};

export default ErrorPage;
