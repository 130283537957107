import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui';

type ActionButtonsProps = {
  handleCancelClick: () => void;
  isSaveButtonDisabled?: boolean;
  handleSaveClick?: () => void;
  form?: string;
  isLoading?: boolean;
  saveButtonText?: string;
  cancelButtonText?: string;
  wrapperClassName?: string;
  buttonsClassName?: string;
};

const ActionButtons = ({
  wrapperClassName,
  handleCancelClick,
  handleSaveClick,
  isSaveButtonDisabled = false,
  form,
  isLoading,
  saveButtonText,
  cancelButtonText,
  buttonsClassName,
}: ActionButtonsProps) => {
  const { t } = useTranslation();
  return (
    <div className={`flex w-full gap-5 ${wrapperClassName}`}>
      <Button
        className={`w-full py-4  ${buttonsClassName || ''}`}
        variant='yellowStroke'
        onClick={handleCancelClick}
      >
        {cancelButtonText || t('cancel')}
      </Button>
      <Button
        className={`w-full py-4 ${buttonsClassName || ''}`}
        variant='yellow'
        type='submit'
        form={form}
        onClick={handleSaveClick}
        disabled={isSaveButtonDisabled}
        loading={isLoading}
      >
        {saveButtonText || t('save')}
      </Button>
    </div>
  );
};

export default ActionButtons;
