import React from 'react';
import Spinner from './Spinner';

type ButtonProps = {
  children: React.ReactNode;
  variant?:
    | 'primary'
    | 'yellow'
    | 'yellowStroke'
    | 'whiteStroke'
    | 'grayStroke'
    | 'black';
  type?: 'button' | 'submit' | 'reset';
  skeleton?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  size?: 'sm' | 'md' | 'lg';
  form?: string;
};

const sizeClassMap = {
  sm: 'py-1 text-sm',
  md: 'py-2',
  lg: 'py-4',
};

const concatClassNames = (...classNames: (string | undefined)[]) =>
  classNames.filter(Boolean).join(' ');

const variantClassMap = {
  primary:
    'active:bg-accent-light not(:disabled)active:text-white bg-transparent border border-accent disabled:bg-transparent disabled:border-secondary disabled:text-secondary focus:bg-accent focus:text-white hover:bg-accent hover:text-white not(:disabled):active:bg-accent-light not(:disabled):active:text-white px-4 rounded text-primary',
  yellow:
    'active:bg-[#FFC909] not(:disabled)active:text-white bg-yellow-btn disabled:!opacity-60 hover:bg-[#FFC909] not(:disabled):active:bg-[#FFC909] not(:disabled):active:text-white px-4 rounded-50px text-primary',
  black:
    'active:bg-accent-light not(:disabled)active:text-white text-white rounded-50px bg-primary disabled:bg-transparent disabled:text-secondary focus:bg-accent focus:text-white hover:bg-accent hover:text-white not(:disabled):active:bg-accent-light not(:disabled):active:text-white px-4 rounded text-primary',
  yellowStroke:
    'active:bg-accent-light not(:disabled)active:text-accent bg-white border border-accent disabled:!opacity-60 not(:disabled):active:bg-accent-light not(:disabled):active:text-accent px-4 rounded-50px text-primary',
  whiteStroke:
    'active:bg-accent-light hover:!shadow-none hover:text-accent active:text-white bg-transparent border border-white disabled:opacity-60 not(:disabled):active:bg-accent-light not(:disabled):active:text-white px-4 rounded-50px text-primary',
  grayStroke:
    'active:bg-accent-light not(:disabled)active:text-white bg-transparent border not(:disabled):border-gray-3 disabled:bg-gray-3 disabled:!opacity-60 not(:disabled):active:bg-accent-light not(:disabled):active:text-white px-4 rounded-[10px] text-primary not(:disabled):hover:border-accent',
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      disabled,
      onClick,
      className,
      form,
      loading,
      variant = 'primary',
      type = 'button',
      size = 'md',
      skeleton = false,
    },
    ref,
  ) => {
    const buttonClassName = concatClassNames(
      `transition-all min-w-fit ease hover:shadow`,
      variantClassMap[variant],
      sizeClassMap[size],
      className,
    );

    if (skeleton) {
      return (
        <button
          className={`animate-pulse rounded bg-secondary ${
            variant === 'primary' ? '' : '-50px'
          } py-2 px-${
            variant === 'primary' ? '-4' : '-16'
          } text-transparent ${className}`}
          disabled={true}
        >
          {children}
        </button>
      );
    }

    return (
      <button
        ref={ref}
        className={buttonClassName}
        disabled={disabled || loading}
        type={type}
        onClick={onClick}
        form={form}
      >
        {loading ? <Spinner size={28} className='mx-auto' /> : children}
      </button>
    );
  },
);

export default Button;
