import React from 'react';
import { useTranslation } from 'react-i18next';
import { RatingStars, SpecialistAvatar } from 'ui';
import { convertMsToTime } from 'utils/time';

type RateOrderCardProps = {
  name: string;
  src: string;
  serviceName: string;
  timeOfOrder: number;
  isFavourite: boolean;
  handleLikeSpecialist: () => void;
  setStarRating: (rating: number) => void;
};

const OrderRatingCard = ({
  name,
  src,
  serviceName,
  timeOfOrder,
  handleLikeSpecialist,
  isFavourite,
  setStarRating,
}: RateOrderCardProps) => {
  const { t } = useTranslation();
  const formatDate = (date: number) => {
    const today = new Date();
    const yesterday = new Date(today);
    const dateToFormat = new Date(date);

    yesterday.setDate(yesterday.getDate() - 1);

    if (dateToFormat.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (dateToFormat.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      return dateToFormat.toLocaleDateString([], {
        day: 'numeric',
        month: 'long',
      });
    }
  };

  return (
    <div className='flex w-full flex-col items-center gap-3 '>
      <div className='flex flex-col items-center'>
        <SpecialistAvatar
          src={src}
          size={100}
          isFavourite={isFavourite}
          handleLikeSpecialist={handleLikeSpecialist}
          circleAvatar
        />
        <p className='font-medium'>{name}</p>
        <p className='text-center text-secondary underline'>
          {serviceName}: {formatDate(timeOfOrder)}{' '}
          {convertMsToTime(timeOfOrder)}
        </p>
      </div>
      <h2 className='mt-5 text-center text-2xl'>{t('order.rate_order')}</h2>
      <RatingStars onRatingChange={setStarRating} />
    </div>
  );
};

export default OrderRatingCard;
