import React, { useEffect } from 'react';
import { Button, Wrapper } from 'ui';
import BG from 'images/backgrounds/bg-trial.jpg';
import { useTranslation } from 'react-i18next';
import { IoCheckmark } from 'react-icons/io5';
import { fetchRequestBoolean } from 'api/api';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'hooks/useAppSelector';

const TrialPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTrialSubscribed } = useAppSelector((state) => state.specialist);

  const handleContinue = async () => {
    window.open('https://buy.stripe.com/aEU3fieCD8d771CbIJ', '_blank');
    await fetchRequestBoolean('specialists/startTrial', 'POST');
    navigate('/specialist/profile');
  };

  useEffect(() => {
    if (isTrialSubscribed) {
      navigate('/specialist/profile');
    }
  }, [navigate, isTrialSubscribed]);

  return (
    <Wrapper noHorizontalPadding flexColumnSpaceBetween>
      <div>
        <img
          src={BG}
          alt='Background'
          height={228}
          width={428}
          className='mb-5 w-full'
        />
        <div className='mb-10 space-y-3 px-4'>
          <h3 className='text-center text-xl2 font-medium'>
            {t('trialPage.title')}
          </h3>
          <p className='text-center text-md2 text-gray-9'>
            {t('trialPage.access')}{' '}
            <span className='whitespace-nowrap rounded-[20px] bg-[#FFDA57EB] px-2 py-1 font-medium text-primary'>
              {t('trialPage.freeTrial')}
            </span>
            <br />
            {t('trialPage.withoutRestrictions')}
          </p>
        </div>
        <ul className='mx-auto mb-10 max-w-[380px] space-y-3 px-4'>
          {(t('trialPage.benefits', { returnObjects: true }) as string[]).map(
            (benefit: string, i: number) => (
              <li className='flex items-center justify-between' key={i}>
                <IoCheckmark size={25} className='text-accent' />
                <p className='text-right text-md2'>{benefit}</p>
              </li>
            ),
          )}
        </ul>
      </div>
      <div className='space-y-[10px] px-5'>
        <Button
          variant='yellow'
          onClick={handleContinue}
          className='w-full py-4'
        >
          {t('trialPage.continue')}
        </Button>
        <p className='text-center text-sm text-gray-8'>
          {t('trialPage.cancelAnyTime')}
        </p>
      </div>
    </Wrapper>
  );
};

export default TrialPage;
