import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useOnclickOutside from 'react-cool-onclickoutside';
import toast from 'react-hot-toast';
import { Button, LanguageItem, ToggleChevron } from 'ui';
import { fetchRequestBoolean } from 'api/api';
import { SupportedLanguages } from 'redux/userSlice';
import i18n from '../../i18n';
import { useAppSelector } from 'hooks/useAppSelector';
import { useLocation } from 'react-router';
import UAicon from 'images/flags/ua.png';
import DEicon from 'images/flags/de.png';
import USicon from 'images/flags/us.png';
import PLicon from 'images/flags/pl.jpg';

const languageIcons = {
  ua: UAicon,
  de: DEicon,
  en: USicon,
  pl: PLicon,
};

const needToReload = (pathname: string) => {
  return (
    pathname.startsWith('/category') ||
    pathname.startsWith('/services') ||
    pathname.startsWith('/specialist/services/select') ||
    pathname.includes('/cancel') ||
    pathname.includes('/privacy')
  );
};

const LanguageDropDown = () => {
  const { pathname } = useLocation();
  const { isLoggedIn } = useAppSelector((state) => state.user);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    () => localStorage.getItem('selectedLanguage') || 'en',
  );

  const dropdownRef = useOnclickOutside(() => {
    if (showLanguageDropdown) setShowLanguageDropdown(false);
  });

  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const handleSelectLanguage = async (
    language: (typeof SupportedLanguages)[number],
  ) => {
    try {
      setShowLanguageDropdown(false);
      setSelectedLanguage(language);

      if (isLoggedIn) {
        const response = await fetchRequestBoolean(
          `app-config/changeLocale?selectedLocale=${language}`,
          'POST',
        );
        if (response && needToReload(pathname)) {
          window.location.reload();
        }
      }
    } catch (error) {
      toast.error('Error while changing language');
    }
  };

  return (
    <div ref={dropdownRef} className='relative'>
      <Button
        variant='yellowStroke'
        className='relative flex items-center gap-2 border-none !bg-gray-100 !py-1 !pl-14 smMax:text-sm'
        onClick={() => setShowLanguageDropdown(!showLanguageDropdown)}
      >
        <img
          src={languageIcons[selectedLanguage as keyof typeof languageIcons]}
          alt={selectedLanguage}
          className='absolute left-3 rounded-lg'
          width={34}
          height={21}
        />
        {selectedLanguage.toUpperCase()}
        <ToggleChevron size={15} isOpen={showLanguageDropdown} />
      </Button>

      <AnimatePresence>
        {showLanguageDropdown && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className='absolute top-full z-20 mt-3 flex min-w-max transform flex-col rounded-xl bg-gray-100 px-1 py-1 shadow-lg'
          >
            {SupportedLanguages.map((language) => (
              <LanguageItem
                handleSelectLanguage={handleSelectLanguage}
                language={language}
                key={language}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LanguageDropDown;
