import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  BASE_URL,
  fetchRequestBoolean,
  updateClientNameAndLocation,
  updateSpecialistNameAndLocation,
  updateUserAvatar,
} from 'api/api';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { toggleModal } from 'redux/modalSlice';
import {
  Button,
  CropImageSection,
  Input,
  SectionHeader,
  SpecialistAvatar,
  toastWithDismiss,
  Wrapper,
} from 'ui';
import {
  setSpecSubRole,
  setUserAvatar,
  updateSpecialistState,
} from 'redux/userSlice';
import {
  Autocomplete,
  Libraries,
  useJsApiLoader,
} from '@react-google-maps/api';
import toast from 'react-hot-toast';
import imageCompression from 'browser-image-compression';
import { LatLng } from 'use-places-autocomplete';
import { Currency } from 'react-app-env';
import { useApiRequest } from 'hooks/useApiRequest';
import heic2any from 'heic2any';
import { useTranslation } from 'react-i18next';
import { MdCancel } from 'react-icons/md';
import { setSpecDescription } from 'redux/specialistSlice';
import { useNavigate } from 'react-router';

const COMPRESSION_OPTIONS = {
  maxSizeMB: 0.2,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

const EditProfilePage = () => {
  const { t } = useTranslation();

  const {
    uid,
    role,
    firstName,
    lastName,
    address,
    profilePhoto,
    currency,
    city,
    country,
    coordinates,
  } = useAppSelector((state) => state.user);
  const { description: stateDescription } = useAppSelector(
    (state) => state.specialist,
  );

  const dispatch = useAppDispatch();

  const [currencyInput, setCurrencyInput] = useState(currency);
  const [cityInput, setCity] = useState(city);
  const [countryInput, setCountry] = useState(country);
  const [firstNameInput, setFirstNameInput] = useState(firstName);
  const [isLocationValid, setIsLocationValid] = useState(true);
  const [isOptionSelected, setIsOptionSelected] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [lastNameInput, setLastNameInput] = useState(lastName);
  const [latLng, setLatLng] = useState<LatLng>(coordinates);
  const [LIBRARIES] = useState<Libraries>(['places']);
  const [location, setLocation] = useState(address);
  const [profilePhotoInput, setProfilePhotoInput] = useState<File | null>(null);
  const [placeId, setPlaceId] = useState('');
  const [description, setDescription] = useState(stateDescription);
  const [isAutocompleteLoaded, setIsAutocompleteLoaded] = useState(false);

  const autocompleteRef = useRef<any>(null);

  const isNewUser = !firstName && !lastName && !address;
  const { data: currencyFetched } = useApiRequest<Currency>({
    endpoint:
      role === 'specialist' ? `${BASE_URL}specialists/${uid}/currency` : null,
    destructuringDataType: 'currency',
  });

  useEffect(() => {
    if (currencyFetched) {
      setCurrencyInput(currencyFetched);
    }
  }, [currencyFetched]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: LIBRARIES,
  });

  useEffect(() => {
    if (isLoaded) {
      setIsAutocompleteLoaded(true);
    }
  }, [isLoaded]);

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const location = place.formatted_address;
      setPlaceId(place.place_id);

      // Set latLng state
      if (place.geometry && place.geometry.location) {
        setLatLng({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      }
      // Set city and country state
      let isCity = false;
      let isCountry = false;
      for (let i = 0; i < place.address_components.length; i++) {
        const component = place.address_components[i];
        if (component.types.includes('locality')) {
          setCity(component.long_name);
          isCity = true;
        }
        if (component.types.includes('country')) {
          setCountry(component.long_name);
          isCountry = true;
        }
      }

      setLocation(location);
      setIsOptionSelected(true);

      if (!isCity || !isCountry) {
        if (!isCity) {
          setCity('');
        }
        if (!isCountry) {
          setCountry('');
        }
        toastWithDismiss(
          'Please select location with city and country',
          'error',
        );
        return;
      }

      setIsLocationValid(true);
    }
  };

  const updateAvatar = async (imageFile: File) => {
    try {
      const compressedFile = await imageCompression(
        imageFile,
        COMPRESSION_OPTIONS,
      );
      toast.dismiss();
      await toast.promise(
        updateUserAvatar(uid, compressedFile, role as 'client' | 'specialist'),
        {
          loading: 'Updating avatar...',
          success: () => {
            dispatch(setUserAvatar(URL.createObjectURL(compressedFile)));
            setProfilePhotoInput(null);
            return 'Avatar updated';
          },
          error: () => {
            setProfilePhotoInput(null);
            return 'Something went wrong';
          },
        },
      );
    } catch (error) {
      setProfilePhotoInput(null);
      toastWithDismiss(t('something_wrong'), 'error');
    }
  };

  const handleAvatarChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!!event.currentTarget.files?.[0]) {
      let imageFile = event.currentTarget.files?.[0];
      //TODO - Check HEIF format
      if (!['image/jpeg', 'image/png', 'image/heic'].includes(imageFile.type)) {
        toastWithDismiss(
          'Invalid file type. Please select a PNG or JPEG image.',
          'error',
        );
        return;
      }
      if (imageFile.type === 'image/heic') {
        // Show a loading toast
        const toastId = toast.loading('Loading...');
        const response = await fetch(URL.createObjectURL(imageFile));
        const heicBlob = await response.blob();
        const options = {
          blob: heicBlob,
          toType: 'image/jpeg',
          quality: 0.5,
        };
        const jpegBlob = await heic2any(options);
        toast.dismiss(toastId);
        // Ensure jpegBlob is an array
        const jpegBlobArray = Array.isArray(jpegBlob) ? jpegBlob : [jpegBlob];
        imageFile = new File(jpegBlobArray, imageFile.name, {
          type: 'image/jpeg',
        });
      }

      setProfilePhotoInput(imageFile);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!isOptionSelected) {
      setIsLocationValid(false);
      return;
    }
    if (!cityInput || !countryInput) {
      toastWithDismiss('Please select location with city and country', 'error');
      return;
    }

    setIsLoading(true);

    const nameAndLocationData = {
      firstName: firstNameInput,
      lastName: lastNameInput,
    };

    const locationData = {
      city: cityInput,
      country: countryInput,
      fullName: location,
      latitude: latLng?.lat,
      longitude: latLng?.lng,
      placeId,
    };

    let response;
    if (role === 'specialist') {
      response = await updateSpecialistNameAndLocation(
        nameAndLocationData,
        locationData,
      );
      response =
        response &&
        (await fetchRequestBoolean(`specialists/currency`, 'POST', {
          currency: currencyInput,
        }));

      response =
        response &&
        (await fetchRequestBoolean(
          `specialists/updateDescr`,
          'POST',
          description || ' ',
        ));
    } else if (role === 'client') {
      response = await updateClientNameAndLocation(
        {
          id: uid,
          ...nameAndLocationData,
        },
        {
          lat: latLng?.lat!,
          lon: latLng?.lng!,
          name: location,
          city: cityInput,
          country: countryInput,
          placeId,
        },
      );
    }
    setIsLoading(false);

    if (response) {
      dispatch(
        updateSpecialistState({
          firstName: firstNameInput,
          lastName: lastNameInput,
          currency: currencyInput,
          address: location,
          city: cityInput,
          country: countryInput,
          coordinates: latLng,
        }),
      );
      dispatch(setSpecDescription(description));
      if (role === 'specialist' && isNewUser) {
        dispatch(setSpecSubRole('editingSchedule'));
      }
      dispatch(
        toggleModal({
          modalType: 'changesSaved',
          url: role === 'client' ? '/category' : '/specialist/profile',
        }),
      );
    }
  };

  return (
    <Wrapper flexColumnSpaceBetween>
      {!profilePhotoInput ? (
        <>
          <div className='flex flex-col gap-2'>
            <SectionHeader
              title={t('edit_profile.header')}
              subtitle={isNewUser ? t('edit_profile.header_new_user') : ''}
              showGoBackButton={!isNewUser}
            />
            <div className='mt-6'>
              <SpecialistAvatar
                src={profilePhoto}
                size={110}
                showLikeButton={false}
              />
              <Input
                type='file'
                placeholder='Change profile photo'
                accept='image/png, image/jpeg'
                onChange={handleAvatarChange}
                className='hover:file:bg-pink-accent pointer-events-none mt-4
        block select-none border-none text-sm text-white
        transition-all file:pointer-events-auto file:mr-4 file:cursor-pointer file:rounded-md file:border-0
        file:bg-yellow-btn file:px-4
        file:py-2 file:text-sm file:font-semibold file:text-primary file:hover:bg-yellow-btn-hover'
              />
            </div>
            <form
              className='mt mt-10 flex w-full flex-col gap-5'
              onSubmit={handleSubmit}
              id='editProfile'
            >
              <Input
                variant='outline'
                label={t('edit.profile_first_name')}
                required
                value={firstNameInput || ''}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (/^[^\p{P}\p{S}]*$/u.test(newValue)) {
                    setFirstNameInput(newValue);
                  }
                }}
              />
              <Input
                variant='outline'
                label={t('edit.profile_last_name')}
                required
                value={lastNameInput || ''}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (/^[^\p{P}\p{S}]*$/u.test(newValue)) {
                    setLastNameInput(newValue);
                  }
                }}
              />
              {isAutocompleteLoaded && (
                <Autocomplete
                  onLoad={(ref) => (autocompleteRef.current = ref)}
                  onPlaceChanged={handlePlaceChanged}
                  children={
                    <Input
                      variant='outline'
                      label={t('edit.profile_location')}
                      required
                      value={location || ''}
                      className={`${isLocationValid ? '' : '!border-error'}`}
                      onChange={(e) => {
                        setLocation(e.target.value);
                        setIsOptionSelected(false);
                      }}
                      helperText={`${
                        !isLocationValid
                          ? 'Please select your location from the list'
                          : ''
                      }`}
                    />
                  }
                />
              )}
              {role === 'specialist' && (
                <>
                  <Input
                    Icon={
                      <MdCancel
                        onClick={() => setDescription('')}
                        className='h-[37px] w-[37px] cursor-pointer rounded-md p-2 text-[#9E9E9E] transition-all hover:text-primary active:p-[10px]'
                      />
                    }
                    label={t('edit.profile_about_you')}
                    onChange={(e) => setDescription(e.target.value)}
                    variant='outline'
                    value={description || ''}
                    maxRows={5}
                    className='pr-[45px]'
                    multiline
                  />
                  <div className='relative mt-4 w-56 rounded-lg border border-secondary p-2 transition-all hover:border-accent'>
                    <label className='absolute -top-2 bg-white text-xs text-gray-2'>
                      {t('edit_profile.currency')}
                    </label>
                    <select
                      name='currency'
                      id='currency'
                      className='w-52 cursor-pointer bg-white py-2 outline-none'
                      value={currencyInput}
                      onChange={(e) =>
                        setCurrencyInput(e.target.value as Currency)
                      }
                    >
                      <option value='USD'>USD $</option>
                      <option value='EUR'>EUR €</option>
                      <option value='UAH'>UAH ₴</option>
                      <option value='CHF'>CHF ₣</option>
                    </select>
                  </div>
                </>
              )}
            </form>
          </div>
          <Button
            className='mt-4 py-4'
            variant='yellow'
            type='submit'
            loading={isLoading}
            form='editProfile'
          >
            {t('save')}
          </Button>
        </>
      ) : (
        <CropImageSection
          image={URL.createObjectURL(profilePhotoInput)}
          handleCancelClick={() => setProfilePhotoInput(null)}
          handleSaveClick={(image) => updateAvatar(image)}
        />
      )}
    </Wrapper>
  );
};

export default EditProfilePage;
