export const PHONE_INPUT_COUNTRIES = [
  'ch', // Switzerland
  'ua', // Ukraine
  'pt', // Portugal
  'pl', // Poland
  'us', // United States
  'de', // Germany
  'gb', // United Kingdom
  'th', // Thailand
  'qa', // Qatar
  'il', // Israel
  'au', // Australia
  'it', // Italy
  'es', // Spain
  'fr', // France
  'ro', // Romania
  'ee', // Estonia
  'mx', // Mexico
  'at', // Austria
  'id', // Indonesia
  'ca', // Canada
];
