import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useApiRequest } from 'hooks/useApiRequest';
import { useAppSelector } from 'hooks/useAppSelector';
import { ActionButtons, SectionHeader, Wrapper } from 'ui';
import { BASE_URL, updateSpecialistSchedule } from 'api/api';
import { Schedule } from 'react-app-env';
import HoursForm from 'components/specialist/Schedule/HoursForm';
import { toggleModal } from 'redux/modalSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { convertTimeToMinutes } from 'utils/time';
import { useTranslation } from 'react-i18next';
import errorToast from 'modals/ErrorToast';
import { setIsNewUser, setSpecSubRole } from 'redux/userSlice';

type CheckedState = {
  [key: string]: boolean;
};

const MAX_TIME_SLOTS = 3;

export const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const isOverlapping = (
  slot1: { start: string; end: string },
  slot2: { start: string; end: string },
) => {
  const slot1Start = convertTimeToMinutes(slot1.start);
  const slot1End = convertTimeToMinutes(slot1.end);
  const slot2Start = convertTimeToMinutes(slot2.start);
  const slot2End = convertTimeToMinutes(slot2.end);

  return (
    (slot2Start >= slot1Start && slot2Start < slot1End) ||
    (slot2End > slot1Start && slot2End <= slot1End)
  );
};

const SchedulePage = () => {
  const { t } = useTranslation();

  const { uid, isNewUser } = useAppSelector((state) => state.user);
  const { schedule } = useAppSelector((state) => state.specialistSchedule);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: fetchedSchedule } = useApiRequest<Schedule[]>({
    endpoint: `${BASE_URL}specialists/${uid}/schedule`,
    destructuringDataType: 'dtos',
    deps: uid,
  });

  const [checkedState, setCheckedState] = useState<CheckedState>(
    daysOfWeek.reduce(
      (initial, day) => ({
        ...initial,
        [day]: schedule[0]?.days.includes(day) || false,
      }),
      {},
    ),
  );
  const [timeSlots, setTimeSlots] = useState([
    { start: '08:00', end: '17:00' },
  ]);
  const [timeSlotsWeekend, setTimeSlotsWeekend] = useState([
    { start: '08:00', end: '17:00' },
  ]);
  const [checkedStateWeekend, setCheckedStateWeekend] = useState<CheckedState>(
    daysOfWeek.reduce(
      (initial, day) => ({
        ...initial,
        [day]: schedule[1]?.days.includes(day) || false,
      }),
      {},
    ),
  );
  const [isLoading, setIsLoading] = useState(false);

  const [isOpenHours, setIsOpenHours] = useState({
    work: true,
    weekend: false,
  });

  const handleToggleHours = useCallback(
    (type: 'work' | 'weekend') => {
      setIsOpenHours({ ...isOpenHours, [type]: !isOpenHours[type] });
    },
    [isOpenHours],
  );

  const handleAddTimeSlot = (
    timeSlots: { start: string; end: string }[],
    setTimeSlots: React.Dispatch<
      React.SetStateAction<{ start: string; end: string }[]>
    >,
  ) => {
    if (timeSlots.length < MAX_TIME_SLOTS) {
      let newSlotStart = '08:00'; // Default start time

      if (timeSlots.length > 0) {
        const lastSlotEndMinutes = convertTimeToMinutes(
          timeSlots[timeSlots.length - 1].end,
        );
        const newSlotStartMinutes = lastSlotEndMinutes + 60; // One hour later
        const newSlotStartHours = Math.floor(newSlotStartMinutes / 60);
        const newSlotStartMinutesPastHour = newSlotStartMinutes % 60;

        // If the new slot start time is beyond 24 hours, create a time slot with empty start and end times
        if (newSlotStartHours >= 24) {
          setTimeSlots([...timeSlots, { start: '', end: '' }]);
          return;
        }

        newSlotStart = `${newSlotStartHours
          .toString()
          .padStart(2, '0')}:${newSlotStartMinutesPastHour
          .toString()
          .padStart(2, '0')}`;
      }

      const newSlot = { start: newSlotStart, end: '' };

      setTimeSlots([...timeSlots, newSlot]);
    }
  };

  const handleUpdateTimeSlot = (
    index: number,
    updatedSlot: { start: string; end: string },
    setPassedTimeSlots: React.Dispatch<
      React.SetStateAction<
        {
          start: string;
          end: string;
        }[]
      >
    >,
  ) => {
    // Create a new array of time slots with the updated slot
    const updatedTimeSlots = timeSlots.map((slot, i) =>
      i === index ? updatedSlot : slot,
    );

    // Check if the updated slot overlaps with any other slot
    const isOverlap = updatedTimeSlots.some((slot, i) => {
      if (i === index) return false; // Skip the updated slot
      return isOverlapping(slot, updatedSlot);
    });

    if (isOverlap) {
      toast.error('Time slots cannot overlap.');
      return;
    }

    // If there's no overlap, update the time slots
    setPassedTimeSlots(updatedTimeSlots);
  };

  const handleSubmit = async () => {
    const allTimeSlotsFilled =
      timeSlotsWeekend.every((slot) => slot.start && slot.end) &&
      timeSlots.every((slot) => slot.start && slot.end);

    if (!allTimeSlotsFilled) {
      toast.error('Please fill in all time slots.');
      return;
    }

    const workHoursData = {
      timeFrames: timeSlots.map((slot) => ({
        from: slot.start,
        to: slot.end,
      })),
      days: Object.entries(checkedState)
        .filter(([_, isChecked]) => isChecked)
        .map(([day]) => day),
    };

    const weekendHoursData = {
      timeFrames: timeSlotsWeekend.map((slot) => ({
        from: slot.start,
        to: slot.end,
      })),
      days: Object.entries(checkedStateWeekend)
        .filter(([_, isChecked]) => isChecked)
        .map(([day]) => day),
    };
    setIsLoading(true);
    const res = await updateSpecialistSchedule(
      uid,
      workHoursData,
      weekendHoursData,
    );
    setIsLoading(false);
    if (res) {
      const savedIsNewUser = isNewUser;
      dispatch(setSpecSubRole('full'));
      dispatch(setIsNewUser(false));
      dispatch(
        toggleModal({
          modalType: 'changesSaved',
          url: savedIsNewUser ? '/specialist/services' : '/specialist/profile',
        }),
      );
    } else {
      errorToast({ message: t('something_wrong') });
    }
  };

  useEffect(() => {
    if (fetchedSchedule) {
      setCheckedState(
        daysOfWeek.reduce(
          (initial, day) => ({
            ...initial,
            [day]: fetchedSchedule[0]?.days.includes(day) || false,
          }),
          {},
        ),
      );

      setCheckedStateWeekend(
        daysOfWeek.reduce(
          (initial, day) => ({
            ...initial,
            [day]: fetchedSchedule[1]?.days.includes(day) || false,
          }),
          {},
        ),
      );

      if (fetchedSchedule[0]?.timeFrames) {
        setTimeSlots(
          fetchedSchedule[0].timeFrames.map((timeFrame) => ({
            start: timeFrame.from,
            end: timeFrame.to,
          })),
        );
      }

      if (fetchedSchedule[1]?.timeFrames) {
        setTimeSlotsWeekend(
          fetchedSchedule[1].timeFrames.map((timeFrame) => ({
            start: timeFrame.from,
            end: timeFrame.to,
          })),
        );
      }
    }
  }, [fetchedSchedule]);

  return (
    <Wrapper className=' flex flex-col gap-6 '>
      <SectionHeader
        title={t('profile.schedule')}
        subtitle={`Create your schedule ${
          isNewUser ? 'before starting work' : ''
        }`}
        showGoBackButton={!isNewUser}
      />
      <HoursForm
        handleToggleHours={handleToggleHours}
        isOpenHours={isOpenHours}
        handleAddTimeSlot={() => handleAddTimeSlot(timeSlots, setTimeSlots)}
        timeSlots={timeSlots}
        setTimeSlots={setTimeSlots}
        checkedState={checkedState}
        checkedStateWeekend={checkedStateWeekend}
        setCheckedState={setCheckedState}
        handleUpdateTimeSlot={handleUpdateTimeSlot}
        type={'work'}
        setCheckedStateWeekend={setCheckedStateWeekend}
      />

      <HoursForm
        handleToggleHours={handleToggleHours}
        isOpenHours={isOpenHours}
        checkedStateWeekend={checkedStateWeekend}
        setCheckedStateWeekend={setCheckedStateWeekend}
        checkedState={checkedState}
        timeSlots={timeSlotsWeekend}
        setTimeSlots={setTimeSlotsWeekend}
        handleAddTimeSlot={() =>
          handleAddTimeSlot(timeSlotsWeekend, setTimeSlotsWeekend)
        }
        type={'weekend'}
        setCheckedState={setCheckedState}
        handleUpdateTimeSlot={handleUpdateTimeSlot}
      />
      <ActionButtons
        isSaveButtonDisabled={false}
        isLoading={isLoading}
        handleSaveClick={handleSubmit}
        handleCancelClick={() => {
          return isNewUser ? undefined : navigate('/specialist/profile');
        }}
      />
    </Wrapper>
  );
};

export default SchedulePage;
