import { useEffect, useState } from 'react';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { getGeocode } from 'use-places-autocomplete';

type GeocodeProps =
  | {
      address: string;
    }
  | {
      location: {
        lat: number;
        lng: number;
      };
    };

const fetchTimeZone = async (lat: number, lon: number) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lon}&timestamp=${Math.floor(
      Date.now() / 1000,
    )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  );
  const data = await response.json();
  return data;
};

const useGeocodeTimeZone = (data: GeocodeProps) => {
  const [LIBRARIES] = useState<Libraries>(['places']);
  const [timeZoneId, setTimeZoneId] = useState<string | undefined>(undefined);

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
    throw new Error('REACT_APP_GOOGLE_MAPS_API_KEY is not defined');
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: LIBRARIES,
  });

  useEffect(() => {
    if (isLoaded && data) {
      const fetchGeocode = async () => {
        let lat, lng;

        if (data && 'address' in data && data.address !== '') {
          const geocodeResults = await getGeocode({ address: data.address });
          lat = geocodeResults[0].geometry.location.lat();
          lng = geocodeResults[0].geometry.location.lng();
        } else if (data && 'location' in data) {
          lat = data.location.lat;
          lng = data.location.lng;
        }

        if (lat !== undefined && lng !== undefined) {
          let timeZoneData = await fetchTimeZone(lat, lng);
          if (timeZoneData.status === 'OK') {
            setTimeZoneId(timeZoneData.timeZoneId);
          } else {
            setTimeZoneId(undefined);
          }
        }
      };

      fetchGeocode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return timeZoneId;
};

export default useGeocodeTimeZone;
