import React, { useEffect, useRef, useState } from 'react';
import { PanInfo, motion } from 'framer-motion';
import ReactDOM from 'react-dom';
import { useMediaQuery } from 'react-responsive';

type SheetProps = {
  children: React.ReactNode;
  className?: string;
  handleClose?: () => void;
  y?: number;
  dragConstraints?: { top?: number; bottom?: number };
  canDrag?: boolean;
  showShadow?: boolean;
};

const Sheet = ({
  children,
  className,
  handleClose,
  y,
  dragConstraints,
  showShadow = true,
  canDrag = true,
}: SheetProps) => {
  const sheetRef = useRef<HTMLDivElement>(null);

  // Use useMediaQuery to determine if the screen is small
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

  // Set isDraggable based on isSmallScreen
  const [isDraggable, setIsDraggable] = useState(isSmallScreen);

  // Update isDraggable whenever isSmallScreen changes
  useEffect(() => {
    setIsDraggable(isSmallScreen);
  }, [isSmallScreen]);

  useEffect(() => {
    if (sheetRef.current) {
      const newHeight = sheetRef.current.scrollHeight;
      sheetRef.current.style.maxHeight = `${newHeight}px`;
    }
  }, [children]);

  const handleDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
  ) => {
    if (info.velocity.y > 800 || info.offset.y > 98) {
      handleClose && handleClose();
    }
  };

  const handleDrag = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
  ) => {
    if (sheetRef.current && !isSmallScreen) {
      const newHeight = sheetRef.current.offsetHeight - info.delta.y;
      const minHeight =
        sheetRef.current.offsetHeight - (dragConstraints?.bottom || 60);
      const maxHeight =
        sheetRef.current.offsetHeight + (dragConstraints?.top || 20);

      if (newHeight <= maxHeight && newHeight >= minHeight) {
        sheetRef.current.style.maxHeight = `${newHeight}px`;
      }
    }
  };

  const sheet = (
    <motion.section
      ref={sheetRef}
      className={`${className} scrollbar-sheet bottom-0 left-0 flex w-full flex-col gap-3 rounded-t-[32px]  bg-white px-5 pb-8 pt-6 sm:overflow-y-auto ${
        isDraggable ? 'fixed cursor-grab' : 'absolute z-10'
      } ${showShadow ? 'drop-shadow-[0px_3px_13px_#707070]' : ''}`}
      drag={canDrag && 'y'}
      whileDrag={{ cursor: 'grabbing' }}
      dragConstraints={{
        top: dragConstraints?.top || 20,
        bottom: dragConstraints?.bottom || 60,
      }}
      dragElastic={{ top: 0.05, bottom: 0.6 }}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      initial={{
        y: '80dvh',
      }}
      animate={{
        y: !isDraggable ? 10 : y || 10,
        transition: { duration: 0.3 },
      }}
      exit='hidden'
    >
      {canDrag && (
        <div className='fixed top-2 h-[5px] w-10 self-center rounded-full bg-gray-3' />
      )}
      {children}
    </motion.section>
  );
  if (!isDraggable) return sheet;

  return ReactDOM.createPortal(
    sheet,
    document.getElementById('portal') || document.createElement('div'),
  );
};

export default Sheet;
