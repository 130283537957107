import React, { Suspense, lazy } from 'react';
import Auth from 'Auth';
import { Route, Routes } from 'react-router';
import { InstallPrompt, Wrapper } from 'ui';
import {
  SpecialistOrdersPage,
  CategoriesPage,
  AddServicePage,
  ErrorPage,
  FavoritePage,
  LocationPage,
  LoginPage,
  OrderDetailedPage,
  OrdersPage,
  PrivacyPage,
  ProfilePage,
  SchedulePage,
  SearchSpecialistsPage,
  ServicesPage,
  SpecialistServicesPage,
  SubCategoriesPage,
  SpecialistCreateOrderPage,
  SpecialistPortfolioPage,
  SpecialistOrderDetailedPage,
  AddCustomerPage,
  SpecialistCalendarPage,
  SupportPage,
} from 'pages';
import { useAppSelector } from 'hooks/useAppSelector';

const CreateOrderPage = lazy(() =>
  import('pages').then((module) => ({ default: module.CreateOrderPage })),
);
const CancelOrderPage = lazy(() =>
  import('pages').then((module) => ({ default: module.CancelOrderPage })),
);
const SpecialistSchedulePage = lazy(() =>
  import('pages').then((module) => ({
    default: module.SpecialistSchedulePage,
  })),
);
const ServicePage = lazy(() =>
  import('pages').then((module) => ({ default: module.ServicePage })),
);
const EditProfilePage = lazy(() =>
  import('pages').then((module) => ({ default: module.EditProfilePage })),
);
const ReviewsPage = lazy(() =>
  import('pages').then((module) => ({ default: module.ReviewsPage })),
);

const MapPage = lazy(() =>
  import('pages').then((module) => ({ default: module.MapPage })),
);

const BalancePage = lazy(() =>
  import('pages').then((module) => ({ default: module.BalancePage })),
);

const RegisterPage = lazy(() =>
  import('pages').then((module) => ({ default: module.RegisterPage })),
);

const AppRoutes = () => {
  const { isLoggedIn } = useAppSelector((state) => state.user);

  return (
    <>
      <Suspense fallback={<Wrapper children='' className='bg-white' />}>
        <Routes>
          <Route element={<Auth allowedRoles={['client']} />}>
            <Route path='/category' element={<CategoriesPage />} />
            <Route path='/category/:id' element={<SubCategoriesPage />} />
            <Route path='/services/:id' element={<ServicesPage />} />
            <Route path='/service/:id' element={<ServicePage />} />
            <Route path='/search' element={<SearchSpecialistsPage />} />
            <Route path='/schedule' element={<SchedulePage />} />
            <Route path='/orders' element={<OrdersPage />} />
            <Route path='/favorites' element={<FavoritePage />} />
            <Route path='/favorites' element={<FavoritePage />} />
            <Route path='/profile/edit' element={<EditProfilePage />} />
          </Route>
          <Route element={<Auth allowedRoles={['specialist']} />}>
            <Route path='/specialist/profile' element={<ProfilePage />} />
            <Route
              path='/specialist/profile/edit'
              element={<EditProfilePage />}
            />
            <Route
              path='/specialist/services/select'
              element={<CategoriesPage />}
            />
            <Route
              path='/specialist/services/select/:id'
              element={<SubCategoriesPage />}
            />
            <Route
              path='/specialist/services'
              element={<SpecialistServicesPage />}
            />
            <Route
              path='/specialist/services/add/:id'
              element={<AddServicePage />}
            />
            <Route
              path='/specialist/orders'
              element={<SpecialistOrdersPage />}
            />
            <Route
              path='/specialist/schedule'
              element={<SpecialistSchedulePage />}
            />
            <Route
              path='/specialist/calendar'
              element={<SpecialistCalendarPage />}
            />
            <Route
              path='/specialist/create-order'
              element={<SpecialistCreateOrderPage />}
            />
            <Route
              path='/specialist/orders/:id'
              element={<SpecialistOrderDetailedPage />}
            />
            <Route
              path='/specialist/orders/:id/cancel'
              element={<CancelOrderPage />}
            />
            <Route
              path='/specialist/add-customer'
              element={<AddCustomerPage />}
            />
            <Route path='/specialist/balance' element={<BalancePage />} />
          </Route>
          <Route path='/create-order' element={<CreateOrderPage />} />
          <Route path='/orders/:id' element={<OrderDetailedPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/login-client' element={<RegisterPage />} />
          <Route path='/reviews/:id' element={<ReviewsPage />} />
          <Route
            path='/specialist/portfolio/:id'
            element={<SpecialistPortfolioPage />}
          />
          <Route path='/map' element={<MapPage />} />
          <Route path='/orders/:id/cancel' element={<CancelOrderPage />} />
          {isLoggedIn && (
            <>
              <Route path='/location' element={<LocationPage />} />
              <Route path='/support' element={<SupportPage />} />
            </>
          )}
          <Route path='/privacy' element={<PrivacyPage />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </Suspense>
      <InstallPrompt />
    </>
  );
};

export default AppRoutes;
