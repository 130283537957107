import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from 'ui';

type WorkingDaysSelectorProps = {
  checkedState: {
    [key: string]: boolean;
  };
  setCheckedState: (value: { [key: string]: boolean }) => void;
  disabledDays: string[];
};

const translatedDaysOfWeek = (t: any) => [
  t('schedule.working_hours_monday'),
  t('schedule.working_hours_tuesday'),
  t('schedule.working_hours_wednesday'),
  t('schedule.working_hours_thursday'),
  t('schedule.working_hours_friday'),
  t('schedule.working_hours_saturday'),
  t('schedule.working_hours_sunday'),
];

const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const WorkingDaysSelector = ({
  checkedState,
  setCheckedState,
  disabledDays,
}: WorkingDaysSelectorProps) => {
  const { t } = useTranslation();
  const handleCheckAll = () => {
    const newCheckedState = daysOfWeek.reduce(
      (initial, day) => ({
        ...initial,
        [day]: !disabledDays.includes(day),
      }),
      {},
    );
    setCheckedState(newCheckedState);
  };

  const handleUncheckAll = () => {
    const newCheckedState = daysOfWeek.reduce(
      (initial, day) => ({ ...initial, [day]: false }),
      {},
    );
    setCheckedState(newCheckedState);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    day: string,
  ) => {
    setCheckedState({ ...checkedState, [day]: event.target.checked });
  };

  return (
    <>
      <div className='flex justify-evenly'>
        <Button
          variant='yellowStroke'
          onClick={handleCheckAll}
          size='sm'
          className='mt-3 px-5'
        >
          {t('schedule.working_hours_all')}
        </Button>
        <Button
          variant='yellowStroke'
          onClick={handleUncheckAll}
          size='sm'
          className='mt-3 px-5'
        >
          {t('schedule.working_hours_none')}
        </Button>
      </div>
      <div className='mt-3 grid grid-cols-2 gap-2'>
        {daysOfWeek.map((day, index) => (
          <Checkbox
            key={day}
            children={translatedDaysOfWeek(t)[index]}
            checked={checkedState[day]}
            onChange={(event) => handleCheckboxChange(event, day)}
            disabled={disabledDays.includes(day)}
          />
        ))}
      </div>
    </>
  );
};

export default WorkingDaysSelector;
