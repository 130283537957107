import React from 'react';
import AndriyAvatar from 'images/Andrii.png';
import MarinaAvatar from 'images/Marina.png';
import TanyaAvatar from 'images/Tany.png';

const avatars = [
  { src: TanyaAvatar, alt: 'Tanya Avatar' },
  { src: AndriyAvatar, alt: 'Andrii Avatar' },
  { src: MarinaAvatar, alt: 'Marina Avatar' },
];

const TeamAvatars = () => {
  return (
    <div className='flex justify-start sm:justify-center lg:justify-start'>
      {avatars.map((avatar, index) => (
        <img
          key={index}
          className={`aspect-square rounded-[50%] object-cover shadow-lg lg:h-[95px] lg:w-[95px] ${
            index !== 0 ? '-ml-3' : ''
          }`}
          style={{ zIndex: avatars.length - index }}
          width={66}
          height={66}
          loading='lazy'
          src={avatar.src}
          alt={avatar.alt}
        />
      ))}
    </div>
  );
};

export default TeamAvatars;
