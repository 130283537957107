import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { PanInfo, motion, useAnimation } from 'framer-motion';
import { IoIosArrowBack, IoIosArrowForward, IoIosClose } from 'react-icons/io';
import { isVideo } from 'utils/validations';

type GalleryImage = {
  galleryImages: string[];
  galleryStartIndex: number;
  setShowGallery: (value: boolean) => void;
};

const ImageGallery = ({
  galleryImages,
  galleryStartIndex,
  setShowGallery,
}: GalleryImage) => {
  const [currentIndex, setCurrentIndex] = useState(galleryStartIndex);
  const controls = useAnimation();

  const goToPrevious = useCallback(async () => {
    await controls.start({ opacity: 0, transition: { duration: 0.2 } });
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1,
    );
    controls.start({ opacity: 1, transition: { duration: 0.2 } });
  }, [controls, galleryImages.length]);

  const goToNext = useCallback(async () => {
    await controls.start({ opacity: 0, transition: { duration: 0.2 } });
    setCurrentIndex((prevIndex) =>
      prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1,
    );
    controls.start({ opacity: 1, transition: { duration: 0.2 } });
  }, [controls, galleryImages.length]);

  const handleDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
  ) => {
    if (info.velocity.x > 1000) {
      goToPrevious();
    } else if (info.velocity.x < -1000) {
      goToNext();
    } else if (info.offset.x > 100) {
      goToPrevious();
    } else if (info.offset.x < -100) {
      goToNext();
    }
  };

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowGallery(false);
      } else if (event.key === 'ArrowLeft') {
        goToPrevious();
      } else if (event.key === 'ArrowRight') {
        goToNext();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    document.body.classList.add('overflow-hidden');

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.classList.remove('overflow-hidden');
    };
  }, [setShowGallery, goToNext, goToPrevious]);

  return ReactDOM.createPortal(
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-6 backdrop-blur-md'>
      {isVideo(galleryImages[currentIndex]) ? (
        <motion.video
          animate={controls}
          src={galleryImages[currentIndex]}
          initial={{ opacity: 0.9 }}
          transition={{ duration: 0.1 }}
          drag='x'
          dragElastic={0.25}
          dragMomentum={false}
          dragSnapToOrigin
          dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0 }}
          onDragEnd={handleDragEnd}
          className='absolute h-auto max-h-full w-auto max-w-full select-none py-5'
          controls
          controlsList='nodownload noplaybackrate'
          disablePictureInPicture
        />
      ) : (
        <motion.img
          animate={controls}
          src={galleryImages[currentIndex]}
          alt='spec gallery'
          initial={{ opacity: 0.9 }}
          transition={{ duration: 0.1 }}
          drag='x'
          dragElastic={0.25}
          dragMomentum={false}
          dragSnapToOrigin
          dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0 }}
          onDragEnd={handleDragEnd}
          className='absolute h-auto max-h-full w-auto max-w-full py-5 '
        />
      )}
      <div className='absolute right-5 top-5 z-50'>
        <IoIosClose
          size={50}
          onClick={() => setShowGallery(false)}
          className='cursor-pointer text-white'
          aria-label='Close gallery'
        />
      </div>
      <div className='absolute bottom-[10vh] z-50'>
        <div className='flex items-center '>
          <IoIosArrowBack
            size={30}
            onClick={goToPrevious}
            className='cursor-pointer text-white'
            aria-label='Previous image'
          />
          <div className='mx-2.5 select-none text-white'>
            {currentIndex + 1} / {galleryImages.length}
          </div>
          <IoIosArrowForward
            size={30}
            onClick={goToNext}
            className='cursor-pointer text-white'
            aria-label='Next image'
          />
        </div>
      </div>
    </div>,
    document.getElementById('portal') as Element,
  );
};

export default ImageGallery;
