import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useApiRequest } from 'hooks/useApiRequest';
import {
  Button,
  ExpandedSpecialistStats,
  ReviewOrderCard,
  SectionHeader,
  SpecialistAvatar,
  Spinner,
  Wrapper,
} from 'ui';
import {
  BASE_URL,
  addFavoriteSpecialist,
  addReview,
  removeFavoriteSpecialist,
} from 'api/api';
import { useAppSelector } from 'hooks/useAppSelector';
import { convertMsToMin } from 'utils/time';
import toast from 'react-hot-toast';
import LeaveReviewModal from 'modals/LeaveReviewModal';
import ReactDOM from 'react-dom';
import { OrderFullInfo } from 'react-app-env';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { toggleModal } from 'redux/modalSlice';
import { formatCurrency } from 'utils/validations';
import { useTranslation } from 'react-i18next';
import useGeocodeTimeZone from 'hooks/useGeocodeTimeZonet';
import { setCoordinates } from 'redux/mapSlice';

const OrderDetailedPage = () => {
  const { t } = useTranslation();
  const { uid } = useAppSelector((state) => state.user);
  const { pathname } = useLocation();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [isFavouriteState, setIsFavouriteState] = useState(false);
  const [comment, setComment] = useState('');
  const [orderInfo, setOrderInfo] = useState<OrderFullInfo | null>(null);
  const [isReviewSent, setIsReviewSent] = useState(false);
  const [starRating, setStarRating] = useState(0);
  const [reviewsCount, setReviewsCount] = useState(0);
  const [averageRating, setAverageRating] = useState(0);

  const { data: orderInfoFetched, loading } = useApiRequest<OrderFullInfo>({
    endpoint: `${BASE_URL}orders/${id}`,
  });

  const { data: isReviewSentFetched } = useApiRequest<boolean>({
    endpoint: `${BASE_URL}clients/${uid}/isReviewForOrder/${id}`,
    destructuringDataType: 'isReview',
  });

  useEffect(() => {
    setOrderInfo(orderInfoFetched);
    if (orderInfoFetched) {
      dispatch(
        setCoordinates({
          lat: orderInfoFetched?.lat,
          lng: orderInfoFetched?.lon,
        }),
      );
    }
  }, [orderInfoFetched, dispatch]);

  const {
    specFullName,
    picsCnt,
    price,
    address,
    duration,
    timeOfOrder,
    stringId: orderId,
    photoURL,
    stringSpecId,
    isFavourite,
    serviceName,
    currency,
  } = orderInfo || {
    specFullName: '',
    picsCnt: 0,
    price: 0,
    address: '',
    duration: 0,
    timeOfOrder: 0,
    stringId: '',
    photoURL: '',
    stringSpecId: '',
    isFavourite: false,
    serviceName: '',
    currency: 'USD',
  };

  const formattedPrice = formatCurrency(currency, price);
  const isFinishedOrder = timeOfOrder + duration < Date.now();

  useEffect(() => {
    if (orderInfo) {
      setReviewsCount(orderInfo.specReviewsCnt);
      setAverageRating(orderInfo.rating);
    }
  }, [orderInfo]);

  useEffect(() => {
    if (isReviewSentFetched) {
      setIsReviewSent(isReviewSentFetched);
    }
  }, [isReviewSentFetched]);

  const handleToggleLikeSpecialist = async () => {
    if (isFavouriteState) {
      setIsFavouriteState(false);
      await removeFavoriteSpecialist(uid, stringSpecId);
    } else {
      setIsFavouriteState(true);
      await addFavoriteSpecialist(uid, stringSpecId);
    }
  };

  const handleAddReview = async () => {
    const response = await addReview(stringSpecId, {
      clientId: uid,
      rating: starRating,
      comment,
      orderId,
    });
    if (response) {
      setReviewsCount(reviewsCount + 1);
      setAverageRating(
        parseFloat(
          (
            (averageRating * reviewsCount + starRating) /
            (reviewsCount + 1)
          ).toFixed(1),
        ),
      );

      setIsReviewSent(true);
      dispatch(toggleModal({ modalType: 'clientLeaveReview' }));
      toast.success(t('order.review_added'));
    }
  };

  const timeZoneId = useGeocodeTimeZone({ address });
  const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en-US';
  const orderDate = new Date(timeOfOrder).toLocaleString(selectedLanguage, {
    timeZone: timeZoneId,
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  useEffect(() => {
    setIsFavouriteState(isFavourite);
  }, [isFavourite]);

  return (
    <Wrapper flexColumnSpaceBetween className={`items-start gap-5`}>
      <div className='w-full'>
        <SectionHeader
          title={
            !loading ? (
              <>
                {specFullName} - {orderInfo?.serviceName} <br /> {orderDate}
              </>
            ) : undefined
          }
        />
        {loading && (
          <div className='mx-auto mt-[20%] w-[50px]'>
            <Spinner />
          </div>
        )}
      </div>
      {!loading &&
        (isFinishedOrder && !isReviewSent ? (
          <ReviewOrderCard
            handleAddReview={handleAddReview}
            starRating={starRating}
            specFullName={specFullName}
            setComment={setComment}
            photoURL={photoURL}
            isFavouriteState={isFavouriteState}
            handleToggleLikeSpecialist={handleToggleLikeSpecialist}
            comment={comment}
            timeOfOrder={timeOfOrder}
            serviceName={serviceName}
            setStarRating={setStarRating}
          />
        ) : (
          <>
            <SpecialistAvatar
              size={124}
              src={photoURL}
              isFavourite={isFavouriteState}
              handleLikeSpecialist={handleToggleLikeSpecialist}
            />

            <ExpandedSpecialistStats
              location={address}
              rank={averageRating}
              duration={convertMsToMin(duration)}
              imagesAmount={picsCnt}
              reviewsUid={stringSpecId}
              portfolioUid={stringSpecId}
              reviewsNumber={reviewsCount}
              showLeaveReview={!isReviewSent}
              ableNavigateToMap={!isFinishedOrder}
            />
            <div className='flex w-full justify-between'>
              <p className='text-primary'>{t('order.total_price')}</p>
              <p className='text-primary'>{formattedPrice}</p>
            </div>
            {!isFinishedOrder && (
              <>
                <div className='flex w-full flex-col gap-5'>
                  <Button
                    variant='yellow'
                    disabled
                    className='h-[63px] w-full py-4'
                  >
                    {t('order.pay_online')}
                  </Button>
                  <div className='flex w-full justify-between gap-3'>
                    <Link
                      className='flex w-full items-stretch'
                      to={`${pathname}/cancel`}
                      state={orderId}
                    >
                      <Button className='w-full !whitespace-normal py-4'>
                        {t('cancel_order.name')}
                      </Button>
                    </Link>
                    <Link className='flex w-full items-stretch' to='/orders'>
                      <Button className='w-full !whitespace-normal py-4'>
                        {t('menu.my_orders')}
                      </Button>
                    </Link>
                  </div>
                </div>
                {ReactDOM.createPortal(
                  <LeaveReviewModal>
                    <ReviewOrderCard
                      handleAddReview={handleAddReview}
                      starRating={starRating}
                      specFullName={specFullName}
                      setComment={setComment}
                      photoURL={photoURL}
                      isFavouriteState={isFavouriteState}
                      handleToggleLikeSpecialist={handleToggleLikeSpecialist}
                      comment={comment}
                      timeOfOrder={timeOfOrder}
                      serviceName={serviceName}
                      setStarRating={setStarRating}
                    />
                  </LeaveReviewModal>,
                  document.getElementById('root') as Element,
                )}
              </>
            )}
          </>
        ))}
    </Wrapper>
  );
};

export default OrderDetailedPage;
