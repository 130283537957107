import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Service, SpecialistProfileDto } from 'react-app-env';

type CreateOrderPageState = {
  dateStamp: number | null;
  month: number;
  customerName: string;
  chosenServices: Service[];
  isOpenSelectCustomer: boolean;
  showSheetOfServices: boolean;
  isCalendarOpen: boolean;
  phoneNumber: string;
  clientId: string;
  isTimeSelected: boolean;
  orderDto: {
    clientId: string;
    specialistId: string;
    price: number;
    serviceId: string;
    duration: number;
    ts: number;
    role: 'client' | 'specialist' | 'guest';
  } | null;
  specialist: SpecialistProfileDto;
};

const initialState: CreateOrderPageState = {
  dateStamp: null,
  customerName: '',
  month: new Date().getMonth() + 1,
  chosenServices: [],
  isOpenSelectCustomer: false,
  showSheetOfServices: false,
  isCalendarOpen: false,
  phoneNumber: '',
  clientId: '',
  isTimeSelected: false,
  orderDto: null,
  specialist: {
    address: '',
    createdAt: 0,
    firstName: '',
    id: 0,
    lastName: '',
    picsCnt: 0,
    profilePhoto: '',
    rating: 0,
    since: 0,
    stringId: '',
    reviewsCnt: 0,
    descr: '',
    insta: '',
  },
};

const createOrderPageSlice = createSlice({
  name: 'createOrderPage',
  initialState,
  reducers: {
    setDateStamp: (state, { payload }: PayloadAction<number>) => {
      state.dateStamp = payload;
    },
    setCustomerName: (state, { payload }: PayloadAction<string>) => {
      state.customerName = payload;
    },
    addChosenService: (state, { payload }: PayloadAction<Service>) => {
      state.chosenServices.push(payload);
    },
    removeChosenService: (state, { payload }: PayloadAction<Service['id']>) => {
      state.chosenServices = state.chosenServices.filter(
        (service) => service.id !== payload,
      );
    },
    setChoosenServices: (state, { payload }: PayloadAction<Service[]>) => {
      state.chosenServices = payload;
    },
    clearChosenServices: (state) => {
      state.chosenServices = [];
    },
    setIsOpenSelectCustomer: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenSelectCustomer = payload;
    },
    setShowSheetOfServices: (state, { payload }: PayloadAction<boolean>) => {
      state.showSheetOfServices = payload;
    },
    setIsCalendarOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isCalendarOpen = payload;
    },
    setPhoneNumber: (state, { payload }: PayloadAction<string>) => {
      state.phoneNumber = payload;
    },
    setMonth: (state, { payload }: PayloadAction<number>) => {
      state.month = payload;
    },
    setSpecialistDto: (
      state,
      { payload }: PayloadAction<SpecialistProfileDto>,
    ) => {
      state.specialist = payload;
    },
    addCustomer: (
      state,
      {
        payload,
      }: PayloadAction<{
        name: string;
        surname: string;
        phoneNumber: string;
        clientId: string;
      }>,
    ) => {
      state.customerName = `${payload.name} ${payload.surname}`;
      state.phoneNumber = payload.phoneNumber;
      state.clientId = payload.clientId;
    },
    setIsTimeSelected: (state, { payload }: PayloadAction<boolean>) => {
      state.isTimeSelected = payload;
    },

    setOrderDto: (
      state,
      { payload }: PayloadAction<CreateOrderPageState['orderDto']>,
    ) => {
      state.orderDto = payload;
    },

    resetState: () => initialState,
  },
});

export const {
  setDateStamp,
  setCustomerName,
  addChosenService,
  removeChosenService,
  clearChosenServices,
  setIsOpenSelectCustomer,
  setShowSheetOfServices,
  setIsCalendarOpen,
  setPhoneNumber,
  addCustomer,
  setChoosenServices,
  resetState,
  setIsTimeSelected,
  setOrderDto,
  setMonth,
  setSpecialistDto,
} = createOrderPageSlice.actions;

export default createOrderPageSlice.reducer;
