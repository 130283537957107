import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type EmailType = 'INVESTOR' | 'GET_DEMO' | 'GET_PITCH';

interface EmailTypeState {
  value: EmailType;
}

const initialState: EmailTypeState = {
  value: 'INVESTOR',
};

export const emailTypeSlice = createSlice({
  name: 'emailType',
  initialState,
  reducers: {
    setFormInvestorType: (state, action: PayloadAction<EmailType>) => {
      state.value = action.payload;
    },
  },
});

export const { setFormInvestorType } = emailTypeSlice.actions;

export default emailTypeSlice.reducer;
