import React from 'react';
import { motion } from 'framer-motion';
import { FaChevronRight } from 'react-icons/fa6';

type SectionLabelProps = {
  enable: boolean;
  icon: string;
  onClick: () => void;
  title: string;
  description: string;
};

const SectionLabel = ({
  title,
  description,
  icon,
  onClick,
  enable,
}: SectionLabelProps) => {
  return (
    <li
      className='flex cursor-pointer items-center justify-between border-b border-gray-3 px-5 py-6 hover:bg-gray-50'
      onClick={onClick}
    >
      <div className='flex w-full items-center'>
        <img
          src={icon}
          alt='service icon'
          className='mr-1 xs:hidden'
          width={20}
          height={20}
        />

        <p className='text-sm text-secondary'>{title}</p>
        <p className='mx-auto text-sm text-gray-2'>{description}</p>
      </div>
      <motion.div animate={{ rotate: enable ? 90 : 0 }}>
        <FaChevronRight className='text-secondary' />
      </motion.div>
    </li>
  );
};

export default SectionLabel;
