import React, { useState } from 'react';
import { useApiRequest } from 'hooks/useApiRequest';
import { Button, Checkbox, Wrapper } from 'ui';
import { GoBackButton } from 'ui';
import { useNavigate } from 'react-router';
import { BASE_URL } from 'api/api';
import { useTranslation } from 'react-i18next';

const PrivacyPage = () => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const { loading, data } = useApiRequest({
    endpoint: `${BASE_URL}splashScreen/termsAndPrivacyPolicy?locale=${localStorage.getItem(
      'selectedLanguage',
    )}`,
    destructuringDataType: 'body',
    deps: false,
  });

  return (
    <Wrapper>
      <GoBackButton />
      <h4 className='mb-4 text-xl font-medium text-primary'>
        Terms and Privacy Policy <span className='text-accent'>GotoU</span>
      </h4>
      <div className='overflow-y-auto sm:max-h-[550px]'>
        {!loading ? (
          typeof data === 'string' &&
          data?.split('\n').map((paragraph: string, i: number) => (
            <p className='mt-2 text-secondary' key={i}>
              {paragraph}
            </p>
          ))
        ) : (
          <p className='mt-2 text-center text-secondary'>
            {t('message.loading')}
          </p>
        )}
      </div>
      {!loading && (
        <div className='mt-2 flex w-full flex-col gap-2'>
          <Checkbox checked={checked} onChange={() => setChecked(!checked)}>
            {t('agree_terms')}
          </Checkbox>
          <Button
            variant='yellow'
            disabled={!checked}
            onClick={() => navigate(-1)}
            className='py-4'
          >
            {t('accept')}
          </Button>
        </div>
      )}
    </Wrapper>
  );
};

export default PrivacyPage;
