import React from 'react';
import RegisterForm from 'components/client/register/RegisterForm';
import { useTranslation } from 'react-i18next';
import { SectionHeader, Wrapper } from 'ui';
import { verifyCodeForOrder } from 'api/login';
import { AppDispatch } from 'store';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'hooks/useAppSelector';
import { createOrder } from 'api/api';
import { resetState } from 'redux/createOrderPageSlice';
import toast from 'react-hot-toast';

const RegisterPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orderDto } = useAppSelector((state) => state.createOrderPage);

  const handleLoginUser = async (
    codeSMS: string,
    setIsLoading: (value: boolean) => void,
    data: {
      uid: string;
      phoneNumber: string;
      role: 'client' | 'specialist';
      codeSMS: string;
      dispatch: AppDispatch;
      setIsValid: (boolean: boolean) => void;
      firstName: string;
      lastName: string;
    },
  ) => {
    setIsLoading(true);
    await verifyCodeForOrder(
      data.uid,
      data.phoneNumber,
      data.role,
      codeSMS,
      data.dispatch,
      data.setIsValid,
      data.firstName,
      data.lastName,
    );

    if (orderDto) {
      const response = await createOrder(
        { ...orderDto, clientId: data.uid },
        t,
      );
      if (response?.orderIdStr) {
        dispatch(resetState());
        navigate(`/orders/${response.orderIdStr}`);
      } else {
        toast.error(t('something_wrong'));
      }
    } else {
      navigate(`/create-order`);
    }
    setIsLoading(false);
  };

  return (
    <Wrapper className='space-y-10'>
      <SectionHeader title={t('log_in.name')} showGoBackButton />
      <RegisterForm handleLoginUser={handleLoginUser} />
    </Wrapper>
  );
};

export default RegisterPage;
