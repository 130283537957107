import { Currency } from 'react-app-env';

export const validatePhoneNumber = (number: string) => {
  // Country calling codes
  const codes = {
    ch: '41', // Switzerland
    ua: '380', // Ukraine
    pt: '351', // Portugal
    pl: '48', // Poland
    us: '1', // United States
    de: '49', // Germany
    gb: '44', // United Kingdom
    th: '66', // Thailand
    qa: '974', // Qatar
    il: '972', // Israel
    au: '61', // Australia
    it: '39', // Italy
    es: '34', // Spain
    fr: '33', // France
    ro: '40', // Romania
    ee: '372', // Estonia
    mx: '52', // Mexico
    at: '43', // Austria
    id: '62', // Indonesia
    ca: '1', // Canada
  };

  // Create a regex from the country calling codes
  const regex = new RegExp(
    '^\\+(' + Object.values(codes).join('|') + ')(\\s?\\d){8,10}\\d$',
  );

  return regex.test(number);
};

export const validateEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const formatCurrency = (currency: Currency, price: number) => {
  return new Intl.NumberFormat([], {
    style: 'currency',
    currency: currency ? currency : 'USD',
  }).format(price);
};

export const isVideo = (url: string) => {
  return ['.mp4', '.mov', '.avi'].some((extension) => url.endsWith(extension));
};
