import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button } from 'ui';

type NoCategoryFoundProps = {
  textMessage: string;
};

const NoCategoryFound = ({ textMessage }: NoCategoryFoundProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <p>{textMessage}</p>
      <Button
        className='my-2 py-3 '
        variant='primary'
        onClick={() => navigate(-1)}
      >
        {t('go_back')}
      </Button>
    </>
  );
};

export default NoCategoryFound;
