import React, { useId } from 'react';

type RadioProps = {
  children?: string;
  name: string;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  radioClassName?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const Radio = ({
  children,
  name,
  checked = false,
  disabled = false,
  required,
  onChange,
  radioClassName,
}: RadioProps) => {
  const id = useId();

  return (
    <div className='mb-4 flex items-center'>
      <input
        required={required}
        id={id}
        type='radio'
        name={name}
        value={children}
        defaultChecked={checked}
        disabled={disabled}
        onChange={onChange}
        className={radioClassName}
      />
      <label htmlFor={id} className='ms-2'>
        {children}
      </label>
    </div>
  );
};

export default Radio;
