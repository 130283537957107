import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency } from 'react-app-env';

export const SupportedLanguages = ['en', 'pl', 'de', 'ua'] as const;

type Specialsist = {
  currency: Currency;
  referallCode: string | null;
  isTrialSubscribed: boolean;
  instagramLink: string;
  trialEndDate: number | null;
  acceptedTerms: boolean;
  description: string;
};

const initialState: Specialsist = {
  currency: 'USD',
  referallCode: null,
  trialEndDate: null,
  instagramLink: '',
  isTrialSubscribed: false,
  acceptedTerms: false,
  description: '',
};

// TODO - make same state for client and specialist. Refactor actions
const specialistSlice = createSlice({
  name: 'specialist',
  initialState,
  reducers: {
    setIsTrialSubscribed(state, { payload }: PayloadAction<boolean>) {
      state.isTrialSubscribed = payload;
    },
    setTrialEndDate(state, { payload }: PayloadAction<number | null>) {
      state.trialEndDate = payload;
    },
    setAcceptedTerms(state, { payload }: PayloadAction<boolean>) {
      state.acceptedTerms = payload;
    },
    setInstagramLink(state, { payload }: PayloadAction<string>) {
      state.instagramLink = payload;
    },
    setSpecDescription(state, { payload }: PayloadAction<string>) {
      state.description = payload;
    },
    clearSpecialistState: () => initialState,
  },
});

export const {
  setTrialEndDate,
  setIsTrialSubscribed,
  setAcceptedTerms,
  setSpecDescription,
  setInstagramLink,
  clearSpecialistState,
} = specialistSlice.actions;

export default specialistSlice.reducer;
