import React, { useState } from 'react';
import LogoSad from 'images/icons/logo-sad.svg';
import { Button, ModalWrapper } from 'ui';
import { useApiRequest } from 'hooks/useApiRequest';
import { BASE_URL } from 'api/api';
import { flag } from 'country-emoji';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setUserLocation } from 'redux/userSlice';
import { withModalHandling } from './withModalHandling';
import { useAppSelector } from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type LocationPayload = {
  city: string;
  country: string;
};

const LeaveReviewModal = ({
  handleCloseModal,
}: {
  handleCloseModal: () => void;
}) => {
  const { t } = useTranslation();
  const { active } = useAppSelector((state) => state.modals.noSpecInLocation);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [location, setLocation] = useState<LocationPayload>({
    city: '',
    country: '',
  });

  const { loading, data: avaliableLocations } = useApiRequest<
    LocationPayload[]
  >({
    endpoint: active ? `${BASE_URL}address/findOurLocations` : null,
    destructuringDataType: 'dtos',
  });

  const handleSelectNewLocation = (location: LocationPayload) => {
    setLocation(location);
  };

  const handleCheckCity = () => {
    dispatch(
      setUserLocation({
        ...location,
        coordinates: { lat: 0, lng: 0 },
        address: `${location.country}, ${location.city}`,
      }),
    );
    navigate('/location');
    handleCloseModal();
  };

  return (
    <ModalWrapper modalId='noSpecInLocation'>
      <div className='mb-4 flex flex-col items-center gap-2'>
        <img src={LogoSad} alt='logo-sad' width={37} height={37} />
        <h2 className='text-center'>{t('we_have_not_found_specialists')}</h2>
      </div>
      <div className=' flex flex-col items-center gap-2'>
        {avaliableLocations && avaliableLocations.length > 0 && (
          <h3>{t('we_already_in')}</h3>
        )}
        <p className='text-center text-sm text-secondary'>
          {t('we_will_notify_you')}
        </p>
      </div>
      <div className='flex max-h-40 w-full flex-col gap-1 overflow-y-auto pr-2'>
        {!loading &&
          avaliableLocations &&
          avaliableLocations?.length > 0 &&
          avaliableLocations?.map((locationItem, i) => (
            <Button
              variant='grayStroke'
              key={locationItem.city + i}
              onClick={() => handleSelectNewLocation(locationItem)}
              className={`inline-flex w-full items-center gap-2 ${
                location.city === locationItem.city
                  ? '!border-accent'
                  : 'border-white'
              } text-left`}
            >
              {flag(locationItem.country)}
              <span className='text-sm'>
                {locationItem.city} ({locationItem.country})
              </span>
            </Button>
          ))}
      </div>
      {avaliableLocations && avaliableLocations.length > 0 ? (
        <Button
          variant='yellow'
          disabled={!location.city}
          className='w-full py-4 sm:w-3/4'
          onClick={handleCheckCity}
        >
          {t('check_city')}
        </Button>
      ) : (
        <Button variant='yellow' className='w-full' onClick={handleCloseModal}>
          {t('close')}
        </Button>
      )}
    </ModalWrapper>
  );
};

export default withModalHandling(LeaveReviewModal, 'noSpecInLocation');
