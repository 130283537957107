import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useNavigate } from 'react-router';
import { Button, SpecialistAvatar, ToggleChevron } from 'ui';
import { useAppSelector } from 'hooks/useAppSelector';
import { AnimatePresence, motion } from 'framer-motion';
import ProfileAvatarLink from './ProfileAvatarLink';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

type ProfileButtonProps = {
  handleLogOut: () => void;
};

const SpecialistLinks = (t: TFunction<'translation', undefined>) => (
  <>
    <ProfileAvatarLink
      text={t('menu.my_services')}
      link='/specialist/services'
    />
    <ProfileAvatarLink text={t('menu.orders')} link='/specialist/orders' />
    <ProfileAvatarLink
      text={t('profile.create_order')}
      link='/specialist/create-order'
    />
    <ProfileAvatarLink text={t('menu.calendar')} link='/specialist/calendar' />
    <ProfileAvatarLink text={t('menu.schedule')} link='/specialist/schedule' />
  </>
);

const ClientLinks = (t: TFunction<'translation', undefined>) => (
  <>
    <ProfileAvatarLink text={t('profile.create_order')} link='/category' />
    <ProfileAvatarLink text={t('menu.my_orders')} link='/orders' />
    <ProfileAvatarLink text={t('menu.my_favorites')} link='/favorites' />
    <ProfileAvatarLink text={t('menu.edit_profile')} link='/profile/edit' />
  </>
);

const ProfileButton = ({ handleLogOut }: ProfileButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { role, firstName, profilePhoto } = useAppSelector(
    (state) => state.user,
  );

  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='relative inline-block' ref={ref}>
      <Button
        className='inline-flex items-center gap-2 px-7'
        variant='yellow'
        onClick={toggleDropdown}
      >
        {t('header.account')}
        <ToggleChevron size={18} isOpen={isOpen} />
      </Button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0.3 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.1 }}
            className='absolute right-0 z-30 mt-2 origin-top-right text-left shadow-xl'
          >
            <div className='w-64 rounded-lg bg-white' onClick={toggleDropdown}>
              <div
                className='flex cursor-pointer items-center px-6 py-2'
                onClick={() =>
                  navigate(
                    role === 'specialist' ? '/specialist/profile' : '/category',
                  )
                }
              >
                <SpecialistAvatar
                  showLikeButton={false}
                  src={profilePhoto}
                  size={40}
                />
                <div className='ml-4'>
                  <p className='font-semibold leading-none text-gray-900'>
                    {firstName || 'User Profile'}
                  </p>
                </div>
              </div>
              <ul className='border-t-2 border-gray-200 py-1'>
                {role === 'specialist' && SpecialistLinks(t)}
                {role === 'client' && ClientLinks(t)}
                <ProfileAvatarLink text={t('menu.support')} link='/support' />
                <ProfileAvatarLink text={t('menu.about_gotou')} link='/' />
              </ul>
              <div className='border-t-2 border-gray-200 py-1'>
                <button
                  className='block w-full px-6 py-3 text-left leading-tight hover:bg-gray-200'
                  onClick={handleLogOut}
                >
                  {t('menu.logout')}
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProfileButton;
