import React from 'react';
import { SectionHeader, ToggleChevron } from 'ui';
import WorkTimeSelector from './WorkTimeSelector';
import WorkingDaysSelector from './WorkingDaysSelector';
import CalendarIcon from 'images/emoji/(Calendar).svg';
import WorkTimeIcon from 'images/emoji/Hours.svg';
import WeekendItemIcon from 'images/emoji/Weekend.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

type CheckedState = {
  [key: string]: boolean;
};

type HoursFormProps = {
  type: 'work' | 'weekend';
  handleToggleHours: (type: 'work' | 'weekend') => void;
  isOpenHours: { work: boolean; weekend: boolean };
  handleAddTimeSlot: () => void;
  timeSlots: { start: string; end: string }[];
  setTimeSlots: React.Dispatch<
    React.SetStateAction<{ start: string; end: string }[]>
  >;
  checkedState: CheckedState;
  checkedStateWeekend: CheckedState;
  setCheckedState: React.Dispatch<React.SetStateAction<CheckedState>>;
  setCheckedStateWeekend: React.Dispatch<React.SetStateAction<CheckedState>>;
  handleUpdateTimeSlot: (
    index: number,
    updatedSlot: { start: string; end: string },
    setPassedTimeSlots: React.Dispatch<
      React.SetStateAction<
        {
          start: string;
          end: string;
        }[]
      >
    >,
  ) => void;
};

const HoursForm = ({
  type,
  handleToggleHours,
  isOpenHours,
  handleAddTimeSlot,
  timeSlots,
  setTimeSlots,
  checkedState,
  checkedStateWeekend,
  setCheckedState,
  setCheckedStateWeekend,
  handleUpdateTimeSlot,
}: HoursFormProps) => {
  const { t } = useTranslation();

  const config = {
    work: {
      title: t('schedule.working_hours'),
      timeIcon: WorkTimeIcon,
      isOpen: isOpenHours.work,
      checkedStateCurrent: checkedState,
      setCheckedStateCurrent: setCheckedState,
      checkedStateOther: checkedStateWeekend,
    },
    weekend: {
      title: t('schedule.weekend_hours'),
      timeIcon: WeekendItemIcon,
      isOpen: isOpenHours.weekend,
      checkedStateCurrent: checkedStateWeekend,
      setCheckedStateCurrent: setCheckedStateWeekend,
      checkedStateOther: checkedState,
    },
  };

  const {
    title,
    timeIcon,
    isOpen,
    checkedStateCurrent,
    setCheckedStateCurrent,
    checkedStateOther,
  } = config[type];

  const disabledDays = Object.keys(checkedStateOther).filter(
    (day) => checkedStateOther[day],
  );

  const handleSetCheckedState = (newState: CheckedState) => {
    const isOverlap = Object.keys(newState).some(
      (day) => checkedStateOther[day] && newState[day],
    );
    if (!isOverlap) {
      setCheckedStateCurrent(newState);
    }
  };

  const handleRemoveTimeSlot = (index: number) => {
    setTimeSlots(timeSlots.filter((_, i) => i !== index));
  };

  return (
    <form
      className='border-secondry flex flex-col gap-4 rounded-2xl border-2 p-3'
      id={`${type}hours`}
    >
      <div>
        <div
          className='flex cursor-pointer items-center'
          onClick={() => handleToggleHours(type)}
        >
          <img
            src={timeIcon}
            alt='Time icon'
            width={16}
            height={16}
            className='mr-2'
          />
          <SectionHeader
            title={title}
            subtitle={t('schedule.working_hours_text_1')}
            wrapperClassName='text-sm smMax:!ml-0'
            titleClassName='text-lg font-normal'
            showGoBackButton={false}
          />
          <ToggleChevron isOpen={isOpen} />
        </div>
        {isOpen && (
          <>
            <AnimatePresence>
              {timeSlots?.map((slot, index) => (
                <WorkTimeSelector
                  key={index}
                  timeSlotIndex={index}
                  workEndTime={slot.end}
                  workStartTime={slot.start}
                  removeTimeSlot={handleRemoveTimeSlot}
                  showDeleteIcon={timeSlots.length > 1}
                  setWorkStartTime={(start) =>
                    handleUpdateTimeSlot(
                      index,
                      { ...slot, start },
                      setTimeSlots,
                    )
                  }
                  setWorkEndTime={(end) =>
                    handleUpdateTimeSlot(index, { ...slot, end }, setTimeSlots)
                  }
                />
              ))}
            </AnimatePresence>

            {timeSlots.length < 3 && (
              <div className='mt-3 flex justify-between gap-3'>
                <p
                  className='ml-3 cursor-pointer border-b-2  border-primary text-primary transition-all  hover:border-accent  '
                  onClick={handleAddTimeSlot}
                >
                  {t('schedule.working_hours_add_timeslot')}
                </p>
              </div>
            )}
          </>
        )}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ opacity: { duration: 0.1 } }}
          >
            <div className='flex'>
              <img
                src={CalendarIcon}
                alt='Calendar icon'
                width={16}
                height={16}
                className='mr-2'
              />
              <SectionHeader
                title={t('schedule.working_hours_text_2')}
                subtitle={t('schedule.working_hours_text_3')}
                wrapperClassName='text-sm smMax:!ml-0'
                titleClassName='text-lg font-normal'
                showGoBackButton={false}
              />
            </div>

            <WorkingDaysSelector
              checkedState={checkedStateCurrent || {}}
              setCheckedState={handleSetCheckedState}
              disabledDays={disabledDays}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  );
};

export default HoursForm;
