import React, { useState } from 'react';
import { Button, Input, ModalWrapper, RatingStars } from 'ui';
import { withModalHandling } from './withModalHandling';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';
import { fetchRequestBoolean } from 'api/api';
import { useAppSelector } from 'hooks/useAppSelector';
import toast from 'react-hot-toast';
import RegisterForm from 'components/client/register/RegisterForm';
import { AppDispatch } from 'store';
import { verifyCodeForOrder } from 'api/login';

type RateSpecialistModalProps = {
  handleCloseModal?: () => void;
  specialistName: string;
};

const RateSpecialistModal = ({
  handleCloseModal,
  specialistName,
}: RateSpecialistModalProps) => {
  const { t } = useTranslation();

  const { specialist } = useAppSelector((state) => state.createOrderPage);
  const { isLoggedIn } = useAppSelector((state) => state.user);

  const [comment, setComment] = useState('');
  const [starRating, setStarRating] = useState(0);
  const [hasReviewed, setHasReviewed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddReview = async () => {
    if (hasReviewed) {
      toast.error('Review already sent');
      handleCloseModal && handleCloseModal();
      return;
    }
    const url = `specialists/${specialist.stringId}/addReview`;
    setIsLoading(true);
    const response = await fetchRequestBoolean(url, 'POST', {
      rating: starRating,
      comment,
    });
    setIsLoading(false);
    if (response) {
      setHasReviewed(true);
      setComment('');
      setStarRating(0);
      toast.success(t('order.review_added'));
      handleCloseModal && handleCloseModal();
    }
  };

  const handleLoginUser = async (
    codeSMS: string,
    setIsLoadingLogin: (value: boolean) => void,
    data: {
      uid: string;
      phoneNumber: string;
      role: 'client' | 'specialist';
      codeSMS: string;
      dispatch: AppDispatch;
      setIsValid: (boolean: boolean) => void;
      firstName: string;
      lastName: string;
    },
  ) => {
    setIsLoadingLogin(true);
    setIsLoading(true);
    const response = await verifyCodeForOrder(
      data.uid,
      data.phoneNumber,
      data.role,
      codeSMS,
      data.dispatch,
      data.setIsValid,
      data.firstName,
      data.lastName,
    );

    if (response) {
      await handleAddReview();
    }
    setIsLoadingLogin(false);
    setIsLoading(false);
  };

  return (
    <ModalWrapper modalId='rateSpecialist'>
      <div className='flex w-full flex-col items-center justify-center'>
        <IoClose
          onClick={handleCloseModal}
          className='absolute right-4 top-4 cursor-pointer text-3xl'
        />
        <p className='text-xl font-medium'>{t('specialist.leave_revew')}</p>
        <p className='mb-2 mt-1 text-md font-medium'>{specialistName}</p>
        <RatingStars onRatingChange={setStarRating} />
        <div className='mt-5 w-full max-w-[400px] space-y-10'>
          <Input
            label={t('comment')}
            variant='outline'
            value={comment}
            multiline
            maxRows={4}
            max={200}
            hasError={comment.length > 200}
            helperText={comment.length > 200 ? 'Max 200 characters' : ''}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      </div>
      {!isLoggedIn && !isLoading ? (
        <RegisterForm
          handleLoginUser={handleLoginUser}
          disabled={starRating === 0 || comment.length > 200}
        />
      ) : (
        <Button
          onClick={handleAddReview}
          variant='yellow'
          loading={isLoading}
          disabled={starRating === 0 || comment.length > 200}
          className='w-full max-w-[400px] py-4'
        >
          {t('submit')}
        </Button>
      )}
    </ModalWrapper>
  );
};

export default withModalHandling(RateSpecialistModal, 'rateSpecialist');
