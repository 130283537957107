import { motion } from 'framer-motion';
import { FaChevronDown } from 'react-icons/fa';

interface ToggleChevronProps {
  isOpen: boolean;
  onToggle?: () => void;
  size?: number;
}

const ToggleChevron = ({ isOpen, onToggle, size = 22 }: ToggleChevronProps) => (
  <div className='ml-auto cursor-pointer' onClick={onToggle}>
    <motion.div
      animate={{ rotate: !isOpen ? 0 : 180 }}
      transition={{ duration: 0.2 }}
    >
      <FaChevronDown size={size} />
    </motion.div>
  </div>
);

export default ToggleChevron;
