import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Button, Input } from 'ui';
import { Link } from 'react-router-dom';
import { FaInstagram, FaLinkedin, FaTelegram } from 'react-icons/fa6';
import { toggleModal } from 'redux/modalSlice';
import { setFormInvestorType } from 'redux/emailTypeSlice';
import { useTranslation } from 'react-i18next';
import { MdMail } from 'react-icons/md';
import toast from 'react-hot-toast';
import { validateEmail } from 'utils/validations';
import { fetchRequestBoolean } from 'api/api';
import CategoriesIcon from 'images/screens/Categories.png';

const ContactsSection = () => {
  const [email, setEmail] = useState('');
  // const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSubscribe = async () => {
    if (!validateEmail(email)) {
      // setHasError(true);
      toast.error(t('Invalid email'));
      return;
    }
    // setHasError(false);
    let queryString = `email=${encodeURIComponent(
      email,
    )}&type=SUBSCRIPTION&name=''&phoneNumber=''`;
    const response = await fetchRequestBoolean(
      `email-contact/create?${queryString}`,
      'POST',
    );
    if (response) {
      toast.success(t('email.subscribed'));
      setEmail('');
    } else {
      toast.error(t('something_wrong'));
    }
  };

  return (
    <article
      className='flex flex-col justify-between gap-7 px-10 py-10  sm:px-[9vw] md:flex-row'
      id='contacts'
    >
      <section className='flex max-w-[540px] flex-col gap-6'>
        <motion.h3
          className='text-3xl font-medium leading-[51px] lg:text-5xl lg:leading-[57px]'
          initial={{ y: 50 }}
          whileInView={{ y: 0 }}
        >
          {t('contacts.title')}
        </motion.h3>
        <p className='text-lg text-gray-5'>{t('contacts.description')}</p>
        <Input
          className='!rounded-[50px] border !border-gray-3 px-4 py-5 placeholder-gray-5 hover:!border-accent focus:!border-accent sm:text-xl sm:leading-8'
          placeholder={t('email.title')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          Icon={
            <MdMail
              className={
                'cursor-pointer rounded-full bg-[#D9D9D947] p-2 text-accent transition-all active:p-3 '
              }
              size={45}
              aria-label='Email icon'
              title='Subribe to newsletter'
              onClick={handleSubscribe}
            />
          }
        />
        <div className='flex flex-col gap-1 rounded-[50px] border border-[#D9D9D9] px-7 pb-10 pt-7'>
          <p className='text-gray-8'>{t('email.description')}</p>
          <motion.p
            className='mb-6 text-2xl text-gray-7'
            initial={{ y: 50 }}
            whileInView={{ y: 0 }}
          >
            info@gotou.me
          </motion.p>
          <p className='text-gray-8'>{t('social_media')}</p>
          <div className='flex gap-3'>
            <Link
              to='https://www.linkedin.com/company/103098243/admin/feed/posts/'
              aria-label='Link to GotoU LinkedIn'
            >
              <FaLinkedin
                size={38}
                className='text-accent-80 hover:text-accent'
              />
            </Link>
            <Link to='https://t.me/gotoume' aria-label='GotoU Telegram bot'>
              <FaTelegram
                size={38}
                className='text-accent-80 hover:text-accent'
              />
            </Link>
            <Link
              to='https://www.instagram.com/gotou_app?igsh=dHo3aDZmM3o2eXNv'
              aria-label='GotoU Instagram bot'
            >
              <FaInstagram
                size={38}
                className='text-accent-80 hover:text-accent'
              />
            </Link>
          </div>
          <Button
            variant='black'
            className='mt-6 max-w-[213px] py-4 sm:whitespace-nowrap '
            onClick={() => {
              dispatch(setFormInvestorType('INVESTOR'));
              dispatch(toggleModal({ modalType: 'investorForm' }));
            }}
          >
            {t('become_investor')}
          </Button>
        </div>
      </section>
      <div className='flex aspect-[4/3] max-h-[600px] w-full max-w-[600px] items-center justify-center overflow-hidden rounded-[20px] bg-yellow-btn'>
        <img
          src={CategoriesIcon}
          alt='App screenshot'
          loading='lazy'
          className='h-full w-3/4 object-contain'
        />
      </div>
    </article>
  );
};

export default ContactsSection;
