import { motion } from 'framer-motion';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { toggleModal } from 'redux/modalSlice';
import { Button } from 'ui';

const variants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: '100%', transition: { duration: 0.15 } },
};

const AcceptCookiesModal = () => {
  const isOpen = useAppSelector((state) => state.modals.cookiePolicy.active);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  return (
    <motion.section
      className={`fixed bottom-0 left-7 right-7 z-50 mx-auto mb-5 flex w-5/6 items-center justify-center overflow-visible rounded-2xl bg-black object-fill p-4 shadow-md smMax:flex-col smMax:gap-2`}
      initial='closed'
      animate={isOpen ? 'open' : 'closed'}
      variants={variants}
      transition={{ duration: 0.5 }}
    >
      <p className='mr-2 text-sm text-white smMax:text-ellipsis smMax:text-xs '>
        {t('cookie_consent')}
      </p>
      <Button
        variant='black'
        className='border border-white hover:border-accent smMax:text-sm'
        onClick={() => dispatch(toggleModal({ modalType: 'cookiePolicy' }))}
      >
        {t('accept_us')}
      </Button>
    </motion.section>
  );
};

export default AcceptCookiesModal;
