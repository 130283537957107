import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useApiRequest } from 'hooks/useApiRequest';
import { setSelectedServiceName, setServices } from 'redux/categorySlice';
import { useAppSelector } from 'hooks/useAppSelector';

import { setServiceId } from 'redux/serviceIdSlice';
import { Button, SectionHeader, Wrapper } from 'ui';
import { BASE_URL } from 'api/api';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export const buttonsAppearVariants = {
  hidden: { opacity: 0, y: -5 },
  visible: { opacity: 1, y: 0 },
};

const ServicesPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { services, selectedSubcategoryName } = useAppSelector(
    (state) => state.category,
  );
  const { subcategoryId } = useAppSelector((state) => state.service);
  const { city, country } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();
  const { loading, data: fetchedServices } = useApiRequest({
    endpoint: `${BASE_URL}services/byLocation/${city}/${country}?subcategoryId=${subcategoryId}`,
    destructuringDataType: 'services',
  });

  const handleSelectService = (serviceId: string, serviceName: string) => {
    dispatch(setServiceId(serviceId));
    dispatch(setSelectedServiceName(serviceName));
    navigate(`/service/${serviceId}`);
  };

  useEffect(() => {
    if (!loading) dispatch(setServices(fetchedServices));
  }, [loading, fetchedServices, dispatch]);

  return (
    <Wrapper>
      <SectionHeader
        title={selectedSubcategoryName}
        subtitle={t('add_service.subcategory_description')}
      />
      <div className='mt-6 flex flex-col gap-3 pr-2 sm:max-h-[70vh] sm:overflow-y-auto'>
        {loading ? (
          <Button skeleton={true}>Category</Button>
        ) : (
          services.length > 0 &&
          services.map((service, index) => (
            <motion.div
              key={service.stringId}
              variants={buttonsAppearVariants}
              initial='hidden'
              animate='visible'
              transition={{ delay: index * 0.04 }}
            >
              <Button
                className='w-full border-accent-50 py-3 text-left hover:border-accent'
                onClick={() =>
                  handleSelectService(service.stringId, service.name)
                }
                variant='grayStroke'
              >
                {service.iconeURL && (
                  <img
                    src={service.iconeURL}
                    alt={`${service.name} icon`}
                    width={14}
                    height={14}
                    className='mr-4'
                  />
                )}
                {service.name}
              </Button>
            </motion.div>
          ))
        )}
        {!loading && services.length === 0 && (
          <>
            <p>{t('no_services')}</p>
            <Button className='my-2 py-3 ' onClick={() => navigate(-1)}>
              {t('go_back')}
            </Button>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default ServicesPage;
