import React, { useRef, useCallback, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import MarkerIcon from 'images/icons/marker-map.svg';
import { defaultMapTheme } from './theme';
import { LatLng } from 'use-places-autocomplete';

type MapProps = {
  center: LatLng;
  selectedCenter?: LatLng;
  isLoaded: boolean;
  isDraggable?: boolean;
  canZoom?: boolean;
  controlZoomVisible?: boolean;
  className?: string;
  onCenterChanged?: (center: LatLng) => void;
};

const defaultOptions = (
  isDraggable: boolean,
  canZoom: boolean,
  controlZoomVisible: boolean,
): google.maps.MapOptions => {
  return {
    zoomControl: controlZoomVisible,
    panControl: true,
    mapTypeControl: false,
    scaleControl: canZoom,
    streetViewControl: false,
    clickableIcons: false,
    keyboardShortcuts: false,
    fullscreenControl: false,
    scrollwheel: false,
    disableDefaultUI: true,
    gestureHandling: 'greedy',
    styles: defaultMapTheme,
    maxZoom: isDraggable ? 20 : 14,
    minZoom: 14,
    disableDoubleClickZoom: !canZoom,
  };
};

const Map = ({
  center,
  selectedCenter,
  isLoaded,
  className,
  isDraggable = false,
  canZoom = false,
  onCenterChanged,
  controlZoomVisible = true,
}: MapProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const mapRef = useRef<google.maps.Map | undefined>(undefined);

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      mapRef.current = map;

      const bounds = new google.maps.LatLngBounds();
      bounds.extend(center);

      bounds.extend({ lat: center.lat + 0.08, lng: center.lng + 0.08 });
      bounds.extend({ lat: center.lat - 0.08, lng: center.lng - 0.08 });

      map.addListener('bounds_changed', () => {
        if (!bounds.contains(map.getCenter() as google.maps.LatLng)) {
          map.setCenter(center);
        }
      });

      map.addListener('dragstart', () => {
        setIsDragging(true);
      });
      map.addListener('dragend', () => {
        setIsDragging(false);
        if (onCenterChanged) {
          const newCenter = map.getCenter();
          if (!newCenter) return;

          const newCenterLatLng = {
            lat: newCenter.lat(),
            lng: newCenter.lng(),
          };
          // Check if the new center is significantly different from the current center
          const distanceThreshold = 0.001; // Adjust as needed
          const isCenterChanged =
            Math.abs(center.lat - newCenterLatLng.lat) > distanceThreshold ||
            Math.abs(center.lng - newCenterLatLng.lng) > distanceThreshold;

          if (isCenterChanged) {
            onCenterChanged(newCenterLatLng);
          }
        }
      });
    },
    [center, onCenterChanged],
  );

  const onUnmount = useCallback(() => {
    mapRef.current = undefined;
  }, []);

  return isLoaded ? (
    <GoogleMap
      key={selectedCenter?.lat} // Update map if user select new address
      mapContainerClassName={`h-[400px] w-auto rounded-lg overflow-hidden ${className}`}
      center={center}
      zoom={isDraggable ? 16 : 14}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={defaultOptions(isDraggable, canZoom, controlZoomVisible)}
    >
      {onCenterChanged ? (
        <img
          src={MarkerIcon}
          alt='marker'
          width={24}
          height={24}
          className={`absolute left-[50%] top-[50%] transition-all ${
            isDragging ? 'scale-125' : ''
          }`}
        />
      ) : (
        <Marker
          position={center}
          icon={{
            url: MarkerIcon,
            scaledSize: new window.google.maps.Size(26, 26),
          }}
        />
      )}
    </GoogleMap>
  ) : (
    <div className='h-[400px] w-auto animate-pulse bg-secondary' />
  );
};

export default Map;
