import { ClearSuggestions, LatLng, SetValue } from 'use-places-autocomplete';
import toast from 'react-hot-toast';
import { toastWithDismiss } from 'ui';

declare var google: any;

export const getBrowserLocation = (
  onSuccess: React.Dispatch<React.SetStateAction<LatLng>>,
  onLocationName: SetValue,
  clearSuggestions: ClearSuggestions,
  setIsOptionSelected: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (navigator.permissions) {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'denied') {
        toast.dismiss();
        toastWithDismiss(
          'Please allow location access to use this feature',
          'error',
        );
        // AcceptToast({
        //   handleSaveClick: () => {
        //     toast.dismiss();
        //   },
        //   text: 'Please allow location access to use this feature',
        //   saveButtonText: 'Allow',
        //   cancelButtonText: 'Cancel',
        // });
      } else if (result.state === 'granted' || result.state === 'prompt') {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const geocoder = new google.maps.Geocoder();
            const latLng = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            geocoder.geocode(
              { location: latLng },
              (
                results: google.maps.GeocoderResult[],
                status: google.maps.GeocoderStatus,
              ) => {
                if (status === 'OK') {
                  if (results[0]) {
                    const locationName = results[0].formatted_address;
                    onLocationName(locationName);
                    setIsOptionSelected(true);
                    clearSuggestions();
                  } else {
                    toast.error('No results found');
                  }
                } else {
                  toast.error('Geocoder failed due to: ' + status);
                }
              },
            );
            onSuccess(latLng);
          },
          () => {
            toastWithDismiss(
              'Please allow location access to use this feature',
              'error',
            );
          },
        );
      }
    });
  } else {
    toastWithDismiss(
      'Your browser does not support location access =(',
      'error',
    );
  }
};
